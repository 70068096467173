import React, { useState, useEffect } from 'react';
import Header from '../../Layouts/Header';
import Footer from '../../Layouts/Footer';
import Breadcrumb from '../../Layouts/Breadcrumb';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import swal from "sweetalert";
import AuthApi from "../../Services/Authapi";
import TextField from '@material-ui/core/TextField';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import draftToHtml from 'draftjs-to-html';
import AuthCheckComponent from '../../Layouts/Token';

const iniLatter = {
    title: '',
    texteditor: ''
}

const Latter = (props) => {
    const classes = props;
    const [doc, setDoc] = useState(iniLatter);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (props && props.authToken === false) {
            // props.history.push('/login');
        }
    }, [props]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setDoc({ ...doc, [name]: value });
    };

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setDoc({ ...doc, texteditor: data });
    };

    const validate = (values) => {
        let errors = {};
        const regex = /^[a-zA-Z]/;

        if (!values.title) {
            errors.title = "Cannot be blank";
        } else if (!regex.test(values.title)) {
            errors.title = "Invalid title format";
        }
        return errors;
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmitting) {
            createLeaves();
        }
    }, [formErrors, isSubmitting]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormErrors(validate(doc));
        setIsSubmitting(true);
        console.log(doc);
    };

    const createLeaves = async () => {
        swal({
            title: "Loading",
            text: "Please wait...",
            buttons: false,
            closeOnClickOutside: false,
            closeOnEsc: false,
            width: "10%",
            content: {
                element: "img",
                attributes: {
                    src: "https://www.boasnotas.com/img/loading2.gif",
                    style: "width: 50px;" 
                }
            }
        });
        try {
            let create = await AuthApi.Templatepostdata(doc);
            swal.close();
            if (create && create.status === true) {
                props.history.push("/latter");
            } else {
                swal({
                    title: "OOPS!",
                    icon: "fail",
                    message: "Something went wrong, Please try later!",
                });
            }
        } catch (error) {
            console.error("Error updating role:", error);
        }
    };

    return (
        <div>
            <AuthCheckComponent authToken={props.authToken} />
            <Header
                {...props}
                authUser={props.authUser}
                component={
                    <div>
                        <Card className={classes.root} style={{ marginBottom: '3%' }}>
                            <CardContent>
                                <Breadcrumb {...props} primaryPageName="User Template" primaryPageLink="/latter" isSecondaryPage={false} secondaryPageName="" />
                            </CardContent>
                        </Card>
                        <Grid container className={classes.root} spacing={2}>
                            <Grid item xs={12}>
                                <Card className={classes.root}>
                                    <CardContent>
                                        <Typography variant="h2" style={{ paddingTop: 0, paddingLeft: 0, paddingBottom: 0 }}>Generate Template</Typography>
                                        <form className={classes.form} onSubmit={handleSubmit}>
                                            <Grid container className={classes.root} spacing={5}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        id="outlined-basic"
                                                        label="Title"
                                                        name="title"
                                                        variant="outlined"
                                                        error={formErrors.title && true}
                                                        value={doc.title}
                                                        onChange={handleChange}
                                                        className={formErrors.title && "input-error"}
                                                    />
                                                    {formErrors.title && (
                                                        <span className="error">{formErrors.title}</span>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.root} spacing={5}>
                                                <Grid item xs={12}>
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        data={doc.texteditor}
                                                        onChange={handleEditorChange}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.root} spacing={3}>
                                                <Grid item xs={4}>
                                                    <Button type="submit" style={{ marginTop: "10px", float: 'left' }} variant="contained" color="primary">Save</Button>
                                                    <Button type="button" style={{ marginTop: "10px", float: 'left' }} onClick={(e) => { e.preventDefault(); props.history.push('/latter') }}>Cancel</Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                } />
            <Footer />
        </div>
    )
}

export default Latter;
