// / eslint-disable react-hooks/exhaustive - deps /
import React, { useState, useEffect } from 'react';
import Header from '../../Layouts/Header';
import Footer from '../../Layouts/Footer';
import Breadcrumb from '../../Layouts/Breadcrumb';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import swal from "sweetalert";
import AuthApi from "../../Services/Authapi";
import TextField from '@material-ui/core/TextField';
import draftToHtml from 'draftjs-to-html';
import { convertFromHTML, ContentState, convertToRaw, EditorState } from 'draft-js';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import AuthCheckComponent from '../../Layouts/Token';

const iniLatter = {
    title: '',
    texteditor: '',
    // event_Id: 0,
}

const Edittemplate = (props) => {
    const classes = props;
    const [doc, setDoc] = useState(iniLatter);
    const [editorState, setEditorState] = React.useState(
        EditorState.createEmpty());
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (props && props.authToken === false) {
            // props.history.push('/login');
        }
    }, []);

    useEffect(() => {
        getEventData();
    }, []);

    const getEventData = async () => {
        let Id = props.history.location.pathname.split('/')[props.history.location.pathname.split('/').length - 1];
        let upcomingEvent = await AuthApi.Templategeedit(Id);
        const event_Id = Id;
        let template_name = (upcomingEvent.status === true && upcomingEvent.data && upcomingEvent.data.template_name && (upcomingEvent.data.template_name !== null || upcomingEvent.data.template_name !== false)) ? upcomingEvent.data.template_name : null;
        let letters = (upcomingEvent.status === true && upcomingEvent.data && upcomingEvent.data.letters && (upcomingEvent.data.letters !== null || upcomingEvent.data.letters !== false)) ? upcomingEvent.data.letters : null;
        setDoc({
            title: template_name,
            texteditor: letters,
            event_Id: event_Id,
        });
    };

    useEffect(() =>{ setTimeout(() => getEventData(), 500)}, []);

    const handleChange = (e, editorData) => {
        if (e && e.target) {
            const { name, value } = e.target;
            setDoc({ ...doc, [name]: value });
        } else if (editorData !== undefined) {
            setDoc({ ...doc, texteditor: editorData });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormErrors(validate(doc));
        setIsSubmitting(true);
        console.log(doc.texteditor);
    };


    const validate = (values) => {
        let errors = {};
        const regex = /^[a-zA-Z]/;

        if (!values.title) {
            errors.title = "Cannot be blank";
        } else if (!regex.test(values.title)) {
            errors.title = "Invalid title format";
        }
        return errors;
    };

    useEffect( () => {
        if (Object.keys(formErrors).length === 0 && isSubmitting) {
             editUser1();
        }
    }, [formErrors]);

    const editUser1 = async () => {
        swal({
            title: "Loading",
            text: "Please wait...",
            buttons: false,
            closeOnClickOutside: false,
            closeOnEsc: false,
            width: "10%",
            content: {
                element: "img",
                attributes: {
                    src: "https://www.boasnotas.com/img/loading2.gif",
                    style: "width: 50px;" 
                }
            }
        });
        try {
            let status = await AuthApi.Templateupdate(doc, doc.event_Id);
            swal.close();
            console.log(status.status);
            if (status && status.status === true) {
                props.history.push("/latter");
            }
        } catch (error) {
            console.error("Error updating latter:", error);
        }
    };

    return (
        <div>
            <AuthCheckComponent authToken={props.authToken} />
            <Header
                {...props}
                authUser={props.authUser}
                component={
                    <div>
                        <Card className={classes.root} style={{ marginBottom: '3%' }}>
                            <CardContent>
                                <Breadcrumb
                                    {...props}
                                    primaryPageName="User Template"
                                    primaryPageLink="/latter"
                                    isSecondaryPage={false}
                                    secondaryPageName="" />
                            </CardContent>
                        </Card>
                        <Grid container className={classes.root} spacing={2}>
                            <Grid item xs={12}>
                                <Card className={classes.root}>
                                    <CardContent>
                                        <Typography variant="h2" style={{ paddingTop: 0, paddingLeft: 0, paddingBottom: 0 }}>Generate Template</Typography>
                                        <form className={classes.form} onSubmit={handleSubmit}>
                                            <Grid container className={classes.root} spacing={5}>
                                                <Grid item xs={12}>
                                                    {console.log(doc.title)}
                                                    <TextField
                                                        fullWidth
                                                        id="outlined-basic"
                                                        name="title"
                                                        label="Title"
                                                        variant="outlined"
                                                        error={formErrors.title && true}
                                                        value={doc.title}
                                                        onChange={handleChange}
                                                        className={formErrors.title ? "input-error" : ""}
                                                    />
                                                    {formErrors.title && (
                                                        <span className="error">
                                                            {formErrors.title}
                                                        </span>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.root} spacing={5} >
                                                <Grid item xs={12} >
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        data={doc.texteditor}
                                                        onChange={(event, editor) => {
                                                            const data = editor.getData();
                                                            handleChange(null, data);
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.root} spacing={3}>
                                                <Grid item xs={4} >
                                                    <Button type="submit" style={{ marginTop: "10px", float: 'left' }} variant="contained" color="primary">
                                                        Save
                                                    </Button>
                                                    <Button type="button" style={{ marginTop: "10px", float: 'left' }} onClick={(e) => { e.preventDefault(); props.history.push('/latter') }}>
                                                        Cancel
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                } />
            <Footer />
        </div>
    );
};

export default Edittemplate;