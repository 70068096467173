/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-dupe-class-members */
import React, { Component } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Dashboard from "./components/Pages/Dashboard";
import Login from "./components/Pages/Login";
import Pagenotfound from "./Pagenotfound";
import InitComponent from "./components/Pages/InitComponent";
import ls from "local-storage";
import User from "./components/Pages/Users/Index";
import UserDetails from './components/Pages/Users/UserDetails';
import Permission from "./components/Pages/Permission";
import AuthApi from "./components/Services/Authapi";
import Role from "./components/Pages/Role";
import AddForm from "./components/Pages/Users/HandleUser";
import green from "@material-ui/core/colors/green";
// import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Edittemplate from "./components/Pages/Documents/Edittemplate";
import EditUser from "./components/Pages/Users/EditUser";
import Leaves from "./components/Pages/Leaves/Leaves1";
import AddLeaves from "./components/Pages/Leaves/AddLeaves";
import EditLeaves from "./components/Pages/Leaves/EditLeaves";
import LeavesTicket from "./components/Pages/LeavesTicket/Ticket";
import AddTickets from "./components/Pages/LeavesTicket/AddTickets";
import EditTicket from "./components/Pages/LeavesTicket/EditTicket";
import ProtectedRoute from "./components/ProtectedRoute";
import Profile from "./components/Pages/Profile/Profile";
import Event from "./components/Pages/Event/Event";
import AddEvent from "./components/Pages/Event/AddEvent";
import EditEvent from "./components/Pages/Event/EditEvent";
import ShowEvent from "./components/Pages/ShowEvent/Show";
import Upcoming from "./components/Pages/UpcomingEvent/Upcoming";
import AddUpcomingEvent from "./components/Pages/UpcomingEvent/AddUpcomingEvent";
import EditUpcomingEvent from "./components/Pages/UpcomingEvent/EditUpcomingEvent";
import Job from "./components/Pages/JobPortal/JobList";
import Addjob from "./components/Pages/JobPortal/AddJob";
import Editjob from "./components/Pages/JobPortal/EditJob";
import Qulification from "./components/Pages/Qulification/QulificationList";
import Addqulification from "./components/Pages/Qulification/AddQulification";
import Editqulification from "./components/Pages/Qulification/EditQulification";
import Experience from "./components/Pages/Experience/ExperienceList";
import Addexperience from "./components/Pages/Experience/AddExperience";
import Editexp from "./components/Pages/Experience/EditExp";
import Time from "./components/Pages/TIme Entry/AddTime";
import Check from "./components/Pages/UserCheck/Check";
import Settings from "./components/Pages/Settings/Settings";
// import { capture } from "./ScreenShot";
import MailIcon from '@mui/icons-material/Mail';
import Cron from "./components/Pages/Cron/Cron";
import Offer from "./components/Pages/Documents/Offer";
import Latter from "./components/Pages/Documents/latter";
import Message from "./components/Pages/Messages/message";
import Header from "./components/Layouts/Header";
import Timer from "./components/Pages/TImer/timer";
import swal from "sweetalert";
import Roleadd from "./components/Pages/Roleadd";
import Testing from "./components/Pages/Testing/testing";
import OfferLatter from "./components/Pages/Documents/OfferLatter";
import Salaryslip from "./components/Pages/Documents/Salaryslip";
import Relievingletter from "./components/Pages/Documents/Relievingletter";
import Documentslist from "./components/Pages/Documents/Documentslist";
import OfferLatterAdd from "./components/Pages/Documents/OfferLatterAdd";
import Relievingletteradd from "./components/Pages/Documents/Relievingletteradd";
import Editrole from "./components/Pages/editrole";
import Forgotpassword from "./components/Layouts/Forms/AuthForms/Forgotpassword";
import Attendance from "./components/Pages/Attendance/Attendance";
import AuthCheckComponent from "./components/Layouts/Token";
import DetailedNotifications from "./components/Layouts/DetailedNotifications";
// import UserDetails from "./components/Pages/Users/UserDetails";

// import {workerTimer} from "./components/Pages/Worker/worker-timer";

let idleTime = 0;
let tabVisibility;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authToken:
        ls.get("authToken") &&
          ls.get("authToken") !== null &&
          ls.get("authToken") !== false
          ? ls.get("authToken")
          : false,
      roles:
        ls.get("roles") && ls.get("roles") !== null && ls.get("roles") !== false
          ? ls.get("roles")
          : false,
      user:
        ls.get("user") && ls.get("user") !== null && ls.get("user") !== false
          ? ls.get("user")
          : false,
      authUser: false,
      location: document.location.pathname,
      count: ls.get("count") ? ls.get("count") : 0,
      timerOn: ls.get("timerOn") ? ls.get("timerOn") : false,
      startTime: ls.get("startTime") ? ls.get("startTime") : 0,
      stopTime: ls.get("stopTime") ? ls.get("stopTime") : 0,
      startTimeStat: false,
    };

    this.countRef = React.createRef(null);
    this.setAutUser = this.setAutUser.bind(this);
    this.updateCounterOnload = this.updateCounterOnload.bind(this);
    this.startTimer = this.startTimer.bind(this);
    // this.stopTimer = this.stopTimer.bind(this);
    // this.handleOnClick = this.handleOnClick.bind(this);
  }

  saveStateToLocalStorage() {
    ls.set("startTime", this.state.startTime);
    ls.set("count", this.state.count);
    ls.set("timerOn", this.state.timerOn);
    ls.set("stopTime", this.state.stopTime);
  }

  componentDidMount() {
    window.addEventListener(
      "beforeunload",
      this.saveStateToLocalStorage.bind(this)
    );
  }

  componentWillUnmount() {
    window.removeEventListener(
      "beforeunload",
      this.saveStateToLocalStorage.bind(this)
    );
    // saves if component has a chance to unmount
    this.saveStateToLocalStorage();
  }

  runCycle() {
    this.timer = setInterval(async () => {
      const newCount = this.state.count + parseInt(1);
      this.setState({ count: newCount >= 0 ? newCount : 0 }, function (param) {
        // this.updateCounterOnload(newCount);
      });
      this.saveStateToLocalStorage();
    }, 1000);
  }

  async startTimer() {
    await AuthApi.inTime();
    this.setState({ timerOn: true }, function () {
      this.setState({ startTime: Date.now() - this.state.startTime });
      this.runCycle();
    });
  }




  // async stopTimer() {
  //   await AuthApi.outTime();
  //   this.setState({ timerOn: false }, function () {
  //     this.setState({ stopTime: Date.now() });
  //     clearInterval(this.timer);
  //   });
  //   this.saveStateToLocalStorage();
  // }

  updateCounterOnload(count) {
    this.setState({ count: count });
  }

  async getAuth() {
    let checkLogin = await AuthApi.checkAuth();
    if (checkLogin && checkLogin.status !== false) {
      this.setAutUser({ authUser: checkLogin.data });
    } else {
      // ls.set("roles", false);
      ls.set("authToken", false);
      // ls.set("user", false);
      this.setAutUser({
        authUser: false,
        authToken: false,
        // roles: false,
        // user: false,
      });
    }
  }
  // hardik(AuthApi) {
  //   AuthApi.outTime();
  // }

  setAutUser(authData) {
    // console.log(authData);
    this.setState(authData);
    // AuthApi.outTime();
    // window.history.pushState({}, undefined, "/dashboard");
  }

  componentDidMount() {
    if (this.state.timerOn === true) {
      this.runCycle();
    }
  }

  componentWillMount() {
    this.getAuth();
  }

  componentDidMount() {
    let item;
    let eventKey;
    const vis = (c) => {
      let self = this;
      const browserProps = {
        hidden: "visibilitychange",
        msHidden: "msvisibilitychange",
        webkitHidden: "webkitvisibilitychange",
        mozHidden: "mozvisibilitychange",
      };
      for (item in browserProps) {
        if (item in document) {
          eventKey = browserProps[item];
          break;
        }
      }
      if (c) {
        if (
          !self._init &&
          !(typeof document.addEventListener === "undefined")
        ) {
          document.addEventListener(eventKey, c);
          self._init = true;
          c();
        }
      }
      return !document[item];
    };
    vis(async () => {
      tabVisibility = vis() ? "Visible" : "Not visible";
      // console.log(tabVisibility);
    });

    setInterval(timerIncrement, 60000);
    document.addEventListener("mousemove", (e) => {
      idleTime = 0;
    });

    document.addEventListener("keyPress", (e) => {
      idleTime = 0;
    });

    async function timerIncrement() {
      idleTime = idleTime + 1;
      if (tabVisibility === "Visible") {
        if (idleTime > 1) {
          // 20 minutes
          // console.log("v");
          await AuthApi.logout();
          window.location.reload();
        }
      } else if (tabVisibility === "Not visible") {
        if (idleTime > 1) {
          // 20 minutes
          // console.log("nv");
          await AuthApi.logout();
          window.location.reload();
        }
      }
    }
  }

  componentDidMount() {
    // window.onbeforeunload = async function (e) {
    // e.preventDefault();
    if (
      (window.performance.navigation &&
        window.performance.navigation.type === 1) ||
      window.performance
        .getEntriesByType("navigation")
        .map((nav) => nav.type)
        .includes("reload")
    ) {
    } else {
      AuthApi.logout();
      this.getAuth();
      // window.location.reload();
      return true;
    }
    // };
  }





  render() {
    // const userpermissions = (this.state.user && Array.isArray(this.state.user.roles) && this.state.user.roles[0] && Array.isArray(this.state.user.roles[0].permissions))
    // ? this.state.user.roles[0].permissions.map(permissionObj => permissionObj.name)
    // : [];
    // console.log(userpermissions)
    // const authToken = false;
    return (
      <div className="App">
        <Router>
          {/* <AuthCheckComponent authToken={this.state.authToken} /> */}
          <Switch>

            <Route
              exact
              path="/dashboard"
              render={(props) => (
                <Dashboard
                  {...props}
                  authUser={this.state.authUser}
                  authToken={this.state.authToken}
                  roles={this.state.roles}
                  user={this.state.user}
                  setAutUser={this.setAutUser}
                  count={this.state.count}
                  timerOn={this.state.timerOn}
                  // updateCounter={this.updateCounter}
                  updateCounterOnload={this.updateCounterOnload}
                  startTimer={this.startTimer}
                  // stopTimer={this.stopTimer}
                  startTime={this.state.startTime}
                  stopTime={this.state.stopTime}
                />
              )}
            />

            <Route
              exact
              path="/dashboard"
              render={(props) => (
                <Header
                  {...props}
                  authUser={this.state.authUser}
                  authToken={this.state.authToken}
                  roles={this.state.roles}
                  user={this.state.user}
                  setAutUser={this.setAutUser}
                  count={this.state.count}
                  timerOn={this.state.timerOn}
                  // updateCounter={this.updateCounter}
                  updateCounterOnload={this.updateCounterOnload}
                  startTimer={this.startTimer}
                  // stopTimer={this.stopTimer}
                  startTime={this.state.startTime}
                  stopTime={this.state.stopTime}
                />
              )}
            />
            {/* <Route
              exact
              path="/dashboard"
              render={(props) => (
                <AuthCheckComponent
                  {...props}
                  authUser={this.state.authUser}
                  authToken={this.state.authToken}
                  roles={this.state.roles}
                  user={this.state.user}
                  setAutUser={this.setAutUser}
                  count={this.state.count}
                  timerOn={this.state.timerOn}
                  // updateCounter={this.updateCounter}
                  updateCounterOnload={this.updateCounterOnload}
                  startTimer={this.startTimer}
                  // stopTimer={this.stopTimer}
                  startTime={this.state.startTime}
                  stopTime={this.state.stopTime}
                />
              )}
            /> */}
            <ProtectedRoute
              {...this.props}
              exact
              path="/permission"
              component={Permission}
              authUser={this.state.authUser}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />

            <ProtectedRoute
              {...this.props}
              exact
              path="/role"
              component={Role}
              authUser={this.state.authUser}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />


            <ProtectedRoute
              {...this.props}
              exact
              path="/Role/add"
              component={Roleadd}
              authUser={this.state.authUser}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />

            <ProtectedRoute
              {...this.props}
              exact
              path="/users"
              component={User}
              authUser={this.state.authUser}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />


            <ProtectedRoute
              {...this.props}
              exact
              path="/users/add"
              component={AddForm}
              authUser={this.state.authUser}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />

            <ProtectedRoute
              {...this.props}
              exact
              path="/users/:userId"
              component={UserDetails}
              authUser={this.state.authUser}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />
            <ProtectedRoute
              {...this.props}
              exact
              path="/users/edit/:id"
              authUser={this.state.authUser}
              component={EditUser}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />

            <ProtectedRoute
              {...this.props}
              exact
              path="/leaves"
              component={Leaves}
              authUser={this.state.authUser}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />
            <ProtectedRoute
              {...this.props}
              exact
              path="/leaves/add"
              authUser={this.state.authUser}
              component={AddLeaves}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />
            <ProtectedRoute
              {...this.props}
              exact
              path="/leaves/edit/:id"
              authUser={this.state.authUser}
              component={EditLeaves}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />
            <ProtectedRoute
              {...this.props}
              exact
              path="/tickets"
              component={LeavesTicket}
              authUser={this.state.authUser}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />
            <ProtectedRoute
              {...this.props}
              exact
              path="/tickets/add"
              authUser={this.state.authUser}
              component={AddTickets}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />
            <ProtectedRoute
              {...this.props}
              exact
              path="/tickets/edit/:id"
              authUser={this.state.authUser}
              component={EditTicket}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />
            <ProtectedRoute
              {...this.props}
              exact
              path="/profile"
              component={Profile}
              user={this.state.user}
              authUser={this.state.authUser}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />
            <ProtectedRoute
              {...this.props}
              exact
              path="/event"
              component={Event}
              authUser={this.state.authUser}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />

            <ProtectedRoute
              {...this.props}
              exact
              path="/event/add"
              authUser={this.state.authUser}
              component={AddEvent}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />
            <ProtectedRoute
              {...this.props}
              exact
              path="/event/edit/:id"
              authUser={this.state.authUser}
              component={EditEvent}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />
            <ProtectedRoute
              {...this.props}
              exact
              path="/show/:id"
              component={ShowEvent}
              authUser={this.state.authUser}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />
            <ProtectedRoute
              {...this.props}
              exact
              path="/upcoming"
              component={Upcoming}
              authUser={this.state.authUser}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />
            <ProtectedRoute
              {...this.props}
              exact
              path="/upcoming/add"
              component={AddUpcomingEvent}
              authUser={this.state.authUser}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />
            <ProtectedRoute
              {...this.props}
              exact
              path="/upcoming/edit/:id"
              authUser={this.state.authUser}
              component={EditUpcomingEvent}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />
            <ProtectedRoute
              {...this.props}
              exact
              path="/job"
              component={Job}
              authUser={this.state.authUser}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />
            <ProtectedRoute
              {...this.props}
              exact
              path="/job/add"
              component={Addjob}
              authUser={this.state.authUser}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />
            <ProtectedRoute
              {...this.props}
              exact
              path="/job/edit/:id"
              authUser={this.state.authUser}
              component={Editjob}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />

            <ProtectedRoute
              {...this.props}
              exact
              path="/Page/edit/:id"
              authUser={this.state.authUser}
              component={Editrole}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />

            <ProtectedRoute
              {...this.props}
              exact
              path="/qulification"
              component={Qulification}
              authUser={this.state.authUser}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />
            <ProtectedRoute
              {...this.props}
              exact
              path="/qulification/add"
              component={Addqulification}
              authUser={this.state.authUser}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />
            <ProtectedRoute
              {...this.props}
              exact
              path="/qulification/edit/:id"
              authUser={this.state.authUser}
              component={Editqulification}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />
            <ProtectedRoute
              {...this.props}
              exact
              path="/experience"
              component={Experience}
              authUser={this.state.authUser}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />
            <ProtectedRoute
              {...this.props}
              exact
              path="/experience/add"
              component={Addexperience}
              authUser={this.state.authUser}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />
            <ProtectedRoute
              {...this.props}
              exact
              path="/experience/edit/:id"
              authUser={this.state.authUser}
              component={Editexp}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />




            {/* <ProtectedRoute
              {...this.props}
              exact
              path="/OfferLatter"
              component={OfferLatter}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            /> */}





            <ProtectedRoute
              {...this.props}
              exact
              path="/time"
              component={Time}
              authUser={this.state.authUser}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />
            <ProtectedRoute
              {...this.props}
              exact
              path="/check"
              component={Check}
              authUser={this.state.authUser}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />

            <ProtectedRoute
              {...this.props}
              exact
              path="/Documentslist"
              component={Documentslist}
              authUser={this.state.authUser}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />

            <ProtectedRoute
              {...this.props}
              exact
              path="/offer"
              component={Offer}
              authUser={this.state.authUser}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />

            <ProtectedRoute
              {...this.props}
              exact
              path="/Edittemplate/:id"
              component={Edittemplate}
              authUser={this.state.authUser}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />

            <ProtectedRoute
              {...this.props}
              exact
              path="/latter"
              component={Latter}
              authUser={this.state.authUser}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />

            <ProtectedRoute
              {...this.props}
              exact
              path="/OfferLatter"
              component={OfferLatter}
              authUser={this.state.authUser}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />

            <ProtectedRoute
              {...this.props}
              exact
              path="/Salaryslip"
              component={Salaryslip}
              authUser={this.state.authUser}
              authToken={this.state.authToken}
              roles={this.state.roles}
              user={this.state.user}
              setAutUser={this.setAutUser}
            />

            <ProtectedRoute
              {...this.props}
              exact
              path="/Relievingletter"
              component={Relievingletter}
              authUser={this.state.authUser}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />

            <ProtectedRoute
              {...this.props}
              exact
              path="/Documents/OfferLatterAdd"
              component={OfferLatterAdd}
              authUser={this.state.authUser}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />

            <ProtectedRoute
              {...this.props}
              exact
              path="/Documents/Relievingletteradd"
              component={Relievingletteradd}
              authUser={this.state.authUser}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />

            <ProtectedRoute
              {...this.props}
              exact
              path="/message"
              component={Message}
              authUser={this.state.authUser}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />

            <ProtectedRoute
              {...this.props}
              exact
              path="/timer"
              component={Timer}
              user={this.state.user}
              authUser={this.state.authUser}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />

            <ProtectedRoute
              {...this.props}
              exact
              path="/testing"
              component={Testing}
              authUser={this.state.authUser}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />


            <ProtectedRoute
              {...this.props}
              exact
              path="/Attendance"
              component={Attendance}
              authUser={this.state.authUser}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />

            <ProtectedRoute
              {...this.props}
              exact
              path="/Settings"
              component={Settings}
              user={this.state.user}
              authUser={this.state.authUser}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />

            <ProtectedRoute
              {...this.props}
              exact
              path="/DetailedNotifications"
              component={DetailedNotifications}
              user={this.state.user}
              authUser={this.state.authUser}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />
            {/* <ProtectedRoute
              {...this.props}
              exact
              path="/Cron"
              component={Cron}
              authUser={this.state.authUser}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            /> */}
            <ProtectedRoute
              {...this.props}
              exact
              path="/Cron"
              component={Cron}
              authUser={this.state.authUser}
              authToken={this.state.authToken}
              setAutUser={this.setAutUser}
            />

            <Route
              exact
              path="/"
              render={(props) => (
                <InitComponent
                  {...props}
                  authUser={this.state.authUser}
                  setAutUser={this.setAutUser}
                  authToken={this.state.authToken}
                />
              )}
            />
            <Route
              exact
              path="/login"
              render={(props) => (
                <Login
                  {...props}
                  // component={hardik}
                  authUser={this.state.authUser}
                  setAutUser={this.setAutUser}
                  authToken={this.state.authToken}
                />
              )}
            />

            <Route

              path="/Forgotpassword"
              render={(props) => (

                <Forgotpassword
                // {...props}
                // // component={hardik}
                // authUser={this.state.authUser}
                // setAutUser={this.setAutUser}
                // authToken={this.state.authToken}
                />
              )}
            />

            <Route path="*" exact={true} component={Pagenotfound} />
          </Switch>
        </Router>

        <div className="timer">
          {this.state.authToken === false ? "" : <Timer
            {...this.props}
            component={Timer}
            user={this.state.user}
            authUser={this.state.authUser}
            authToken={this.state.authToken}
            setAutUser={this.setAutUser}
          />}
        </div>
      </div>
    );

  }
}

export default function CustomStyles() {
  return (
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  );
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#1B6E95",
      light: "#1b6e9582",
      dark: "#1B6E95",
    },
    secondary: {
      main: green[500],
      light: green[100],
      dark: green[900],
      contrastText: green[600],
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    subtitle1: {
      fontSize: 12,
    },
    body1: {
      fontWeight: 500,
    },
    button: {
      fontStyle: "italic",
    },
    h2: {
      fontSize: "1.2rem",
      "@media (min-width:600px)": {
        fontSize: "1.5rem",
      },
      float: "left",
      padding: "1%",
      marginTop: "5px",
      fontWeight: "500",
      color: "#1B6E95",
    },
  },
});
