import React, { Component } from 'react';
import Header from '../../Layouts/Header';
import Footer from '../../Layouts/Footer';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Breadcrumb from '../../Layouts/Breadcrumb';
import Typography from '@material-ui/core/Typography';
// import { TextField } from '@material-ui/core';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormControl from '@material-ui/core/FormControl';
// import FormLabel from '@material-ui/core/FormLabel';
// import Button from '@material-ui/core/Button';
// import LoadderButton from '../../Layouts/LoadderButton';
// import AuthApi from '../../Services/Authapi';
// import FontAwesomeIconComponent from '../../Layouts/FontAwesomeIconComponent';
// import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
// import Select from '@material-ui/core/Select';
import EditForm from '../../Layouts/Forms/UserForms/EditForm';
import AuthCheckComponent from '../../Layouts/Token';


class HandleUser1 extends Component {
  

    componentWillMount() {
        if (this.props && this.props.authToken === false) {
        //   this.props.history.push('/login');
        }
       
      }
    
      componentWillReceiveProps(props) {
        if (props && props.authToken === false) {
        //   props.history.push('/login');
        }
      }


   

    render() {
        const classes = this.props;
       
        return (
            <div>
                  <AuthCheckComponent authToken={this.props.authToken} />
                <Header
                    {...this.props}
                    authUser={this.props.authUser}
                    component={
                        <div>
                            <Card className={classes.root} style={{ marginBottom: '3%' }}>
                                <CardContent>
                                    <Breadcrumb
                                        {...this.props}
                                        primaryPageName="Users" 
                                        primaryPageLink="/users" 
                                        isSecondaryPage={true}
                                        secondaryPageName="Edit" />
                                </CardContent>
                            </Card>
                            <Grid container className={classes.root} spacing={2}>
                                <Grid item xs={12}>
                                    <Card className={classes.root}>
                                        <CardContent>
                                            <Typography variant="h2">Edit User</Typography>
                                            <EditForm  {...this.props}/>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={6}></Grid>
                            </Grid>
                        </div>
                    } />
                <Footer />
            </div>
        );
    }
}

export default HandleUser1;