import React, { useState, useEffect, useRef } from "react";
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import swal from 'sweetalert';
import Header from "../../Layouts/Header";
import Footer from "../../Layouts/Footer";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Breadcrumb from "../../Layouts/Breadcrumb";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AuthApi from "../../Services/Authapi";
import Paper from "@material-ui/core/Paper";
import FontAwesomeIconComponent from "../../Layouts/FontAwesomeIconComponent";
import IconButton from "@material-ui/core/IconButton";
import { useLocation } from "react-router-dom";
import ls from "local-storage";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { Pdfdownload } from "../../../TableAction";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useHistory } from "react-router-dom";
import DialogContent from "@material-ui/core/DialogContent";
import Tooltip from "@material-ui/core/Tooltip";
import AuthCheckComponent from "../../Layouts/Token";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

let length;

let ds;

const columns = [
  { id: "name", label: "Employee ID", minWidth: 100 },
  { id: "firstName", label: "Name", minWidth: 100 },
  { id: "BasicSalary", label: "Basic Salary", minWidth: 100 },
  { id: "DA", label: "DA", minWidth: 50 },
  { id: "HRA", label: "HRA", minWidth: 50, align: "center" },
  { id: "CA", label: "CA", minWidth: 100, align: "center" },
  { id: "Tax", label: "Tax", minWidth: 100, align: "center" },
  { id: "netSalary", label: "Net Salary", minWidth: 100, align: "center" },
  { label: "Actions", buttons: [Pdfdownload] },
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 151,
  },
  controls: {
    display: "flex",
    alignItems: "left",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  hr: {
    borderTop: "3px solid #1B6E95",
  },
  container: {
    maxHeight: 440,
  },
  spacing: {
    "& > *": {
      margin: theme.spacing(2),
      width: "25ch",
    },
  },
}));

const iniuser = {
  firstName: "",
  lastName: "",
  email: "",
  gender: "",
  dob: "",
  photo: "",
  qualification: "",
  last_organization: "",
  uploadedImgName: "",
  roleName: "",
  user: false,
  user_id: 0,
};

const iniSalary = {
  salary: "",
};

const DataForSal = {
  getData: [],
};

let inipdf = [];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const Profile = (props) => {
  const classes = props;
  const classes1 = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const [value, setValue] = React.useState(0);
  const [editValues, setEditValues] = useState(iniuser);
  const [shown, setShown] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [sal, setSal] = React.useState(iniSalary);
  const [data, setData] = React.useState(DataForSal);
  const [datasalaryid, setdatasalaryid] = React.useState([]);
  const [slip, setslip] = React.useState([]);
  const [Pdf, setPdf] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [qualifications, setQualifications] = useState([]);
  const history = useHistory()
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    if (location.state && location.state.tab === "salaryInfo") {
      setValue(1);
      if (location.state.salarySlipId && sessionStorage.getItem('openSalaryModal') === 'true') {
        openModal(location.state.salarySlipId);
        sessionStorage.removeItem('openSalaryModal'); 
      }
    }
  }, [location.state]);

  const handleChangePage1 = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage1 = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setEditValues({ ...editValues, [name]: value });
  };

  const handleSalary = (e) => {
    const { name, value } = e.target;
    setSal({ ...sal, [name]: value });
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  function createData(BasicSalary, DA, HRA, CA, Tax, netSalary) {
    return { BasicSalary, DA, HRA, CA, Tax, netSalary };
  }

  ds = [data.getData];

  const rows = [];

  if (ds[0].length !== 0) {
    ds[0].forEach((element1) => {
      rows.push(
        createData(
          element1.BasicSalary,
          element1.DA,
          element1.HRA,
          element1.CA,
          element1.Tax,
          element1.netSalary
        )
      );
    });
  } else {
    rows.push(createData("Data Not Found"));
  }

  const getFormData = async () => {
    let userId = ls("user").id;
    let user = await AuthApi.singleUser(userId);

    let user_id = userId;
    let firstName =
      user.status === true &&
        user.data &&
        user.data.firstName &&
        (user.data.firstName !== null || user.data.firstName !== false)
        ? user.data.firstName
        : null;
    let lastName =
      user.status === true && user.data && user.data.lastName
        ? user.data.lastName
        : null;
    let email =
      user.status === true && user.data && user.data.email
        ? user.data.email
        : null;
    let gender =
      user.status === true && user.data && user.data.gender
        ? user.data.gender
        : null;
    let dob =
      user.status === true && user.data && user.data.dob ? user.data.dob : null;
    let photo =
      user.status === true && user.data && user.data.photo
        ? user.data.photo
        : null;
    let qualification =
      user.status === true && user.data && user.data.qualification
        ? user.data.qualification
        : null;
    let last_organization =
      user.status === true && user.data && user.data.last_organization
        ? user.data.last_organization
        : null;
    let userRole =
      user.status === true &&
        user.data &&
        user.data.roles &&
        Object.keys(user.data.roles).length > 0
        ? user.data.roles
        : [];
    let roleName = Object.keys(userRole).length > 0 ? userRole[0].name : null;
    let imageName =
      user.data.photo !== null
        ? user.data.photo.substr(user.data.photo.lastIndexOf("/") + 1)
        : "";
    let salary =
      user.status === true && user.data && user.data.salary
        ? user.data.salary
        : null;
    let mobileNo =
      user.status === true && user.data && user.data.mobileNo
        ? user.data.mobileNo
        : null;
    let bankName =
      user.status === true && user.data && user.data.bankName
        ? user.data.bankName
        : null;
    let bankAccNo =
      user.status === true && user.data && user.data.bankAccNo
        ? user.data.bankAccNo
        : null;
    let doj =
      user.status === true && user.data && user.data.doj ? user.data.doj : null;

    let location =
      user.status === true && user.data && user.data.location
        ? user.data.location
        : null;
    let department =
      user.status === true && user.data && user.data.department
        ? user.data.department
        : null;
    let designation =
      user.status === true && user.data && user.data.designation
        ? user.data.designation
        : null;
    let password =
      user.status === true && user.data && user.data.password
        ? user.data.password
        : null;

    let uploadedImgName = imageName;

    function formatDate(dob) {
      const parts = dob.split("-");
      return `${parts[2]}-${parts[1]}-${parts[0]}`; // This should be adjusted to yyyy-MM-dd
    }

    setEditValues({
      firstName: firstName,
      lastName: lastName,
      email: email,
      gender: gender,
      dob1: dob,
      dob: formatDate(dob),
      photo: photo,
      qualification: qualification,
      last_organization: last_organization,
      roleName: roleName,
      uploadedImgName: uploadedImgName,
      salary: salary,
      mobileNo: mobileNo,
      bankName: bankName,
      bankAccNo: bankAccNo,
      doj: doj,
      location: location,
      department: department,
      designation: designation,
      password: password,
      user_id: user_id,
    });
  };
  // console.log(editValues.password)



  useEffect(() => {
    getQualificationData();
  }, []);

  const getQualificationData = async () => {
    try {
      const response = await AuthApi.getQulification();
      if (response && response.data) {
        setQualifications(response.data);
      }
    } catch (error) {
      console.error('Error fetching qualification data:', error);
    }
  };

  const imgUpload = async (e) => {
    const postData = new FormData();
    postData.append("file", e.target.files[0]);
    let updateImg = await AuthApi.updateImg(postData);
    if (updateImg && updateImg.status === true) {
      setEditValues({
        ...editValues,
        photo: updateImg.data.image_url,
        uploadedImgName: updateImg.data.image_name,
      });
    }
  };

  useEffect(() => {
    getFormData()
  }, [])

  const openModal = async (user_id) => {
    try {
      const slip = await AuthApi.viewSalarySlip(user_id);
      if (slip) {
        setslip(slip)
      } else {
      }
      setPdf(true)
    } catch (error) {
    }
  };

  const downloadSlip = async (id) => {
    try {
      swal({
        title: "Salaries Downloading...",
        text: "Please wait...",
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        width: "10%",
        content: {
          element: "img",
          attributes: {
            src: "https://www.boasnotas.com/img/loading2.gif",
            style: "width: 50px;"
          }
        }
      });

      const slip = await AuthApi.getAllSalaryPdf(id);
      if (slip && slip.status === true) {
      }
      swal.close();
    } catch (error) {
      console.error("Error downloading salary slip:", error);
      swal({
        title: "Error",
        text: "Failed to download salary slip. Please try again later.",
        icon: "error",
      });
    }
  }

  const removeImg = () => {
    setEditValues({
      ...editValues,
      photo: null,
      uploadedImgName: null,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  const editUser1 = async (id) => {
    let status = await AuthApi.updateUser(editValues, props.user.id);
    if (status && status.status === true) {
      props.history.push("/dashboard");
    }
  };

  const call1 = async (e) => {
    if (shown === false) {
      setShown({ shown: shown });
    } else {
      handleSubmit(e);
      await editUser1();
    }
  };

  const removeUser = async (id) => {
    swal({
      title: "Are you sure?",
      icon: "warning",
      buttons: ["Cancel", "Yes"],
    }).then(async (confirm) => {
      if (confirm) {
        let currentUser = await AuthApi.Salarydelete(id);
        if (currentUser && currentUser.status === true) {
          history.push("/dashboard");
        } else {
        }
      }
    });
  }

  console.warn = console.error = () => { };

  var month = new Array();
  month[0] = "01";
  month[1] = "02";
  month[2] = "03";
  month[3] = "04";
  month[4] = "05";
  month[5] = "06";
  month[6] = "07";
  month[7] = "08";
  month[8] = "09";
  month[9] = "10";
  month[10] = "11";
  month[11] = "12";

  var d = new Date();
  var dateString = d.getFullYear() + "-" + month[d.getMonth()];

  const handleMonthChange = async (e, id) => {
    let select = e.target.value;

    let today = new Date(select);
    let month = today.getMonth() + 1;
    let year = today.getFullYear();

    const AllData = await AuthApi.alldatasalaryid(month, year, ls("user").id);

    if (AllData && AllData.status === true || AllData.status === false) {
      setdatasalaryid(AllData.data);
    }
  };

  const allData = async (e, id) => {
    let today = new Date();
    let month = today.getMonth() + 1;
    let year = today.getFullYear();
    const dis = await AuthApi.alldatasalaryid(month, year, ls("user").id);
    if (dis && dis.status === true || dis.status === false) {
      setdatasalaryid(dis.data);
    }

    const input = document.getElementById("datetime-local");
    input.value = dateString;
  };

  const permissions = (props.authUser && Array.isArray(props.authUser.userAccess)) ? props.authUser.userAccess : [];

  return (
    <div>
      <AuthCheckComponent authToken={props.authToken} />
      <Header
        {...props}
        authUser={props.authUser}
        component={
          <div>
            <Card className={classes.root} style={{ marginBottom: "3%" }}>
              <CardContent>
                <Breadcrumb
                  {...props}
                  primaryPageName="Profile"
                  primaryPageLink="/profile"
                  isSecondaryPage={false}
                  secondaryPageName=""
                />
              </CardContent>
            </Card>

            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="Profile Info" {...a11yProps(0)} />
                <Tab label="Salary Info" {...a11yProps(1)} onClick={allData} />
                </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                <Grid container className={classes1.controls} spacing={3}>
                  <Grid item xs={12}>
                    <Card className={classes.root}>
                      <CardContent>
                        <Grid
                          container
                          className={classes1.controls}
                          spacing={3}
                        >
                          <Grid item xs={6}>
                            {shown === false && (
                              <Typography
                                component="span"
                                variant="body2"
                                style={{ float: "left" }}
                              >
                                First Name :-
                              </Typography>
                            )}
                            {shown === false ? (
                              <Typography
                                component="span"
                                style={{ float: "left", marginLeft: "42px" }}
                                variant="body2"
                                display="block"
                              >
                                {editValues.firstName}
                              </Typography>
                            ) : (
                              <TextField
                                style={{ float: "left", marginLeft: "04px" }}
                                id="standard-basic"
                                variant="outlined"
                                name="firstName"
                                label="First Name"
                                error={!editValues.firstName}
                                value={editValues.firstName}
                                onChange={handleChange1}
                              />
                            )}
                          </Grid>

                          <Grid item xs={6}>
                            {shown === false && (
                              <Typography
                                component="span"
                                style={{ float: "left" }}
                                variant="body2"
                              >
                                Last Name :-
                              </Typography>
                            )}
                            {shown === false ? (
                              <Typography
                                component="span"
                                style={{ float: "left", marginLeft: "62px" }}
                                variant="body2"
                                display="block"
                              >
                                {editValues.lastName}
                              </Typography>
                            ) : (
                              <TextField
                                style={{ float: "left", marginLeft: "18px" }}
                                id="standard-basic"
                                name="lastName"
                                label="Last Name"
                                error={!editValues.lastName}
                                variant="outlined"
                                value={editValues.lastName}
                                onChange={handleChange1}
                              />
                            )}
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          className={classes1.controls}
                          spacing={3}
                        >
         <Grid item xs={6}>
                            {shown === false && (
                              <Typography
                                component="span"
                                style={{ float: "left" }}
                                variant="body2"
                              >
                                Email :-
                              </Typography>
                            )}
                            {shown === false ? (
                              <Typography
                                component="span"
                                style={{ float: "left", marginLeft: "74px" }}
                                variant="body2"
                                display="block"
                              >
                                {editValues.email}
                              </Typography>
                            ) : (
                              <TextField
                                style={{ float: "left", marginLeft: "04px" }}
                                id="standard-basic"
                                variant="outlined"
                                name="email"
                                label="Email"
                                value={editValues.email}
                                disabled
                              />
                            )}
                          </Grid>

                          <Grid item xs={6}>
                            {shown === false && (
                              <Typography
                                component="span"
                                style={{ float: "left" }}
                                variant="body2"
                              >
                                Gender :-
                              </Typography>
                            )}
                            {shown === false ? (
                              <Typography
                                component="span"
                                style={{ float: "left", marginLeft: "80px" }}
                                variant="body2"
                                display="block"
                              >
                                {editValues.gender}
                              </Typography>
                            ) : (
                              <RadioGroup
                                aria-label="gender"
                                name="gender"
                                error={editValues.gender && true}
                                value={editValues.gender}
                                onChange={handleChange1}
                              >
                                <Grid
                                  container
                                  className={classes.root}
                                  spacing={2}
                                  style={{
                                    marginLeft: "18px"
                                  }}
                                >
                                  <FormControlLabel
                                    value="Female"
                                    control={<Radio />}
                                    label="Female"
                                  />
                                  <FormControlLabel
                                    value="Male"
                                    control={<Radio />}
                                    label="Male"
                                  />
                                </Grid>
                              </RadioGroup>
                            )}
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          className={classes1.controls}
                          spacing={3}
                        >
                          <Grid item xs={6}>
                            {shown === false && (
                              <Typography
                                component="span"
                                style={{ float: "left" }}
                                variant="body2"
                              >
                                Date Of Birth :-
                              </Typography>
                            )}
                            {shown === false ? (
                              <Typography
                                component="span"
                                style={{ float: "left", marginLeft: "76px" }}
                                variant="body2"
                                display="block"
                              >
                                {editValues.dob1}
                              </Typography>
                            ) : (
                              <TextField
                                style={{ float: "left", marginLeft: "04px", width: '40%' }}
                                id="date"
                                variant="outlined"
                                name="dob"
                                label="Date Of Birth"
                                error={!editValues.dob}
                                type="date"
                                value={editValues.dob}
                                className={classes.textField}
                                onChange={handleChange1}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            )}
                          </Grid>

                          <Grid item xs={6}>
                            {shown === false && (
                              <Typography
                                component="span"
                                style={{ float: "left" }}
                                variant="body2"
                              >
                                Photo :-
                              </Typography>
                            )}
                            {shown === false ? (
                              <Typography
                                component="span"
                                style={{ float: "left", marginLeft: "89px" }}
                                variant="body2"
                                display="block"
                              >
                                <img
                                  src={editValues.photo}
                                  alt="User profile image"
                                  width="100"
                                  height="100"
                                  style={{
                                    borderRadius: "50%",
                                    height: "70px",
                                    width: "70px"
                                  }}
                                />
                              </Typography>
                            ) : editValues.photo === null ? (
                              <div
                                style={{ float: "left", marginLeft: "89px" }}
                              >
                                <input
                                  accept="image/*"
                                  className={classes.input}
                                  name="photo"
                                  id="contained-button-file"
                                  type="file"
                                  onChange={(e) => imgUpload(e)}
                                  style={{ display: "none" }}
                                />
                                <label htmlFor="contained-button-file">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    component="span"
                                  >
                                    Upload Profile Picture
                                  </Button>
                                </label>
                              </div>
                            ) : (
                              <div style={{ float: "left", marginLeft: "18px", width: "39%" }}>
                                <Card className={classes.root}>
                                  <CardContent>
                                    <Grid
                                      container
                                      className={classes.root}
                                      spacing={3}
                                    >
                                      <Grid item xs={4}>
                                        <img
                                          error={editValues.photo && true}
                                          src={editValues.photo}
                                          alt="User profile image"
                                          width="35"
                                          height="35"
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <Typography
                                          variant="subtitle1"
                                          gutterBottom
                                        >
                                          {editValues.uploadedImgName}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={4}>
                                        <Button
                                          onClick={(e) => {
                                            removeImg(e);
                                          }}
                                        >
                                          <FontAwesomeIconComponent
                                            classes="fa fa-trash"
                                            colorName="primary"
                                            fontSize="small"
                                          />
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </CardContent>
                                </Card>
                              </div>
                            )}
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          className={classes1.controls}
                          spacing={3}
                        >
                          <Grid item xs={6}>
                            {shown === false && (
                              <Typography
                                component="span"
                                style={{ float: "left" }}
                                variant="body2"
                              >
                                Qualification :-
                              </Typography>
                            )}
                            {shown === false ? (
                              <Typography
                                component="span"
                                style={{ float: "left", marginLeft: "28px" }}
                                variant="body2"
                                display="block"
                              >
                                {editValues.qualification}
                              </Typography>
                            ) : (
                              <FormControl variant="outlined" style={{ width: '40%', float: "left", marginLeft: "04px" }}>
                                <InputLabel id="demo-simple-select-outlined-label">Qualification</InputLabel>
                                <Select
                                  fullWidth
                                  id="outlined-basic"
                                  name="qualification"
                                  variant="outlined"
                                  value={editValues.qualification}
                                  label="Qualification"
                                  error={!editValues.qualification}
                                  onChange={handleChange1}
                                  className={editValues.dob && "input-error"}
                                >
                                  <MenuItem value="">
                                    <em>None</em>
                                  </MenuItem>
                                  {qualifications.map((qualification) => (
                                    <MenuItem key={qualification.id} value={qualification.qualification}>
                                      {qualification.qualification}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            )}
                          </Grid>

                          <Grid item xs={6}>
                            {shown === false && (
                              <Typography
                                component="span"
                                style={{ float: "left" }}
                                variant="body2"
                              >
                                Last Organization :-
                              </Typography>
                            )}
                            {shown === false ? (
                              <Typography
                                component="span"
                                style={{ float: "left", marginLeft: "18px" }}
                                variant="body2"
                                display="block"
                              >
                                {editValues.last_organization}
                              </Typography>
                            ) : (
                              <TextField
                                style={{ float: "left", marginLeft: "18px" }}
                                id="standard-basic"
                                name="last_organization"
                                error={!editValues.last_organization}
                                label="Last Organization"
                                variant="outlined"
                                value={editValues.last_organization}
                                onChange={handleChange1}
                              />
                            )}
                          </Grid>

                          <Grid item xs={6}>
                            {shown === false && (
                              <Typography
                                component="span"
                                style={{ float: "left" }}
                                variant="body2"
                              >
                                Role Name :-
                              </Typography>
                            )}
                            {shown === false && (
                              <Typography
                                component="span"
                                style={{ float: "left", marginLeft: "37px" }}
                                variant="body2"
                                display="block"
                              >
                                {editValues.roleName}
                              </Typography>
                            )}
                          </Grid>
                        </Grid>

                        {shown && (
                          <Grid container className={classes1.controls} spacing={3}>
                            <Grid item xs={6}>
                              {shown === false && (
                                <Typography component="span" variant="body2" style={{ float: "left" }}>
                                  New Password :-
                                </Typography>
                              )}
                              <TextField
                                style={{ float: "left", marginLeft: "04px" }}
                                id="standard-basic"
                                variant="outlined"
                                name="password"
                                error={!editValues.password}
                                value={editValues.password}
                                label="New Password"
                                onChange={handleChange1}
                                type={showPassword ? "text" : "password"}
                                InputProps={{
                                  endAdornment: (
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                    >
                                      {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  ),
                                }}
                              />
                            </Grid>

                            <Grid item xs={6}>
                              {shown === false && (
                                <Typography component="span" variant="body2" style={{ float: "left" }}>
                                  Confirm Password :-
                                </Typography>
                              )}
                              <TextField
                                style={{ float: "left", marginLeft: "18px" }}
                                id="standard-basic"
                                variant="outlined"
                                name="confirmPassword"
                                error={!confirmPassword || editValues.password !== confirmPassword}
                                value={confirmPassword}
                                onChange={handleConfirmPasswordChange}
                                label="Confirm Password"
                                type={showConfirmPassword ? "text" : "password"}
                                InputProps={{
                                  endAdornment: (
                                    <IconButton
                                      aria-label="toggle confirm password visibility"
                                      onClick={handleClickShowConfirmPassword}
                                      onMouseDown={handleMouseDownPassword}
                                    >
                                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  ),
                                }}
                              />
                            </Grid>
                          </Grid>
                        )}

                        <Grid
                          container
                          className={classes1.controls}
                          spacing={3}
                        >
                          <Grid item xs={3} style={{ display: "flex" }}>
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              onClick={(e) => {
                                call1(e);
                              }}
                            >
                              Edit Profile
                            </Button>
                            <Button
                              type="button"
                              onClick={(e) => {
                                e.preventDefault();
                                props.history.push("/dashboard");
                              }}
                            >
                              Cancel
                            </Button>
                          </Grid>
                          <Grid item xs={4}></Grid>
                          <Grid item xs={4}></Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </TabPanel>

              <TabPanel value={value} index={1} dir={theme.direction}>
                <div className={classes1.spacing}>
                  <TextField
                    id="datetime-local"
                    label="Select Month/Year"
                    views={["year", "month"]}
                    variant="outlined"
                    style={{ top: "-10px", float: "left" }}
                    defaultValue={dateString}
                    type="Month"
                    onChange={(e) => {
                      handleMonthChange(e);
                    }}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ min: "2020-01", max: dateString }}
                  />
                </div>

                <Paper className={classes.root}>
                  <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <StyledTableRow>
                          {columns.map((column) => (
                            <StyledTableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </StyledTableCell>
                          ))}
                        </StyledTableRow>
                      </TableHead>
                      <TableBody>
                        {datasalaryid.length > 0 ? (
                          datasalaryid.map((row) => {
                            return (
                              <StyledTableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                              >
                                {columns.map((column) => {
                                  if (column.buttons) {
                                    return (
                                      <StyledTableCell key={column.label}>
                                        {column.buttons.map((val, index) => {
                                          return (
                                            <React.Fragment key={index}>
                                              <Tooltip title="Show">
                                                <IconButton
                                                  variant="contained"
                                                  color="primary"
                                                  style={{ padding: "4px" }}
                                                  onClick={() => openModal(row.id)}
                                                >
                                                  <FontAwesomeIconComponent
                                                    classes="fa fa-eye"
                                                    colorName="primary"
                                                    fontSize={"small"}
                                                  />
                                                </IconButton>
                                              </Tooltip>
                                              <Tooltip title="Download">
                                                <IconButton
                                                  variant="contained"
                                                  color="primary"
                                                  style={{ padding: "4px" }}
                                                  onClick={(id) => downloadSlip(row.id)}
                                                >
                                                  <FontAwesomeIconComponent
                                                    classes="fa fa-download"
                                                    colorName="primary"
                                                    fontSize={"small"}
                                                  />
                                                </IconButton>
                                              </Tooltip>
                                              <Tooltip title="Delete">
                                                <IconButton
                                                  variant="contained"
                                                  color="primary"
                                                  style={{ padding: "4px" }}
                                                  onClick={() => removeUser(row.id)}
                                                >
                                                  <FontAwesomeIconComponent
                                                    classes="fa fa-trash"
                                                    colorName="primary"
                                                    fontSize={"small"}
                                                  />
                                                </IconButton>
                                              </Tooltip>
                                            </React.Fragment>
                                          );
                                        })}
                                      </StyledTableCell>
                                    );
                                  }

                                  const value = row[column.id];
                                  return (
                                    <StyledTableCell
                                      key={column.id}
                                      align={column.align}
                                    >
                                      {column.format &&
                                        typeof value === "number"
                                        ? column.format(value)
                                        : value}
                                    </StyledTableCell>
                                  );
                                })}
                              </StyledTableRow>
                            );
                          })
                        ) : (
                          <StyledTableRow>
                            <StyledTableCell colSpan={columns.length} align="center">
                              No data found
                            </StyledTableCell>
                          </StyledTableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage1}
                    onChangeRowsPerPage={handleChangeRowsPerPage1}
                  />
                </Paper>
              </TabPanel>
            </SwipeableViews>

            {Pdf && (
              <Dialog
                open={Pdf}
                onClick={() => setPdf(false)}
              >
                <DialogTitle>Salary Slip</DialogTitle>
                <DialogContent>
                  {slip && (
                    <iframe title="Salary Slip" src={slip} width="700px" height="800px" />
                  )}
                </DialogContent>
              </Dialog>
            )}
          </div>
        }
      />
      <Footer />
    </div>
  );
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#1B6E95",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default Profile;
            