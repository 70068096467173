import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, Box, Typography, Button } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Authapi from '../Services/Authapi';
import ls from "local-storage";

const AuthCheckComponent = (props) => {
    const history = useHistory();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (props && props.authToken === false) {
            setOpen(true);
        }
    }, [props]);

    const handleRefresh = async () => {
        setOpen(false);

        try {
            let formData = {
                user_id: ls("user").id
            };
            const newToken = await Authapi.refreshToken(formData);
            // console.log('New Token:', newToken);

            localStorage.setItem('authToken', newToken.access_token);
            localStorage.setItem('roles', newToken.roles);

            // Set a flag in local storage to indicate the token has been refreshed
            localStorage.setItem('tokenRefreshed', 'true');

            window.location.reload();
        } catch (error) {
            console.error('Error refreshing token:', error);
        }
    };


    useEffect(() => {
        // Check if the token has already been refreshed
        // const tokenRefreshed = localStorage.getItem('tokenRefreshed');
        // if (!tokenRefreshed) {
        //     handleRefresh();
        // } else {
        //     // Remove the flag after the first reload to allow future refreshes
        //     localStorage.removeItem('tokenRefreshed');
        // }
        handleRefresh1()
    }, []);

    const handleRefresh1 = async () => {
        setOpen(false);

        try {
            let formData = {
                user_id: ls("user").id
            };
            const newToken = await Authapi.refreshToken(formData);
            // console.log('New Token:', newToken);

            localStorage.setItem('authToken', newToken.access_token);
            localStorage.setItem('roles', newToken.roles);

            // Set a flag in local storage to indicate the token has been refreshed
            localStorage.setItem('tokenRefreshed', 'true');

            // window.location.reload();
        } catch (error) {
            console.error('Error refreshing token:', error);
        }
    };

    return (
        <div>
            <Modal
                open={open}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                        textAlign: 'center'
                    }}
                >
                    <ErrorOutlineIcon sx={{ fontSize: 50, color: 'red' }} />
                    <Typography id="modal-title" variant="h6" component="h2" sx={{ color: 'red', mt: 2 }}>
                        Error: Cannot process your entry!
                    </Typography>
                    <Typography id="modal-description" sx={{ mt: 2 }}>
                        Since Long Your Screen Is Ideal. Please Refresh To Work Smoothly.
                    </Typography>
                    <Button variant="contained" color="error" onClick={handleRefresh} sx={{ mt: 2 }}>
                        Continue
                    </Button>
                </Box>
            </Modal>
        </div>
    );
};

export default AuthCheckComponent;