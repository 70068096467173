import React, { useState, useEffect } from 'react';
import Header from '../../Layouts/Header';
import Footer from '../../Layouts/Footer';
import Breadcrumb from '../../Layouts/Breadcrumb';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useHistory } from 'react-router-dom';
import OfferLatter from './OfferLatter';
import Salaryslip from './Salaryslip';
import Relievingletter from './Relievingletter';
import AuthCheckComponent from '../../Layouts/Token';

const Documentslist = (props) => {
    const [selectedValue, setSelectedValue] = useState('');
    const history = useHistory();

    useEffect(() => {
        if (props.authToken === false) {
            // history.push('/login');
        }
    }, [props.authToken, history]);

    const handleSelectChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const renderContent = () => {
        switch (selectedValue) {
            case 10:
                return <OfferLatter {...props} />;
            case 20:
                return <Salaryslip {...props} />;
            case 30:
                return <Relievingletter {...props} />;
            default:
                return null;
        }
    };

    const permissions = (props.authUser && Array.isArray(props.authUser.userAccess)) ? props.authUser.userAccess : [];
    // console.log(permissions)
    return (
        <div>
              <AuthCheckComponent authToken={props.authToken} />
            <Header
                {...props}
                authUser={props.authUser}
                component={
                    <div>
                        <Card style={{ marginBottom: '3%' }}>
                            <CardContent>
                                <Breadcrumb
                                    {...props}
                                    primaryPageName="Documents List"
                                    primaryPageLink="/Documentslist"
                                    isSecondaryPage={false}
                                    secondaryPageName=""
                                />
                            </CardContent>
                        </Card>
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">All Letter</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedValue}
                                    onChange={handleSelectChange}
                                    label="All Letter"
                                >
                                    <MenuItem value={10}>Offer Letter</MenuItem>
                                    <MenuItem value={20}>Salary Slip</MenuItem>
                                    <MenuItem value={30}>Relieving Letter</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <div style={{ marginTop: '2%', marginLeft: "-93px" }}>
                            {renderContent()}
                        </div>
                    </div>
                }
            />
            <Footer />
        </div>
    );
};

export default Documentslist;
