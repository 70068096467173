import React, { useState, useEffect } from 'react';
import Header from '../../Layouts/Header';
import Footer from '../../Layouts/Footer';
import Breadcrumb from '../../Layouts/Breadcrumb';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AuthApi from "../../Services/Authapi";
import moment from "moment";
import swal from "sweetalert";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AuthCheckComponent from '../../Layouts/Token'; 

const iniDoc = {
    firstName: "",
    dojdate: "",
    mobileNo: "",
    candidateName: "",
    designation: "",
    doj: "",
    location: "",
};

const Relievingletteradd = (props) => {
    const classes = props;
    const [doc, setDoc] = useState(iniDoc);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [filteredUsers, setFilteredUsers] = useState([]); 

    useEffect(() => {
        userData(); 
    }, []);

    const userData = async () => {
        try {
            let users = await AuthApi.getData();
            if (users && users.status === true) {
                const loggedInUsers = users.data.filter(user => user.user_logged === 1);
                setFilteredUsers(loggedInUsers); 
                // console.log(loggedInUsers)
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setDoc({ ...doc, [name]: value });

        if (name === "firstName") {
            const selectedUser = filteredUsers.find(user => user.firstName === value);
            if (selectedUser) {
                setDoc(prevDoc => ({
                    ...prevDoc,
                    candidateName: selectedUser.firstName,
                    location: selectedUser.location,
                    designation: selectedUser.designation,
                    dojdate: selectedUser.doj,
                    mobileNo: selectedUser.mobileNo,
                }));
            }
        }
    };

    const validate = (values) => {
        let errors = {};
        const regex = /^[a-zA-Z]/;
        const dobRegx = /^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/;

        if (!values.firstName) {
            errors.firstName = "Cannot be blank";
        } else if (!regex.test(values.firstName)) {
            errors.firstName = "Invalid candidate name format";
        }

        if (!values.doj) {
            errors.doj = "Cannot be blank";
        } else if (!dobRegx.test(values.doj)) {
            errors.doj = "Invalid date format";
        }

        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormErrors(validate(doc));
        setIsSubmitting(true);
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmitting) {
            createLeaves();
        }
    }, [formErrors]);

    const createLeaves = async () => {
        swal({
            title: "Loading",
            text: "Please wait...",
            buttons: false,
            closeOnClickOutside: false,
            closeOnEsc: false,
            content: {
                element: "img",
                attributes: {
                    src: "https://www.boasnotas.com/img/loading2.gif",
                    style: "width: 50px;"
                }
            }
        });
        try {
            let create = await AuthApi.Relievingletterpost(doc);
            swal.close();
            if (create && create.status === true) {
                props.history.push("/Documentslist");
            } else {
                swal({
                    title: "OOPS!",
                    icon: "error",
                    text: "Something went wrong, Please try later!",
                });
            }
        } catch (error) {
            console.error("Error creating relieving letter:", error);
        }
    };

    return (
        <>
            <div>
                <AuthCheckComponent authToken={props.authToken} />
                <Header
                    {...props}
                    authUser={props.authUser}
                    component={
                        <div>
                            <Card className={classes.root} style={{ marginBottom: '3%' }}>
                                <CardContent>
                                    <Breadcrumb
                                        {...props}
                                        primaryPageName="Documentslist"
                                        primaryPageLink="/Documentslist"
                                        isSecondaryPage={true}
                                        secondaryPageName="Relieving Letter" />
                                </CardContent>
                            </Card>
                            <Grid container className={classes.root} spacing={2}>
                                <Grid item xs={12}>
                                    <Card className={classes.root}>
                                        <CardContent>
                                            <Typography variant="h2" style={{ paddingLeft: 5 }}>Add Relieving Letter</Typography>
                                            <form className={classes.form} onSubmit={handleSubmit} id="create-course-form">
                                                <Grid container className={classes.root} spacing={5}>
                                                    <Grid item xs={6}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id="demo-simple-select-label">User First Name</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                name="firstName"
                                                                value={doc.firstName}
                                                                label="User First Name"
                                                                error={formErrors.firstName && true}
                                                                onChange={handleChange}
                                                                className={formErrors.firstName && "input-error"}
                                                            >
                                                                <MenuItem value="">
                                                                    <em>None</em>
                                                                </MenuItem>
                                                                {filteredUsers.map((item) => (
                                                                    <MenuItem key={item.id} value={item.firstName}>
                                                                        {item.firstName}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                            {formErrors.firstName && (
                                                                <span className="error">{formErrors.firstName}</span>
                                                            )}
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            id="date"
                                                            variant="outlined"
                                                            label="Date of Relieving"
                                                            name="doj"
                                                            type="date"
                                                            error={formErrors.doj && true}
                                                            value={doc.doj}
                                                            onChange={handleChange}
                                                            className={formErrors.doj && "input-error"}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                        {formErrors.doj && (
                                                            <span className="error">{formErrors.doj}</span>
                                                        )}
                                                    </Grid>
                                                </Grid>

                                                <Grid container className={classes.root} spacing={3}>
                                                    <Grid item xs={4} style={{ display: 'flex' }}>
                                                        <Button type="submit" variant="contained" color="primary">
                                                            Save
                                                        </Button>
                                                        <Button type="button" style={{ marginTop: "10px", marginLeft: "10px" }} onClick={() => props.history.push('/Documentslist')}>
                                                            Cancel
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </div>
                    } 
                />
                <Footer />
            </div>
        </>
    );
}

export default Relievingletteradd;
