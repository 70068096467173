import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { Card, CardContent, Typography, Grid, Box, makeStyles, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, RadioGroup, FormControlLabel, Radio, MenuItem, Select, FormControl, InputLabel, IconButton, InputAdornment } from '@material-ui/core';
import AuthCheckComponent from '../../Layouts/Token';
import Header from '../../Layouts/Header';
import Footer from '../../Layouts/Footer';
import AuthApi from '../../Services/Authapi';
import Breadcrumb from '../../Layouts/Breadcrumb';
import swal from 'sweetalert';
import { Tabs, Tab } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import SearchIcon from '@material-ui/icons/Search';
import { FormLabel } from '@material-ui/core';
import ClearIcon from "@material-ui/icons/Clear";
import "./user.css"
import Tooltip from "@material-ui/core/Tooltip";
import FontAwesomeIconComponent from "../../Layouts/FontAwesomeIconComponent";
import { useHistory } from "react-router-dom";
import { saveAs } from 'file-saver';


const useStyles = makeStyles((theme) => ({
  profileCard: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    textAlign: 'center',
    position: 'sticky',
    top: theme.spacing(10),
  },
  profilePhoto: {
    width: '100px',
    height: '100px',
    border: '2px dashed #ccc',
    borderRadius: '50%',
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sectionTitle: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  socialIcon: {
    fontSize: 40,
    margin: theme.spacing(1),
  },
  container: {
    padding: theme.spacing(3),
  },
  cardContent: {
    padding: theme.spacing(3),
  },
  appBar: {
    marginBottom: theme.spacing(3),
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

const UserDetails = (props) => {
  const classes = useStyles();
  const { users = [], authToken, authUser } = props;
  const [userimg1, setuserimg] = useState([]);
  const [userDetails, setuserDetails] = useState("");
  const { userId } = useParams();
  const [openEditPersonal, setOpenEditPersonal] = useState(false);
  const [openEditBank, setOpenEditBank] = useState(false);
  const [openEditOffice, setOpenEditOffice] = useState(false);
  const [qualifications, setQualifications] = useState([]);
  const [photo, setPhoto] = useState('');
  const [tabIndex, setTabIndex] = useState(0);
  const [leaveMonthYear, setLeaveMonthYear] = useState(new Date());

  const history = useHistory()
  const [selectedMonthYear, setSelectedMonthYear] = useState(new Date().toISOString().slice(0, 7));
  const [salary, setSalary] = useState([]);
  const [slip, setslip] = React.useState([]);
  const [Pdf, setPdf] = React.useState(false);

  const [totalDurationTime, setTotalDurationTime] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [timeRange, setTimeRange] = useState("today");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [editPersonalInfo, setEditPersonalInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    dob: '',
    gender: '',
    qualification: '',
    lastOrganization: '',
    mobileNo: ''
  });

  const [editBankInfo, setEditBankInfo] = useState({
    bankName: '',
    bankAccNo: ''
  });

  const [editOfficeInfo, setEditOfficeInfo] = useState({
    doj: '',
    designation: ''
  });

  const [leaveData, setLeaveData] = useState([]);

  const userimg = async () => {
    if (userId) {
      let users = await AuthApi.userimgid(userId);
      setuserimg(users);
    }
  };

  const UserDetails = async () => {
    if (userId) {
      let users = await AuthApi.singleUser(userId);
      setuserDetails(users.data);
    }
  };

  useEffect(() => {
    userimg();
    UserDetails();
  }, [userId]);

  useEffect(() => {
    if (userDetails) {
      setEditPersonalInfo({
        firstName: userDetails.firstName || '',
        lastName: userDetails.lastName || '',
        email: userDetails.email || '',
        dob: userDetails.dob || '',
        gender: userDetails.gender || '',
        qualification: userDetails.qualification || '',
        last_organization: userDetails.last_organization || '',
        mobileNo: userDetails.mobileNo || '',
        photo: photo,
        roleName: userDetails.roleName,
        uploadedImgName: userDetails.uploadedImgName,
        salary: userDetails.salary,
        bankName: userDetails.bankName,
        bankAccNo: userDetails.bankAccNo,
        doj: userDetails.doj,
        location: userDetails.location,
        department: userDetails.department,
        designation: userDetails.designation,
        password: userDetails.password,
        user_id: userDetails.user_id,
        allowed_ip: userDetails.allowed_ip,
      });

      setEditBankInfo({
        bankName: userDetails.bankName || '',
        bankAccNo: userDetails.bankAccNo || '',
        firstName: userDetails.firstName || '',
        lastName: userDetails.lastName || '',
        email: userDetails.email || '',
        dob: userDetails.dob || '',
        gender: userDetails.gender || '',
        qualification: userDetails.qualification || '',
        last_organization: userDetails.last_organization || '',
        mobileNo: userDetails.mobileNo || '',
        photo: photo,
        roleName: userDetails.roleName,
        uploadedImgName: userDetails.uploadedImgName,
        salary: userDetails.salary,
        doj: userDetails.doj,
        location: userDetails.location,
        department: userDetails.department,
        designation: userDetails.designation,
        password: userDetails.password,
        user_id: userDetails.user_id,
        allowed_ip: userDetails.allowed_ip,
      });

      setEditOfficeInfo({
        doj: userDetails.doj || '',
        designation: userDetails.designation || '',
        bankName: userDetails.bankName,
        bankAccNo: userDetails.bankAccNo,
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        email: userDetails.email,
        dob: userDetails.dob,
        gender: userDetails.gender,
        qualification: userDetails.qualification,
        last_organization: userDetails.last_organization,
        mobileNo: userDetails.mobileNo,
        photo: photo,
        roleName: userDetails.roleName,
        uploadedImgName: userDetails.uploadedImgName,
        salary: userDetails.salary,
        location: userDetails.location,
        department: userDetails.department,
        password: userDetails.password,
        user_id: userDetails.user_id,
        allowed_ip: userDetails.allowed_ip,
      });

      setPhoto(userDetails.photo || '');
    }
  }, [userDetails]);

  const handleEditOpen = (section) => {
    switch (section) {
      case 'personal':
        setOpenEditPersonal(true);
        break;
      case 'bank':
        setOpenEditBank(true);
        break;
      case 'office':
        setOpenEditOffice(true);
        break;
      default:
        break;
    }
  };

  const handleEditClose = (section) => {
    switch (section) {
      case 'personal':
        setOpenEditPersonal(false);
        break;
      case 'bank':
        setOpenEditBank(false);
        break;
      case 'office':
        setOpenEditOffice(false);
        break;
      default:
        break;
    }
  };

  const handleUpdateUser = async (section) => {
    swal({
      title: "Loading",
      text: "Please wait...",
      buttons: false,
      closeOnClickOutside: false,
      closeOnEsc: false,
      width: "10%",
      content: {
        element: "img",
        attributes: {
          src: "https://www.boasnotas.com/img/loading2.gif",
          style: "width: 50px;"
        }
      }
    });
    try {
      let updateData = {};
      switch (section) {
        case 'personal':
          updateData = {
            ...editPersonalInfo,
            dob: formatDate(editPersonalInfo.dob),
            photo
          };
          break;
        case 'bank':
          updateData = {
            ...editBankInfo,
            dob: formatDate(editBankInfo.dob),
            photo
          };
          break;
        case 'office':
          updateData = {
            ...editOfficeInfo,
            dob: formatDate(editOfficeInfo.dob),
            photo
          };
          break;
        default:
          break;
      }
      await AuthApi.updateUser(updateData, userId);
      swal.close();
      UserDetails();
      userimg()
      handleEditClose(section);
    } catch (error) {
      console.error('Error updating user data:', error);
    }
  };

  function formatDate(dob) {
    if (!dob) return '';
    const parts = dob.split("-");
    return `${parts[0]}-${parts[1]}-${parts[2]}`;
  }

  useEffect(() => {
    const month = leaveMonthYear.getMonth() + 1;
    const year = leaveMonthYear.getFullYear();
    userreportAttendance(userId, timeRange);
    fetchLeaveTickets(userId, month, year);
    getQualificationData()
  }, [userId, timeRange, leaveMonthYear]);

  const getQualificationData = async () => {
    try {
      const response = await AuthApi.getQulification();
      if (response && response.data) {
        setQualifications(response.data);
      }
    } catch (error) {
      console.error('Error fetching qualification data:', error);
    }
  };

  const handlePersonalInfoChange = (e) => {
    const { name, value } = e.target;
    if (name === 'dob') {
      // Convert the date from yyyy-MM-dd to dd-MM-yyyy for storage
      const [year, month, day] = value.split('-');
      const formattedDate = `${day}-${month}-${year}`;
      setEditPersonalInfo((prev) => ({ ...prev, [name]: formattedDate }));
    } else {
      setEditPersonalInfo((prev) => ({ ...prev, [name]: value }));
    }
  };
  const handleBankInfoChange = (e) => {
    const { name, value } = e.target;
    setEditBankInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleOfficeInfoChange = (e) => {
    const { name, value } = e.target;
    setEditOfficeInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  function formatDate(dateString) {
    if (!dateString) return '';
    const parts = dateString.split("-");
    // Ensure the date is in the format yyyy-mm-dd
    if (parts.length === 3) {
      return `${parts[2]}-${parts[1]}-${parts[0]}`; // Convert to dd-mm-yyyy
    }
    return dateString; // Return original if format is unexpected
  }

  const handleTimeRangeChange = (event) => {
    setTimeRange(event.target.value);
    userreportAttendance(userId, event.target.value);
  };

  const handleDateRangeChange = async (startDate, endDate) => {
    if (startDate && endDate) {
      const [startYear, startMonth, startDay] = startDate.split("-");
      const [endYear, endMonth, endDay] = endDate.split("-");
      const response = await AuthApi.getUserReportByDateRange(userId, startDay, startMonth, startYear, endDay, endMonth, endYear);
      if (response && response.data) {
        const formattedData = response.data.map((item, index) => ({
          id: index,
          name: item.user_name,
          firstName: item.firstname,
          status: item.status,
          date: item.date,
          lastouttime: item.last_out_time,
          firstintime: item.first_in_time,
          breaks: item.breaks,
          breakduration: item.break_duration,
          totalduration: item.total_duration,
        }));
        setTotalDurationTime(formattedData);
        setOriginalData(formattedData);
      } else {
        setTotalDurationTime([]);
      }
    }
  };

  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    setEndDate(newEndDate);
    handleDateRangeChange(startDate, newEndDate);
  };

  const userreportAttendance = async (userId, timeRange) => {
    try {
      const response = await AuthApi.userreportAttendance(userId, timeRange);

      if (response && response.data) {
        const formattedData = response.data.map((item, index) => ({
          id: index,
          name: item.user_name,
          firstName: item.firstname,
          status: item.status,
          date: item.date,
          lastouttime: item.last_out_time,
          firstintime: item.first_in_time,
          breaks: item.breaks,
          breakduration: item.break_duration,
          totalduration: item.total_duration,
        }));
        // console.log("Formatted Data: ", formattedData); 
        setTotalDurationTime(formattedData);
        setOriginalData(formattedData);
      } else {
        setTotalDurationTime([]);
      }
    } catch (error) {
      console.error('Error fetching attendance data:', error);
      setTotalDurationTime([]);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearch = () => {
    const query = searchQuery.toLowerCase();
    const filteredData = originalData.filter((item) =>
      Object.values(item).some(
        (value) => value.toString().toLowerCase().indexOf(query) > -1
      )
    );
    setTotalDurationTime(filteredData);
  };

  const handleClearSearch = () => {
    setSearchQuery("");
    setTotalDurationTime(originalData);
  };

  const columns = [
    { field: 'name', headerName: 'Employee ID', flex: 1, headerClassName: 'header-red' },
    { field: 'firstName', headerName: 'Name', flex: 1, headerClassName: 'header-red' },
    { field: 'date', headerName: 'Date', flex: 1, headerClassName: 'header-red' },
    { field: 'status', headerName: 'Status', flex: 1, headerClassName: 'header-red' },
    { field: 'firstintime', headerName: 'First In Time', flex: 1, headerClassName: 'header-red' },
    { field: 'lastouttime', headerName: 'Last Out Time', flex: 1, headerClassName: 'header-red' },
    { field: 'totalduration', headerName: 'Total Duration', flex: 1, headerClassName: 'header-red' },
    { field: 'breakduration', headerName: 'Break Duration', flex: 1, headerClassName: 'header-red' },
    { field: 'breaks', headerName: 'Breaks', flex: 1, headerClassName: 'header-red' },
  ];

  const leaveColumns = [
    { field: 'user_id', headerName: 'Employee ID', flex: 1, headerClassName: 'header-red' },
    { field: 'firstName', headerName: 'Name', flex: 1, headerClassName: 'header-red' },
    { field: 'leaveType', headerName: 'Leave Type', flex: 1, headerClassName: 'header-red' },
    { field: 'ticket_mesasge', headerName: 'Ticket Mesasge', flex: 1, headerClassName: 'header-red' },
    { field: 'date_range', headerName: 'Date  Range', flex: 1, headerClassName: 'header-red' },
    { field: 'no_of_days', headerName: 'No Of Days', flex: 1, headerClassName: 'header-red' },
    { field: 'status', headerName: 'Status', flex: 1, headerClassName: 'header-red' },
    { field: 'cancel_reason', headerName: 'Reason for Declined', flex: 1, headerClassName: 'header-red' },
    { field: 'c_reason', headerName: 'Reason for Cancel', flex: 1, headerClassName: 'header-red' },
    { field: 'created_at', headerName: 'Created At', flex: 1, headerClassName: 'header-red' },
    { field: 'updated_at', headerName: 'Updated', flex: 1, headerClassName: 'header-red' },
  ];

  const fetchLeaveTickets = async (userId, month, year) => {
    try {
      const response = await AuthApi.leavetickets(userId, month, year);
      console.log(response)
      if (response && response.data) {
        setLeaveData(response.data);
      } else {
        setLeaveData([]);
      }
    } catch (error) {
      console.error("Error fetching leave tickets:", error);
      setLeaveData([]);
    }
  };

  const handleMonthChange = (event) => {
    const date = new Date(event.target.value);
    setLeaveMonthYear(date);
  };

  const openModal = async (user_id) => {
    try {
      const slip = await AuthApi.viewSalarySlip(user_id);
      if (slip) {
        setslip(slip)
      } else {
      }
      setPdf(true)
    } catch (error) {
    }
  };

  const downloadSlip = async (id) => {
    try {
      swal({
        title: "Salaries Downloading...",
        text: "Please wait...",
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        width: "10%",
        content: {
          element: "img",
          attributes: {
            src: "https://www.boasnotas.com/img/loading2.gif",
            style: "width: 50px;"
          }
        }
      });

      const slip = await AuthApi.getAllSalaryPdf(id);
      if (slip && slip.status === true) {
      }
      swal.close();
    } catch (error) {
      console.error("Error downloading salary slip:", error);
      swal({
        title: "Error",
        text: "Failed to download salary slip. Please try again later.",
        icon: "error",
      });
    }
  }


  // const removeUser = async (id) => {
  //   swal({
  //     title: "Are you sure?",
  //     icon: "warning",
  //     buttons: ["Cancel", "Yes"],
  //   }).then(async (confirm) => {
  //     if (confirm) {
  //       let currentUser = await AuthApi.Salarydelete(id);
  //       if (currentUser && currentUser.status === true) {
  //         history.push("/dashboard");
  //       } else {
  //       }
  //     }
  //   });
  // }

  const salaryColumns = [
    { field: 'name', headerName: 'Employee ID', flex: 1, headerClassName: 'header-red' },
    { field: 'firstName', headerName: 'Name', flex: 1, headerClassName: 'header-red' },
    { field: 'BasicSalary', headerName: 'Basic Salary', flex: 1, headerClassName: 'header-red' },
    { field: 'DA', headerName: 'DA', flex: 1, headerClassName: 'header-red' },
    { field: 'HRA', headerName: 'HRA', flex: 1, headerClassName: 'header-red' },
    { field: 'CA', headerName: 'CA', flex: 1, headerClassName: 'header-red' },
    { field: 'Tax', headerName: 'Tax', flex: 1, headerClassName: 'header-red' },
    { field: 'netSalary', headerName: 'Net Salary', flex: 1, headerClassName: 'header-red' },
    { field: 'MonthYear', headerName: 'Month Year', flex: 1, headerClassName: 'header-red' },
    {
      field: 'actions', headerName: 'Actions', flex: 1, headerClassName: 'header-red', renderCell: (params) => (
        <>
          <Tooltip title="Show">
            <IconButton
              variant="contained"
              color="primary"
              style={{ padding: "4px" }}
              onClick={() => openModal(params.id)}
            >
              <FontAwesomeIconComponent
                classes="fa fa-eye"
                colorName="primary"
                fontSize={"small"}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Download">
            <IconButton
              variant="contained"
              color="primary"
              style={{ padding: "4px" }}
              onClick={(id) => downloadSlip(params.id)}
            >
              <FontAwesomeIconComponent
                classes="fa fa-download"
                colorName="primary"
                fontSize={"small"}
              />
            </IconButton>
          </Tooltip>
          {/* <Tooltip title="Delete">
            <IconButton
              variant="contained"
              color="primary"
              style={{ padding: "4px" }}
              onClick={() => removeUser(params.id)}
            >
              <FontAwesomeIconComponent
                classes="fa fa-trash"
                colorName="primary"
                fontSize={"small"}
              />
            </IconButton>
          </Tooltip> */}
        </>
      )
    },
  ];

  const imgUpload = async (e) => {
    const postData = new FormData();
    postData.append("file", e.target.files[0]);
    try {
      let updateImg = await AuthApi.updateImg(postData);
      if (updateImg && updateImg.status === true) {
        setPhoto(updateImg.data.image_url);
        setEditPersonalInfo((prev) => ({
          ...prev,
          photo: updateImg.data.image_url,
          uploadedImgName: updateImg.data.image_name,
        }));
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleMonthYearChange = (event) => {
    setSelectedMonthYear(event.target.value);
  };

  useEffect(() => {
    const fetchSalaryData = async () => {
      const [year, month] = selectedMonthYear.split("-");
      try {
        const response = await AuthApi.alldatasalaryid(parseInt(month), year, userId);
        if (response && response.status === true) {
          setSalary(response.data);
        } else {
          setSalary([]);
        }
      } catch (error) {
        console.error("Error fetching salary data:", error);
        setSalary([]);
      }
    };

    fetchSalaryData();
  }, [selectedMonthYear, userId]);

  const downloadAttendanceCSV = () => {
    const csvRows = [];
    const headers = columns.map(col => col.headerName);
    csvRows.push(headers.join(','));

    totalDurationTime.forEach(row => {
      const values = columns.map(col => row[col.field]);
      csvRows.push(values.join(','));
    });

    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    saveAs(blob, 'user_attendance.csv');
  };

  return (
    <div>
      <AuthCheckComponent authToken={authToken} />
      <Header
        {...props}
        authUser={authUser}
        component={
          <div>
            <Card className={classes.root} style={{ marginBottom: '3%' }}>
              <CardContent>
                <Breadcrumb
                  {...props}
                  primaryPageName="Users"
                  primaryPageLink="/users"
                  isSecondaryPage={true}
                  secondaryPageName="User Profile" />
              </CardContent>
            </Card>

            <div style={{ width: "100%" }}>
              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                aria-label="settings tabs"
              >
                <Tab label="User Profile" />
                <Tab label="User Attendance " />
                <Tab label="Leave" />
                <Tab label="Salary Slip" />
              </Tabs>

              <TabPanel value={tabIndex} index={0}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <Card className={classes.profileCard}>
                      <CardContent>
                        <Box className={classes.profilePhoto} style={{ display: "contents" }}>
                          <img src={userimg1.data} alt="User" style={{ width: "100px", height: "100px", borderRadius: "50%" }} />
                        </Box>


                        <Typography variant="body1" style={{ marginTop: '20px' }}>Development</Typography>
                        <Typography variant="body2">{userDetails.department || '-'}</Typography>
                        <Typography variant="body1" style={{ marginTop: '10px' }}>Registered Email</Typography>
                        <Typography variant="body2">{userDetails.email}</Typography>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12} sm={8} style={{ marginBottom: "50px" }}>
                    <Card className={classes.profileCard}>
                      <CardContent className={classes.cardContent}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Typography variant="h6" className={classes.sectionTitle}>PERSONAL INFO</Typography>
                            <Button variant="contained" color="primary" style={{ float: 'right' }} onClick={() => handleEditOpen('personal')}>Edit</Button>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography style={{ fontWeight: "bold" }}>Name</Typography>
                            <Typography>{userDetails.firstName} {userDetails.lastName}</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography style={{ fontWeight: "bold" }}>Official Email ID</Typography>
                            <Typography>{userDetails.email}</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography style={{ fontWeight: "bold" }}>Phone Number</Typography>
                            <Typography>{userDetails.mobileNo || '-'}</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography style={{ fontWeight: "bold" }}>Date of Birth</Typography>
                            <Typography>{userDetails.dob || '-'}</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography style={{ fontWeight: "bold" }}>Gender</Typography>
                            <Typography>{userDetails.gender || '-'}</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography style={{ fontWeight: "bold" }}>Qualification</Typography>
                            <Typography>{userDetails.qualification || '-'}</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography style={{ fontWeight: "bold" }}>Last Organization</Typography>
                            <Typography>{userDetails.last_organization || '-'}</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography style={{ fontWeight: "bold" }}>Address</Typography>
                            <Typography>{userDetails.location || '-'}</Typography>
                          </Grid>
                        </Grid>

                        <Grid container spacing={3} style={{ marginTop: '20px' }}>
                          <Grid item xs={12}>
                            <Typography variant="h6" className={classes.sectionTitle}>Bank INFO</Typography>
                            <Button variant="contained" color="primary" style={{ float: 'right' }} onClick={() => handleEditOpen('bank')}>Edit</Button>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography style={{ fontWeight: "bold" }}>Bank Name</Typography>
                            <Typography>{userDetails.bankName || '-'}</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography style={{ fontWeight: "bold" }}>Bank Acc No</Typography>
                            <Typography>{userDetails.bankAccNo || '-'}</Typography>
                          </Grid>
                        </Grid>

                        <Grid container spacing={3} style={{ marginTop: '20px' }}>
                          <Grid item xs={12}>
                            <Typography variant="h6" className={classes.sectionTitle}>Office INFO</Typography>
                            <Button variant="contained" color="primary" style={{ float: 'right' }} onClick={() => handleEditOpen('office')}>Edit</Button>
                          </Grid>
                          {/* <Grid item xs={12} sm={6}>
                            <Typography style={{ fontWeight: "bold" }}>Date Of Joining</Typography>
                            <Typography>{userDetails.doj || '-'}</Typography>
                          </Grid> */}
                          <Grid item xs={12} sm={6}>
                            <Typography style={{ fontWeight: "bold" }}>Date Of Joining</Typography>
                            <Typography>{userDetails.doj ? formatDate(userDetails.doj) : '-'}</Typography> {/* Updated to format date */}
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Typography style={{ fontWeight: "bold" }}>Designation</Typography>
                            <Typography>{userDetails.designation || '-'}</Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>

                {/* Edit Dialogs */}
                <Dialog open={openEditPersonal} onClose={() => handleEditClose('personal')}>
                  <DialogTitle>Edit Personal Info</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Update your personal information here.
                    </DialogContentText>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          autoFocus
                          margin="dense"
                          label="First Name"
                          name="firstName"
                          value={editPersonalInfo.firstName}
                          onChange={handlePersonalInfoChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          margin="dense"
                          label="Last Name"
                          name="lastName"
                          value={editPersonalInfo.lastName}
                          onChange={handlePersonalInfoChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          margin="dense"
                          label="Email Address"
                          name="email"
                          value={editPersonalInfo.email}
                          onChange={handlePersonalInfoChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography
                          component="span"
                          style={{ float: "left" }}
                          variant="body2"
                          display="block"
                        >
                          <img
                            src={userimg1.data}
                            alt="User profile image"
                            width="100"
                            height="100"
                            style={{
                              borderRadius: "50%",
                              height: "70px",
                              width: "70px"
                            }}
                          />
                        </Typography>
                        <input
                          accept="image/*"
                          style={{ display: 'none' }}
                          id="raised-button-file"
                          type="file"
                          onChange={(e) => imgUpload(e)}
                        />
                        <label htmlFor="raised-button-file">
                          <Button variant="contained" color="primary" component="span" style={{
                            left: "16px",
                            top: "18px"
                          }}>
                            Update Picture
                          </Button>
                        </label>

                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          autoFocus
                          margin="dense"
                          label="Birthday"
                          type="date"
                          name="dob"
                          value={formatDate(editPersonalInfo.dob)}
                          onChange={handlePersonalInfoChange}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={4} style={{ display: "flex" }}>
                        <FormControl component="fieldset">
                          <Grid container className={classes.root} spacing={4}>
                            <Grid item xs={12} style={{ display: "flex" }}>
                              <FormLabel component="legend">Gender</FormLabel>
                            </Grid>
                          </Grid>
                          <RadioGroup
                            aria-label="gender"
                            name="gender"
                            onChange={handlePersonalInfoChange}
                            value={editPersonalInfo.gender}
                          >
                            <Grid container className={classes.root} spacing={2}>
                              <Grid item xs={6}>
                                <FormControlLabel
                                  value="Female"
                                  control={<Radio />}
                                  label="Female"
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <FormControlLabel
                                  value="Male"
                                  control={<Radio />}
                                  label="Male"
                                />
                              </Grid>
                            </Grid>
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" style={{ width: '100%' }}>
                          <InputLabel id="demo-simple-select-outlined-label">Qualification </InputLabel>
                          <Select
                            fullWidth
                            id="outlined-basic"
                            name="qualification"
                            label="Qualification"
                            variant="outlined"
                            value={editPersonalInfo.qualification}
                            onChange={handlePersonalInfoChange}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {qualifications.map((qualification) => (
                              <MenuItem key={qualification.id} value={qualification.qualification}>
                                {qualification.qualification}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          margin="dense"
                          label="Last Organization"
                          name="last_organization"
                          value={editPersonalInfo.last_organization}
                          onChange={handlePersonalInfoChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          margin="dense"
                          label="Phone Number"
                          name="mobileNo"
                          value={editPersonalInfo.mobileNo}
                          onChange={handlePersonalInfoChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          margin="dense"
                          label="Location"
                          name="location"
                          value={editPersonalInfo.location}
                          onChange={handlePersonalInfoChange}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => handleEditClose('personal')} color="primary">Cancel</Button>
                    <Button onClick={() => handleUpdateUser('personal')} color="primary">Save</Button>
                  </DialogActions>
                </Dialog>

                <Dialog open={openEditBank} onClose={() => handleEditClose('bank')}>
                  <DialogTitle>Edit Bank Info</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Update your bank information here.
                    </DialogContentText>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          autoFocus
                          margin="dense"
                          label="Bank Name"
                          name="bankName"
                          value={editBankInfo.bankName}
                          onChange={handleBankInfoChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          margin="dense"
                          label="Bank Account Number"
                          name="bankAccNo"
                          value={editBankInfo.bankAccNo}
                          onChange={handleBankInfoChange}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Button onClick={() => handleEditClose('bank')} color="primary">Cancel</Button>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Button onClick={() => handleUpdateUser('bank')} color="primary">Save</Button>
                      </Grid>
                    </Grid>
                  </DialogActions>
                </Dialog>

                <Dialog open={openEditOffice} onClose={() => handleEditClose('office')}>
                  <DialogTitle>Edit Office Info</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Update your office information here.
                    </DialogContentText>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          autoFocus
                          margin="dense"
                          label="Date Of Joining"
                          name="doj"
                          type="date"
                          value={editOfficeInfo.doj}
                          onChange={handleOfficeInfoChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          margin="dense"
                          label="Designation"
                          name="designation"
                          value={editOfficeInfo.designation}
                          onChange={handleOfficeInfoChange}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Button onClick={() => handleEditClose('office')} color="primary">Cancel</Button>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Button onClick={() => handleUpdateUser('office')} color="primary">Save</Button>
                      </Grid>
                    </Grid>
                  </DialogActions>
                </Dialog>
              </TabPanel>

              <TabPanel value={tabIndex} index={1}>
                <Grid container spacing={4} style={{ marginBottom: '-11px' }}>

                  <Grid item xs={3} >
                    <FormControl variant="outlined" style={{ marginBottom: '20px', width: "89%" }} position="start">
                      <InputLabel id="time-range-label">Time Range</InputLabel>
                      <Select
                        labelId="time-range-label"
                        id="time-range"
                        value={timeRange}
                        onChange={handleTimeRangeChange}
                        label="Time Range"
                      >
                        <MenuItem value="today">Today</MenuItem>
                        <MenuItem value="last7days">Last 7 Days</MenuItem>
                        <MenuItem value="last30days">Last 30 Days</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <TextField
                      label="Start Date"
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <TextField
                      label="End Date"
                      type="date"
                      value={endDate}
                      onChange={handleEndDateChange}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={3} >
                    <TextField
                      label="Search"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: searchQuery !== "",
                        style: {
                          color: '#1b6e95',
                          transform: searchQuery !== "" ? 'translate(14px, -6px) scale(0.75)' : 'translate(14px, 11px) scale(1)',
                          transition: 'transform 0.2s ease-in-out'
                        }
                      }}
                      InputProps={{
                        style: {
                          backgroundColor: 'lightgray',
                          borderRadius: '4px',
                          height: "40px"
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            {searchQuery && (
                              <ClearIcon onClick={handleClearSearch} style={{ cursor: 'pointer' }} />
                            )}
                            <SearchIcon onClick={handleSearch} style={{ cursor: 'pointer' }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <div style={{ height: 400, width: '100%' }}>
                  <DataGrid
                    rows={totalDurationTime}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 20]}
                    pagination
                  />
                </div>
                <Grid item xs={3} style={{ maxWidth: "21%", marginTop: "-44px", marginBottom: "50px" }} >
                  <Button variant="contained" color="primary" onClick={downloadAttendanceCSV}>
                    Download Attendance CSV
                  </Button>
                </Grid>
              </TabPanel>

              <TabPanel value={tabIndex} index={2}>
                <Grid container spacing={3}>
                  <Grid item xs={2}>
                    <TextField
                      id="datetime-local"
                      label="Select Month/Year"
                      views={["year", "month"]}
                      variant="outlined"
                      defaultValue={leaveMonthYear.toISOString().slice(0, 7)}
                      type="month"
                      onChange={handleMonthChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ min: "2000-01", max: new Date().toISOString().slice(0, 7) }}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <div style={{ height: 400, width: '100%', marginTop: '20px', marginBottom:"40px" }}>
                  <DataGrid
                    rows={leaveData}
                    columns={leaveColumns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 20]}
                    pagination
                  />
                </div>
              </TabPanel>

              <TabPanel value={tabIndex} index={3}>
                <Grid container spacing={3} style={{ marginBottom: '20px' }}>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      id="datetime-local"
                      label="Select Month/Year"
                      variant="outlined"
                      type="month"
                      value={selectedMonthYear}
                      onChange={handleMonthYearChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ min: "2000-01", max: new Date().toISOString().slice(0, 7) }}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <div style={{ height: 400, width: '100%', marginBottom:"40px" }}>
                  <DataGrid
                    rows={salary}
                    columns={salaryColumns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 20]}
                    pagination
                  />
                </div>
              </TabPanel>
              {Pdf && (
                <Dialog
                  open={Pdf}
                  onClick={() => setPdf(false)}
                  style={{ marginTop: "35px" }}
                >
                  <DialogTitle>Salary Slip</DialogTitle>
                  <DialogContent>
                    {slip && (
                      <iframe title="Salary Slip" src={slip} width="100%" height="500px" />
                    )}
                  </DialogContent>
                </Dialog>
              )}
            </div>
          </div>
        }
      />
      <Footer {...props} />
    </div>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export default UserDetails;