/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Header from "../../Layouts/Header";
import Footer from "../../Layouts/Footer";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Breadcrumb from "../../Layouts/Breadcrumb";
import Typography from "@material-ui/core/Typography";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import AuthApi from "../../Services/Authapi";
import swal from "sweetalert";
import moment from "moment";
import AuthCheckComponent from "../../Layouts/Token";

const inileaves = {
  leaveName: "",
  date: "",
};

const AddLeaves = (props) => {
  const classes = props;
  const [leaveValues, setLeaveValues] = useState(inileaves);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (props && props.authToken === false) {
      // props.history.push("/login");
    }
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLeaveValues({ ...leaveValues, [name]: value });
  };

  const validate = (values) => {
    let errors = {};
    const regex = /^[a-zA-Z]/;
    const dateR = /^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/;

    if (!values.leaveName) {
      errors.leaveName = "Cannot be blank";
    } else if (!regex.test(values.leaveName)) {
      errors.leaveName = "Invalid leave name format";
    }

    if (!values.date) {
      errors.date = "Cannot be blank";
    } else if (!dateR.test(values.date)) {
      errors.date = "Invalid date format";
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors(validate(leaveValues));
    setIsSubmitting(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmitting) {
      createLeaves();
    }
  }, [formErrors]);

  const createLeaves = async () => {
    swal({
      title: "Loading",
      text: "Please wait...",
      buttons: false,
      closeOnClickOutside: false,
      closeOnEsc: false,
      width: "10%",
      content: {
        element: "img",
        attributes: {
          src: "https://www.boasnotas.com/img/loading2.gif",
          style: "width: 50px;", 
        },
      },
    });
    try {
      const formattedDate = moment(leaveValues.date, "YYYY-MM-DD").format("DD-MM-YYYY");
      const dataToSend = { ...leaveValues, date: formattedDate };
  
      let create = await AuthApi.createHoliday(dataToSend);
      swal.close();
      console.log(create);
  
      if (create && create.status === true) {
        props.history.push("/leaves");
      } else {
        swal({
          title: "OOPS",
          text: create.message || "The leave name has already been taken.",
          icon: "error",
        });
      }
    } catch (error) {
      swal.close();
      console.error("Error creating leave:", error);
      swal({
        title: "Error",
        text: error.response?.data?.message || "An error occurred while creating the leave.",
        icon: "error",
      });
    }
  };

  return (
    <div>
      <AuthCheckComponent authToken={props.authToken} />
      <Header
        {...props}
        authUser={props.authUser}
        component={
          <div>
            <Card className={classes.root} style={{ marginBottom: "3%" }}>
              <CardContent>
                <Breadcrumb
                  {...props}
                  primaryPageName="Holidays"
                  primaryPageLink="/leaves"
                  isSecondaryPage={true}
                  secondaryPageName="Add Holiday"
                />
              </CardContent>
            </Card>
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12}>
                <Card className={classes.root}>
                  <CardContent>
                    <Typography variant="h2">Add Holiday Leave</Typography>
                    <form className={classes.form} onSubmit={handleSubmit}>
                      <Grid container className={classes.root} spacing={5}>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            id="leaveName"
                            name="leaveName"
                            label="Holiday Name"
                            variant="outlined"
                            error={formErrors.leaveName && true}
                            value={leaveValues.leaveName}
                            onChange={handleChange}
                            className={formErrors.leaveName && "input-error"}
                          />
                          {formErrors.leaveName && (
                            <span className="error">
                              {formErrors.leaveName}
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            id="date"
                            variant="outlined"
                            label="Date"
                            name="date"
                            type="date"
                            error={formErrors.date && true}
                            value={leaveValues.date}
                            onChange={handleChange}
                            className={formErrors.date && "input-error"}
                            className={classes.textField}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          {formErrors.date && (
                            <span className="error">{formErrors.date}</span>
                          )}
                        </Grid>
                      </Grid>

                      <Grid container className={classes.root} spacing={3}>
                        <Grid item xs={4} style={{ display: "flex" }}>
                          <Button
                            type="submit"
                            style={{ marginTop: "10px" }}
                            variant="contained"
                            color="primary"
                          >
                            Save
                          </Button>
                          <Button
                            type="button"
                            style={{ marginTop: "10px" }}
                            onClick={(e) => {
                              e.preventDefault();
                              props.history.push("/leaves");
                            }}
                          >
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={4}></Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}></Grid>
            </Grid>
          </div>
        }
      />
      <Footer />
    </div>
  );
};

export default AddLeaves;
