import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Breadcrumb from "./Breadcrumb";
import Card from "@material-ui/core/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Badge from "@mui/material/Badge";
import Authapi from "../Services/Authapi";
import ls from "local-storage";
import * as FaIcons from "react-icons/fa";
import * as MdIcons from "react-icons/md";
import * as GiIcons from "react-icons/gi";
// import * as AiIcons from "react-icons/ai";
// import * as BiIcons from "react-icons/bi";
// import * as RiIcons from "react-icons/ri";
// import * as CgIcons from "react-icons/cg";
// import * as BsIcons from "react-icons/bs";
// import * as FiIcons from "react-icons/fi";
// import * as IoIcons from "react-icons/io5";
// import * as HiIcons from "react-icons/hi";
// import * as GrIcons from "react-icons/gr";
// import * as DiIcons from "react-icons/di";
import "./detailedNotifications.css";
import { useHistory } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";

const allIcons = {
  ...FaIcons,
  ...MdIcons,
  ...GiIcons,
  // ...AiIcons,
  // ...BiIcons,
  // ...RiIcons,
  // ...CgIcons,
  // ...BsIcons,
  // ...FiIcons,
  // ...IoIcons,
  // ...HiIcons,
  // ...GrIcons,
  // ...DiIcons,
  // Add more icon packs here if needed
};

const iconStyle = { color: "#1b6e95", marginRight: "8px", fontSize: "24px" }; 
const headerStyle = { backgroundColor: "#1b6e95", color: "#fff", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "04px" };

function formatDate(dateString) {
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    timeZone: "Asia/Kolkata"
  };
  return new Intl.DateTimeFormat("en-GB", options).format(new Date(dateString));
}

const DetailedNotifications = (props) => {
  const [notifications, setNotifications] = useState({});
  const [readNotifications, setReadNotifications] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedGroup, setSelectedGroup] = useState("All Notifications");
  const [notificationIcons, setNotificationIcons] = useState({});
  const [expandedGroups, setExpandedGroups] = useState({});
  const [sidebarExpandedGroups, setSidebarExpandedGroups] = useState({}); 
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        let notificationsData =
          ls("roles") === "Super Admin"
            ? await Authapi.Notification()
            : await Authapi.NotificationEmployee();
        setNotifications(notificationsData);

        let readNotificationsData =
          ls("roles") === "Super Admin"
            ? await Authapi.Readedadminnotifications()
            : await Authapi.Readedemployeenotifications();
        setReadNotifications(readNotificationsData);

        // Fetch saved icons from the backend
        const iconsResponse = await Authapi.getNotificationIcons();
        if (iconsResponse) {
          // Transform the keys to replace underscores with spaces
          const transformedIcons = {};
          Object.keys(iconsResponse).forEach((key) => {
            transformedIcons[key.replace(/_/g, " ")] = iconsResponse[key];
          });
          setNotificationIcons(transformedIcons);
        }

        // Initialize expandedGroups with all groups set to true
        const initialExpandedGroups = {};
        Object.keys(notificationsData).forEach((type) => {
          initialExpandedGroups[type] = true;
        });
        Object.keys(readNotificationsData).forEach((type) => {
          initialExpandedGroups[`Readed_${type}`] = true;
        });
        setExpandedGroups(initialExpandedGroups);

        // Initialize sidebarExpandedGroups with "All Notifications" and "Readed Notifications" set to true
        const initialSidebarExpandedGroups = {
          "All Notifications": true,
          "Readed Notifications": true,
        };
        Object.keys(notificationsData).forEach((type) => {
          initialSidebarExpandedGroups[type] = false;
        });
        Object.keys(readNotificationsData).forEach((type) => {
          initialSidebarExpandedGroups[`Readed_${type}`] = false;
        });
        setSidebarExpandedGroups(initialSidebarExpandedGroups);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleGroupClick = (group) => {
    setSelectedGroup(group);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = async (notification) => {
    try {
      await Authapi.Notificationread(notification.id);
      const notificationData = {
        id: notification.id,
        type: notification.type,
        dataId: notification.data_id,
      };
      sessionStorage.setItem("fromNotificationPanel", "true");
      switch (notification.type) {
        case "Leave Ticket":
        case "Leave Status":
          history.push({
            pathname: "/tickets",
            state: { notificationData },
          });
          break;
        case "Time-Entry":
          sessionStorage.setItem("fromNotificationPanel", "true");
          history.push({
            pathname: "/check",
            state: { notificationData },
          });
          break;
        case "Time-Entry Status":
          sessionStorage.setItem('fromNotificationPanel', 'true');
          history.push({
            pathname: "/check",
            state: { notificationData }
          });
          break;
        case "Upcoming Event":
          const eventId = notification.data_id;
          sessionStorage.setItem("openEventModal", "true");
          history.push({
            pathname: "/dashboard",
            state: { eventId },
          });
          break;
        case "New Holiday":
          const dateMatch = notification.notification.match(/\d{2}-\d{2}-\d{4}/);
          if (dateMatch) {
            const date = dateMatch[0];
            sessionStorage.setItem('openHolidayModal', 'true');
            history.push({
              pathname: "/leaves",
              state: { date }
            });
          }
          break;
        case "Resign-Letter":
          history.push("/Relievingletter");
          break;
        case "Birthday":
          history.push("/dashboard");
          break;
        case "Salary-Slip":
          sessionStorage.setItem("openSalaryModal", "true");
          history.push({
            pathname: "/profile",
            state: { tab: "salaryInfo", salarySlipId: notification.data_id },
          });
          break;
        default:
          break;
      }
      handleClose();
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  const handleExpandClick = (type) => {
    setExpandedGroups((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
  };

  const handleSidebarExpandClick = (type) => {
    setSidebarExpandedGroups((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
  };

  const renderNotificationsByType = (type, notificationsData) => {
    if (!notificationsData[type]) return null; 

    return notificationsData[type].map((notification, index) => (
      <Card
        key={notification.id}
        className="notification-card"
        onClick={() => handleNotificationClick(notification)}
        style={{ marginTop: "10px" }}
      >
        <CardContent style={{ padding: "12px" }}>
          <Typography variant="body2" component="p">
            {index + 1}. {notification.notification}
          </Typography>
          <Typography
            variant="caption"
            color="textSecondary"
            className="notification-date"
          >
            {formatDate(notification.updated_at)}
          </Typography>
        </CardContent>
      </Card>
    ));
  };

  const renderNotifications = () => {
    const isReadedGroup = selectedGroup.startsWith("Readed_");
    const groupType = isReadedGroup ? selectedGroup.replace("Readed_", "") : selectedGroup;

    if (selectedGroup === "All Notifications") {
      return (
        <>
          {Object.keys(notifications).map((type) => (
            <div key={type} className="notification-group">
              <div
                className="notification-group-header"
                style={{
                  backgroundColor: "#1b6e95",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "04px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {allIcons[notificationIcons[type]] ? (
                    React.createElement(allIcons[notificationIcons[type]], {
                      style: { ...iconStyle, color: "#fff" },
                    })
                  ) : (
                    <NotificationsIcon style={{ ...iconStyle, color: "#fff" }} />
                  )}
                  <Typography
                    variant="h6"
                    className="notification-group-title"
                    style={{ color: "#fff" }}
                  >
                    {type}
                  </Typography>
                </div>
                <IconButton
                  onClick={() => handleExpandClick(type)}
                  style={{ color: "#fff" }}
                >
                  {expandedGroups[type] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedGroups[type]} timeout="auto" unmountOnExit>
                {renderNotificationsByType(type, notifications)}
              </Collapse>
              <Divider className="notification-divider" />
            </div>
          ))}
          {/* {Object.keys(readNotifications).map((type) => (
            <div key={`Readed_${type}`} className="notification-group">
              <div
                className="notification-group-header"
                style={{
                  backgroundColor: "#1b6e95",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "04px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {allIcons[notificationIcons[type]] ? (
                    React.createElement(allIcons[notificationIcons[type]], {
                      style: { ...iconStyle, color: "#fff" },
                    })
                  ) : (
                    <NotificationsIcon style={{ ...iconStyle, color: "#fff" }} />
                  )}
                  <Typography
                    variant="h6"
                    className="notification-group-title"
                    style={{ color: "#fff" }}
                  >
                    Readed {type}
                  </Typography>
                </div>
                <IconButton
                  onClick={() => handleExpandClick(`Readed_${type}`)}
                  style={{ color: "#fff" }}
                >
                  {expandedGroups[`Readed_${type}`] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedGroups[`Readed_${type}`]} timeout="auto" unmountOnExit>
                {renderNotificationsByType(type, readNotifications)}
              </Collapse>
              <Divider className="notification-divider" />
            </div>
          ))} */}
        </>
      );
    } else if (selectedGroup === "Readed Notifications") {
      return (
        <>
          {Object.keys(readNotifications).map((type) => (
            <div key={`Readed_${type}`} className="notification-group">
              <div
                className="notification-group-header"
                style={{
                  backgroundColor: "#1b6e95",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "04px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {allIcons[notificationIcons[type]] ? (
                    React.createElement(allIcons[notificationIcons[type]], {
                      style: { ...iconStyle, color: "#fff" },
                    })
                  ) : (
                    <NotificationsIcon style={{ ...iconStyle, color: "#fff" }} />
                  )}
                  <Typography
                    variant="h6"
                    className="notification-group-title"
                    style={{ color: "#fff" }}
                  >
                    {type}
                  </Typography>
                </div>
                <IconButton
                  onClick={() => handleExpandClick(`Readed_${type}`)}
                  style={{ color: "#fff" }}
                >
                  {expandedGroups[`Readed_${type}`] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedGroups[`Readed_${type}`]} timeout="auto" unmountOnExit>
                {renderNotificationsByType(type, readNotifications)}
              </Collapse>
              <Divider className="notification-divider" />
            </div>
          ))}
        </>
      );
    } else {
      return (
        <div className="notification-group">
          <div
            className="notification-group-header"
            style={{
              backgroundColor: "#1b6e95",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "04px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {allIcons[notificationIcons[groupType]] ? (
                React.createElement(
                  allIcons[notificationIcons[groupType]],
                  { style: { ...iconStyle, color: "#fff" } }
                )
              ) : (
                <NotificationsIcon style={{ ...iconStyle, color: "#fff" }} />
              )}
              <Typography
                variant="h6"
                className="notification-group-title"
                style={{ color: "#fff" }}
              >
                {isReadedGroup ? ` ${groupType}` : groupType}
              </Typography>
            </div>
            <IconButton
              onClick={() => handleExpandClick(selectedGroup)}
              style={{ color: "#fff" }}
            >
              {expandedGroups[selectedGroup] ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              )}
            </IconButton>
          </div>
          <Collapse
            in={expandedGroups[selectedGroup]}
            timeout="auto"
            unmountOnExit
          >
            {renderNotificationsByType(groupType, isReadedGroup ? readNotifications : notifications)}
          </Collapse>
          <Divider className="notification-divider" />
        </div>
      );
    }
  };

  const getTotalNotificationCount = () => {
    return Object.keys(notifications).reduce(
      (total, type) => total + notifications[type].length,
      0
    );
  };

  const getTotalReadNotificationCount = () => {
    return Object.keys(readNotifications).reduce(
      (total, type) => total + readNotifications[type].length,
      0
    );
  };

  return (
    <div>
      <Header
        {...props}
        authUser={props.authUser}
        setAutUser={props.setAutUser}
        component={
          <>
            <div>
              <Card style={{ marginBottom: "3%" }}>
                <CardContent>
                  <Breadcrumb
                    {...props}
                    primaryPageName="All Notifications"
                    primaryPageLink="/DetailedNotifications"
                    isSecondaryPage={false}
                    secondaryPageName=""
                  />
                </CardContent>
              </Card>
            </div>

            <div className="detailed-notifications-container">
              <div className="sidebar">
                <List component="nav">
                  <ListItem
                    button
                    selected={selectedGroup === "All Notifications"}
                    onClick={() => handleGroupClick("All Notifications")}
                    style={headerStyle}
                  >
                    <div style={{ display: "contents", alignItems: "center" }}>
                      <ListItemIcon>
                        <NotificationsIcon style={{ ...iconStyle, color: "#fff" }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={`All Notifications (${getTotalNotificationCount()})`}
                        style={{ color: "#fff" }}
                      />
                    </div>
                    <IconButton
                      onClick={() => handleSidebarExpandClick("All Notifications")}
                      style={{ color: "#fff" }}
                    >
                      {sidebarExpandedGroups["All Notifications"] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                  </ListItem>
                  <Collapse in={sidebarExpandedGroups["All Notifications"]} timeout="auto" unmountOnExit>
                    {Object.keys(notifications).map((type) => (
                      <ListItem
                        button
                        key={type}
                        selected={selectedGroup === type}
                        onClick={() => handleGroupClick(type)}
                      >
                        <ListItemIcon>
                          {allIcons[notificationIcons[type]] ? (
                            React.createElement(
                              allIcons[notificationIcons[type]],
                              { style: iconStyle }
                            )
                          ) : (
                            <NotificationsIcon style={iconStyle} />
                          )}
                        </ListItemIcon>
                        <ListItemText
                          primary={`${type} (${notifications[type].length})`}
                        />
                      </ListItem>
                    ))}
                  </Collapse>
                  <ListItem
                    button
                    selected={selectedGroup === "Readed Notifications"}
                    onClick={() => handleGroupClick("Readed Notifications")}
                    style={headerStyle}
                  >
                    <div style={{ display: "contents", alignItems: "center" }}>
                      <ListItemIcon>
                        <NotificationsIcon style={{ ...iconStyle, color: "#fff" }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={`Readed Notifications (${getTotalReadNotificationCount()})`}
                        style={{ color: "#fff" }}
                      />
                    </div>
                    <IconButton
                      onClick={() => handleSidebarExpandClick("Readed Notifications")}
                      style={{ color: "#fff" }}
                    >
                      {sidebarExpandedGroups["Readed Notifications"] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                  </ListItem>
                  <Collapse in={sidebarExpandedGroups["Readed Notifications"]} timeout="auto" unmountOnExit>
                    {Object.keys(readNotifications).map((type) => (
                      <ListItem
                        button
                        key={`Readed_${type}`}
                        selected={selectedGroup === `Readed_${type}`}
                        onClick={() => handleGroupClick(`Readed_${type}`)}
                      >
                        <ListItemIcon>
                          {allIcons[notificationIcons[type]] ? (
                            React.createElement(
                              allIcons[notificationIcons[type]],
                              { style: iconStyle }
                            )
                          ) : (
                            <NotificationsIcon style={iconStyle} />
                          )}
                        </ListItemIcon>
                        <ListItemText
                          primary={`${type} (${readNotifications[type].length})`}
                        />
                      </ListItem>
                    ))}
                  </Collapse>
                </List>
              </div>
              <div className="notifications-content">
                {loading ? (
                  <p>Loading...</p>
                ) : Object.keys(notifications).length > 0 ||
                  Object.keys(readNotifications).length > 0 ? (
                  renderNotifications()
                ) : (
                  <p>No notifications available</p>
                )}
              </div>
            </div>
          </>
        }
      />
      <Footer {...props} />
    </div>
  );
};

export default DetailedNotifications;
