import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Header from "../../Layouts/Header";
import Footer from "../../Layouts/Footer";
import AuthApi from "../../Services/Authapi";
import TableComponent from "../../Layouts/TableComponent";
import swal from "sweetalert";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import ls from "local-storage";
import TextField from "@material-ui/core/TextField";
import AuthCheckComponent from '../../Layouts/Token';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';

class Salaryslip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            count: 0,
            pageStatus: false,
            viewPdf: false,
            searchQuery: "",
            suggestions: [],
            tableTh: [
                {
                    id: "name",
                    numeric: false,
                    disablePadding: true,
                    label: "Employee ID",
                    sortable: true,
                },
                {
                    id: "Name",
                    numeric: false,
                    disablePadding: true,
                    label: "Name",
                    sortable: true,
                },
                {
                    id: "BasicSalary",
                    numeric: false,
                    disablePadding: true,
                    label: "Basic Salary",
                    sortable: true,
                },
                {
                    id: "DA",
                    numeric: false,
                    disablePadding: true,
                    label: "DA",
                    sortable: true,
                },
                {
                    id: "HRA",
                    numeric: false,
                    disablePadding: true,
                    label: "HRA",
                    sortable: false,
                },
                {
                    id: "CA",
                    numeric: false,
                    disablePadding: true,
                    label: "CA",
                    sortable: false,
                },
                {
                    id: "Tax",
                    numeric: false,
                    disablePadding: true,
                    label: "Tax",
                    sortable: false,
                },
                {
                    id: "netSalary",
                    numeric: false,
                    disablePadding: true,
                    label: "Net Salary",
                    sortable: false,
                },
                {
                    id: "MonthYear",
                    numeric: false,
                    disablePadding: true,
                    label: "Month Year",
                    sortable: true,
                },
                {
                    id: "actions",
                    numeric: false,
                    disablePadding: true,
                    label: "Actions",
                    sortable: false,
                },
            ],
        };
        this.userData = this.userData.bind(this);
        this.removeUser = this.removeUser.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSuggestionClick = this.handleSuggestionClick.bind(this);
    }

    componentWillMount() {
        if (this.props && this.props.authToken === false) {
            // this.props.history.push("/login");
        }
        this.userData();
    }

    componentWillReceiveProps(props) {
        if (props && props.authToken === false) {
            // props.history.push("/login");
        }
    }

    async userData() {
        try {
            const users = await AuthApi.alldatasalary();
            if (users && users.status === true) {
                this.setState({
                    users: users.data,
                    count: users.data.length,
                });
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    }

    downloadSlip = async (id) => {
        let userId = ls('user').id;

        try {
            swal({
                title: "Salaries Downloading...",
                text: "Please wait...",
                buttons: false,
                closeOnClickOutside: false,
                closeOnEsc: false,
                width: "10%",
                content: {
                    element: "img",
                    attributes: {
                        src: "https://www.boasnotas.com/img/loading2.gif",
                        style: "width: 50px;"
                    }
                }
            });

            const slip = await AuthApi.getAllSalaryPdf(id);
            if (slip && slip.status === true) {
                // Handle the PDF download or other actions here
            }
            swal.close();
        } catch (error) {
            console.error("Error downloading salary slip:", error);
            swal({
                title: "Error",
                text: "Failed to download salary slip. Please try again later.",
                icon: "error",
            });
        }
    }

    viewPdf = async (id) => {
        try {
            const slip = await AuthApi.viewSalarySlip(id);
            if (slip) {
                this.setState({
                    slip: slip,
                    viewPdf: true
                });
            }
        } catch (error) {
            console.error("Error viewing PDF:", error);
        }
    };

    closeModel = () => {
        this.setState({ viewPdf: false });
    }

    async removeUser(id) {
        swal({
            title: "Are you sure?",
            icon: "warning",
            buttons: ["Cancel", "Yes"],
        }).then(async (confirm) => {
            if (confirm) {
                let currentUser = await AuthApi.Salarydelete(id);
                if (currentUser && currentUser.status === true) {
                    this.setState({
                        pageStatus: true,
                    });
                    this.userData();
                    setTimeout(() => this.setState({ pageStatus: false }), 500);
                }
            }
        });
    }

    handleChange(e) {
        const searchQuery = e.target.value;
        const suggestions = this.state.users.filter((item) => {
            return item.Name.toLowerCase().includes(searchQuery.toLowerCase());
        });
        this.setState({ searchQuery, suggestions });
    }

    handleSearch = () => {
        // Trigger search functionality
        const filterUser = this.state.users.filter((item) => {
            return (
                item.Name
                    .toLowerCase()
                    .includes(this.state.searchQuery.toLowerCase())
            );
        });
        this.setState({ filterUser });
    };

    clearSearch = () => {
        this.setState({ searchQuery: "", suggestions: [] }, this.handleSearch);
    };

    handleSuggestionClick = (suggestion) => {
        this.setState({ searchQuery: suggestion.Name, suggestions: [] }, this.handleSearch);
    };

    render() {
        const User = ls("user");
        const classes = this.props;
        const filteredUsers = this.state.filterUser || this.state.users;
        const permissions = (this.props.authUser && Array.isArray(this.props.authUser.userAccess)) ? this.props.authUser.userAccess : [];
        return (
            <>
                <div>
                    <AuthCheckComponent authToken={this.props.authToken} />
                    <Header
                        {...this.props}
                        authUser={this.props.authUser}
                        setAutUser={this.props.setAutUser}
                        component={
                            <div>
                                <TextField
                                    className="hardik"
                                    label="Search"
                                    value={this.state.searchQuery}
                                    onChange={this.handleChange}
                                    style={{
                                        position: "absolute",
                                        right: "52px",
                                        marginTop: "10px"
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: this.state.searchQuery !== "",
                                        style: {
                                            color: '#1b6e95',
                                            transform: this.state.searchQuery !== "" ? 'translate(14px, -6px) scale(0.75)' : 'translate(14px, 11px) scale(1)',
                                            transition: 'transform 0.2s ease-in-out'
                                        }
                                    }}
                                    InputProps={{
                                        style: {
                                            backgroundColor: 'lightgray',
                                            borderRadius: '4px',
                                            height: "40px"
                                        },
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {this.state.searchQuery && (
                                                    <ClearIcon onClick={this.clearSearch} style={{ cursor: 'pointer' }} />
                                                )}
                                                <SearchIcon onClick={this.handleSearch} style={{ cursor: 'pointer' }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                {this.state.searchQuery && this.state.suggestions.length > 0 && (
                                    <Paper style={{ position: 'absolute', right: '120px', marginTop: '53px', zIndex: 1, width: "213px", maxHeight: '200px', overflowY: 'auto', border: '1px solid #ccc', borderRadius: '4px', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)' }}>
                                        {[...new Set(this.state.suggestions.map(suggestion => suggestion.Name))].map((name, index) => (
                                            <MenuItem key={index} onClick={() => this.handleSuggestionClick({ Name: name })} style={{ padding: '10px 20px', cursor: 'pointer' }}>
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Paper>
                                )}

                                <TableComponent
                                    {...this.props}
                                    tableTh={this.state.tableTh}
                                    tableData={filteredUsers}
                                    tableCount={filteredUsers.length}
                                    tablePagestatus={this.state.pageStatus}
                                    colNameToShow={[
                                        "name",
                                        "Name",
                                        "BasicSalary",
                                        "DA",
                                        "HRA",
                                        "CA",
                                        "Tax",
                                        "netSalary",
                                        "MonthYear",
                                    ]}
                                    selectableRows={true}
                                    openPopUp={false}
                                    downloadRow={this.downloadSlip}
                                    showRow={this.viewPdf}
                                    removeRow={this.removeUser}
                                    actionBtns={[permissions.includes("Salaryslip.View") && ('show1'), permissions.includes("Salaryslip.Download") && ('download'), permissions.includes("Salaryslip.Delete") && ('delete')]}
                                    modelName={"Salary Slip"}
                                />
                                {/* {this.state.viewPdf && (
                                    <Dialog
                                        open={this.state.viewPdf}
                                        onClose={this.closeModel}
                                    >
                                        <DialogTitle>Salary Slip</DialogTitle>
                                        <DialogContent>
                                            {this.state.slip && (
                                                <iframe title="Salary Slip" src={this.state.slip} width="800px" height="800px" />
                                            )}
                                        </DialogContent>
                                    </Dialog>
                                )} */}


                                {this.state.viewPdf && (
                                    <Dialog open={this.state.viewPdf} onClose={this.closeModel}  style={{ marginTop: "35px" }}>
                                        <DialogTitle>Salary Slip</DialogTitle>
                                        <DialogContent>
                                            {this.state.slip && (
                                                <iframe title="Salary Slip" src={this.state.slip} width="100%" height="500px" />
                                            )}
                                        </DialogContent>
                                    </Dialog>
                                )}
                            </div>
                        }
                    />
                    <Footer {...this.props} />
                </div>
            </>
        );
    }
}

export default withRouter(Salaryslip);