import React, { Component } from "react";
import { withRouter } from "react-router-dom"; 
import Header from "../../Layouts/Header";
import Footer from "../../Layouts/Footer";
import AuthApi from "../../Services/Authapi";
import TableComponent from "../../Layouts/TableComponent";
import swal from "sweetalert";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import ls from "local-storage";
import AuthCheckComponent from '../../Layouts/Token';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
// import "./Documentslist.css"
import "./Documentslist.css";

class OfferLatter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      DataisLoaded: false,
      users: [],
      count: 0,
      isModalOpen: false,
      apidata: [],
      template: [],
      selectedTemplate: "",
      searchQuery: "",
      suggestions: [],
      showpdfdata: false,
      pageStatus: false,
      tableTh: [
        {
          id: "serial_number",
          numeric: false,
          disablePadding: true,
          label: "Serial No",
          sortable: true,
          cell: (row, index) => index + 1,
        },
        {
          id: "id",
          numeric: false,
          disablePadding: true,
          label: "ID",
          sortable: true,
        },
        {
          id: "candidate_name",
          numeric: false,
          disablePadding: true,
          label: "Candidate Name",
          sortable: true,
        },
        {
          id: "designation",
          numeric: false,
          disablePadding: true,
          label: "Designation",
          sortable: true,
        },
        {
          id: "location",
          numeric: false,
          disablePadding: true,
          label: "Location",
          sortable: true,
        },
        {
          id: "actions",
          numeric: false,
          disablePadding: true,
          label: "Actions",
          sortable: false,
        },
      ],
    };
    this.userData = this.userData.bind(this);
    this.removeUser = this.removeUser.bind(this);
    this.openModal = this.openModal.bind(this);
    this.downloadofferletter = this.downloadofferletter.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleTemplateChange = this.handleTemplateChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.handleSuggestionClick = this.handleSuggestionClick.bind(this);
  }

  componentDidMount() {
    if (this.props && this.props.authToken === false) {
      // this.props.history.push("/login");
    }
    this.userData();
    this.getTemplate();
  }

  componentWillReceiveProps(props) {
    if (props && props.authToken === false) {
      // props.history.push("/login");
    }
  }

  userData = async (e) => {
    let users = await AuthApi.offerletter();
    if (users && users.status === true) {
      this.setState({
        users: users.data,
        count: users.data.length,
      });
    }
  };

  async downloadofferletter(id) {
    let users = await AuthApi.offerletterdo(id);
    if (users && users.status === true) {
      this.setState({
        users: users.data,
        count: users.data.length,
      });
    }
  }

  async getTemplate() {
    try {
      let response = await AuthApi.Templategetdata();
      if (response && response.status === true) {
        this.setState({
          template: response.data,
        });
      } else {
        console.error("Failed to fetch templates: ", response);
      }
    } catch (error) {
      console.error("Error fetching template data:", error);
    }
  }

  async removeUser(id) {
    swal({
      title: "Are you sure?",
      icon: "warning",
      buttons: ["Cancel", "Yes"],
    }).then(async (confirm) => {
      if (confirm) {
        let currentEvent = await AuthApi.offerletterdelete(id);
        if (currentEvent && currentEvent.status === true) {
          this.setState({
            pageStatus: true,
          });
          this.userData();
          setTimeout(() => this.setState({ pageStatus: false }), 500);
        }
      }
    });
  }

  openModal = async (id) => {
    try {
      const user = await AuthApi.offerletterview(id);
      if (user) {
        this.setState({
          user: user,
          isModalOpen: true,
        });
      }
    } catch (error) {
      console.error("Error opening modal:", error);
    }
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  handleChange(e) {
    const searchQuery = e.target.value;
    const suggestions = this.state.users.filter((item) => {
      return item.candidate_name.toLowerCase().includes(searchQuery.toLowerCase());
    });
    this.setState({ searchQuery, suggestions });
  }

  handleTemplateChange(event) {
    this.setState({ selectedTemplate: event.target.value });
  }

  handleSearch = () => {
    // Trigger search functionality
    const filterUser = this.state.users.filter((item) => {
      return (
        item.candidate_name
          .toLowerCase()
          .includes(this.state.searchQuery.toLowerCase())
      );
    });
    this.setState({ filterUser });
  };

  clearSearch = () => {
    this.setState({ searchQuery: "", suggestions: [] }, this.handleSearch);
  };

  handleSuggestionClick = (suggestion) => {
    this.setState({ searchQuery: suggestion.candidate_name, suggestions: [] }, this.handleSearch);
  };

  render() {
    const User = ls("user");
    const {
      users,
      tableTh,
      pageStatus,
      searchQuery,
      isModalOpen,
      user,
    } = this.state;

    const filterUser = this.state.filterUser || this.state.users;
    const permissions = (this.props.authUser && Array.isArray(this.props.authUser.userAccess)) ? this.props.authUser.userAccess : [];

    return (
      <>
        <div>
          <AuthCheckComponent authToken={this.props.authToken} />
          <Header
            {...this.props}
            authUser={this.props.authUser}
            setAutUser={this.props.setAutUser}
            component={
              <div>
                <TextField
                  className="hardik"
                  label="Search"
                  value={this.state.searchQuery}
                  onChange={this.handleChange}
                  style={{
                    position: "absolute",
                    right: "145px",
                    marginTop: "21px"
                  }}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: this.state.searchQuery !== "",
                    style: {
                      color: '#1b6e95',
                      transform: this.state.searchQuery !== "" ? 'translate(14px, -6px) scale(0.75)' : 'translate(14px, 11px) scale(1)', 
                      transition: 'transform 0.2s ease-in-out'
                    }
                  }}
                  InputProps={{
                    style: {
                      backgroundColor: 'lightgray',
                      borderRadius: '4px',
                      height: "40px"
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        {this.state.searchQuery && (
                          <ClearIcon onClick={this.clearSearch} style={{ cursor: 'pointer' }} />
                        )}
                        <SearchIcon onClick={this.handleSearch} style={{ cursor: 'pointer' }} />
                      </InputAdornment>
                    ),
                  }}
                />
                {this.state.searchQuery && this.state.suggestions.length > 0 && (
                  <Paper style={{ position: 'absolute', right: '212px', marginTop: '62px', zIndex: 1, width: "209px", maxHeight: '200px', overflowY: 'auto', border: '1px solid #ccc', borderRadius: '4px', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)' }}>
                    {[...new Set(this.state.suggestions.map(suggestion => suggestion.candidate_name))].map((candidate_name, index) => (
                      <MenuItem key={index} onClick={() => this.handleSuggestionClick({ candidate_name })} style={{ padding: '10px 20px', cursor: 'pointer' }}>
                        {candidate_name}
                      </MenuItem>
                    ))}
                  </Paper>
                )}
                <TableComponent
                  {...this.props}
                  tableTh={tableTh}
                  tableData={filterUser.map((item, index) => ({
                    ...item,
                    serial_number: index + 1,
                  }))}
                  tableCount={filterUser.length}
                  tablePagestatus={pageStatus}
                  colNameToShow={[
                    "serial_number",
                    "id",
                    "candidate_name",
                    "designation",
                    "location",
                  ]}
                  openPopUp={false}
                  removeRow={this.removeUser}
                  downloadRow={(id) => this.downloadofferletter(id)}
                  showRow={(id) => this.openModal(id)}
                  actionBtns={[permissions.includes("Offerletter.View") && ('show'),
                  permissions.includes("Offerletter.Delete") && ('delete'),
                  permissions.includes("Offerletter.Download") && ('download')]}
                  modelName={"Offer Letter"}
                  addRoute={"/Documents/OfferLatterAdd"}
                  updateRoute={"/users/edit"}
                  openPopUpUpdate={false}
                />

                {isModalOpen && (
                  <Dialog open={isModalOpen} onClose={this.closeModal}  style={{ marginTop: "35px" }} >
                    <DialogTitle>Offer Letter</DialogTitle>
                    <DialogContent>
                      {user && (
                        <iframe
                          title="Offer Letter"
                          src={user}
                          width="100%"
                          height="500px"
                        />
                      )}
                    </DialogContent>
                  </Dialog>
                )}
              </div>
            }
          />
          <Footer {...this.props} />
        </div>
      </>
    );
  }
}

export default withRouter(OfferLatter);