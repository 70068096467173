import React, { Component } from 'react';
import Header from '../Layouts/Header'
import Footer from '../Layouts/Footer';
import AuthApi from '../Services/Authapi';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import swal from 'sweetalert';
import TableComponent from '../Layouts/TableComponent';
import Breadcrumb from '../Layouts/Breadcrumb';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import AuthCheckComponent from '../Layouts/Token';
import ClearIcon from "@material-ui/icons/Clear";
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';

class Permission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permission: [],
      count: 0,
      setopen: false,
      permissionName: false,
      searchQuery: "",
      filteredPermissions: [],
      suggestions: [],
      isPopup: false,
      tableTh: [
        { id: 'name', numeric: false, disablePadding: true, label: 'Name', sortable: true },
        { id: 'created_at', numeric: false, disablePadding: true, label: 'Created On', sortable: true },
        { id: 'actions', numeric: false, disablePadding: true, label: 'Actions', sortable: false }
      ]
    }
    this.permissionData = this.permissionData.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.updatChange = this.updatChange.bind(this);
    this.saveData = this.saveData.bind(this);
    this.removePermission = this.removePermission.bind(this);
    this.updatePermission = this.updatePermission.bind(this);
    this.handleClickOpenUpdate = this.handleClickOpenUpdate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearchIconClick = this.handleSearchIconClick.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.handleSuggestionClick = this.handleSuggestionClick.bind(this);
  }

  componentWillMount() {
    if (this.props && this.props.authToken === false) {
      this.props.history.push('/login');
    }
    this.permissionData();
  }

  componentWillReceiveProps(props) {
    if (props && props.authToken === false) {
      // props.history.push('/login');
    }
  }

  permissionData = async (e) => {
    let permission = await AuthApi.getPermission();
    if (permission && permission.status === true) {
      this.setState({
        permission: permission.data,
        count: permission.data.length,
        filteredPermissions: permission.data
      })
    }
  }

  updatePermission = async (id) => {
    let stat = await AuthApi.updatePermission(this.state.permissionName, id);
    if (stat && stat.status === true) {
      this.setState({ setopen: false }, function () {
        this.permissionData();
      });
    }
  }

  handleClickOpen(row = false) {
    this.setState({
      setopen: true,
      isPopup: true,
      permissionName: row.name,
    })
  };

  handleClickOpenUpdate(row = false) {
    this.setState({
      setopen: true,
      isPopup: false,
      permissionName: row.name,
      permissionId: row.id,
    })
  };

  handleClose() {
    this.setState({
      setopen: false
    })
  };

  async saveData() {
    if (this.state.isPopup) {
      let currentPermission = await AuthApi.createPermission(this.state.permissionName);
      if (currentPermission && currentPermission.status === true) {
        this.setState({ setopen: false }, function () {
          this.permissionData();
        });
      } else {
        // axios popup result  
      }
    } else {
      this.updatePermission(this.state.permissionId)
    }
  }

  async removePermission(id) {
    swal({
      title: "Are you sure?",
      icon: "warning",
      buttons: ["Cancel", "Yes"]
    }).then(async (confirm) => {
      if (confirm) {
        let currentPermission = await AuthApi.deletePermission(id);
        if (currentPermission && currentPermission.status === true) {
          this.permissionData();
        } else {
        }
      }
    });
  }

  updatChange(e) {
    this.setState({ permissionName: e.target.value })
  }

  handleChange(e) {
    const searchQuery = e.target.value;
    const suggestions = this.state.permission.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    this.setState({ searchQuery, suggestions });
  }

  handleSearchIconClick() {
    const filterUser = this.state.permission.filter((item) =>
      item.name.toLowerCase().includes(this.state.searchQuery.toLowerCase())
    );
    this.setState({ filteredPermissions: filterUser });
  }

  clearSearch() {
    this.setState({ searchQuery: "", suggestions: [], filteredPermissions: this.state.permission });
  }

  handleSuggestionClick(suggestion) {
    this.setState({ searchQuery: suggestion.name, suggestions: [] }, this.handleSearchIconClick);
  }

  render() {
    const classes = this.props;
    const { filteredPermissions, searchQuery, suggestions } = this.state;
    const permissions = (this.props.authUser && Array.isArray(this.props.authUser.userAccess)) ? this.props.authUser.userAccess : [];
    // console.log(permissions)

    return (
      <div>
        <AuthCheckComponent authToken={this.props.authToken} />
        <Header
          {...this.props}
          authUser={this.props.authUser}
          setAutUser={this.props.setAutUser}
          component={
            <div>
              <Card className={classes.root} style={{ marginBottom: '3%' }}>
                <CardContent>
                  <Breadcrumb
                    {...this.props}
                    primaryPageName="Permissions"
                    primaryPageLink="/permission"
                    isSecondaryPage={false}
                    secondaryPageName="" />
                </CardContent>
              </Card>
              <TextField
                label="Search"
                value={searchQuery}
                onChange={this.handleChange}
                style={{
                  position: "absolute",
                  right: "121px",
                  marginTop: "21px"
                }}
                variant="outlined"
                InputLabelProps={{
                  shrink: searchQuery !== "",
                  style: {
                    color: '#1b6e95',
                    transform: searchQuery !== "" ? 'translate(14px, -6px) scale(0.75)' : 'translate(14px, 11px) scale(1)', // Adjust this line
                    transition: 'transform 0.2s ease-in-out'
                  }
                }}
                InputProps={{
                  style: {
                    backgroundColor: 'lightgray',
                    borderRadius: '4px',
                    height: "40px"
                  },
                  // endAdornment: (
                  //   <InputAdornment position="end">
                  //     <IconButton onClick={this.handleSearchIconClick}>
                  //       <SearchIcon />
                  //     </IconButton>
                  //     {searchQuery && (
                  //       <IconButton onClick={this.clearSearch}>
                  //         <ClearIcon />
                  //       </IconButton>
                  //     )}
                  //   </InputAdornment>
                  // ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {searchQuery&& (
                        <ClearIcon onClick={this.clearSearch} style={{ cursor: 'pointer' }} />
                      )}
                      <SearchIcon onClick={this.handleSearchIconClick} style={{ cursor: 'pointer' }} />
                    </InputAdornment>
                  ),
                }}
              />
              {searchQuery && suggestions.length > 0 && (
                <Paper
                  style={{
                    position: 'absolute',
                    right: '188px',
                    marginTop: '62px',
                    zIndex: 1,
                    width: "211px",
                    maxHeight: '200px',
                    overflowY: 'auto',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)'
                  }}>
                  {[...new Set(suggestions.map(suggestion => suggestion.name))].map((name, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => this.handleSuggestionClick({ name })}
                      style={{
                        padding: '10px 20px',
                        cursor: 'pointer'
                      }}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Paper>
              )}
              <TableComponent
                {...this.props}
                tableTh={this.state.tableTh}
                tableData={filteredPermissions}
                tableCount={filteredPermissions.length}
                colNameToShow={['name', 'created_at']}
                openPopUp={this.handleClickOpen}
                openPopUpUpdate={this.handleClickOpenUpdate}
                removeRow={this.removePermission}
                actionBtns={[permissions.includes("Permission.Edit") && ('update'), permissions.includes("Permission.Delete") && ('delete')]}
                modelName={'Permission'}
              />
            </div>
          } />
        <Footer />
        <PopUp
          isPopup={this.state.isPopup}
          {...this.props}
          handleClose={this.handleClose}
          setopen={this.state.setopen} permissionName={this.state.permissionName} saveData={this.saveData} updatChange={this.updatChange} updatePermission={this.updatePermission} />
      </div>
    )
  }
}
export default Permission;

class PopUp extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let error = this.props.permissionName == undefined;
    return (
      <div>
        <Dialog open={this.props.setopen}>
          <DialogTitle>{(this.props.isPopup) ? "Add Permission" : " Update Permission"}</DialogTitle>
          <DialogContent>
            <TextField autoFocus
              helperText={error ? "Cannot be blank" : "Perfect!"}
              error={error}
              name="name"
              label="Name"
              type="text" value={(this.props.permissionName && this.props.permissionName !== false) ? this.props.permissionName : ""} onChange={(e) => { this.props.updatChange(e) }} />
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={(e) => { this.props.saveData() }} > Save </Button>
            <Button onClick={(e) => { this.props.handleClose() }} color="primary"> Close </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}