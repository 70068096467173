import React, { Component } from "react";

class Footer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const currentYear = new Date().getFullYear();

    return (
      <div className="footer">
        <p>Copyright@{currentYear}. All rights reserved.</p>
      </div>
    );
  }
}

export default Footer;