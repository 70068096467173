import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import AuthApi from "../../../Services/Authapi";
import swal from "sweetalert";
import ls from "local-storage";
import Grid from "@material-ui/core/Grid";
import { Link, useHistory } from "react-router-dom";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string("Enter your password").required("Password is required"),
});

const LoginForm = (props) => {
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let checkLogin = await AuthApi.login(values);
  
      if (checkLogin && checkLogin.access_token) {
        ls.set("authToken", checkLogin.access_token);
        ls.set("roles", checkLogin.user.roles[0].name);
        ls.set("user", checkLogin.user);
        ls.set("isActive", false);
        props.setAutUser({
          authUser: checkLogin.data,
          authToken: checkLogin.access_token,
          roles: checkLogin.user.roles[0].name,
          user: checkLogin.user,
        });
        window.location.reload();
      } else {
        swal("Error", checkLogin || "Login failed", "error");
      }
    },
  });
  
  useEffect(() => {
    if (props.authToken === false) {
      props.history.push("/login");
    }
  }, [props.authToken, props.history]);

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email"
              variant="outlined"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="password"
              name="password"
              variant="outlined"
              label="Password"
              type={showPassword ? "text" : "password"}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              type="submit" 
            >
              Login
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Link to="/forgotpassword" style={{ textDecoration: "none", color: "#1b6e95" }}>
              Forgot Password?
            </Link>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default LoginForm;
