/* eslint-disable no-lone-blocks */
import React from "react";
import { Route, Redirect } from "react-router-dom";
import ls from "local-storage";

const ProtectedRoute = ({ component: Component, path, ...rest}) => {
  const role = ls("roles");
  const User = ls("user");
  const thisPath = path;

  const userpermissions = (User && Array.isArray(User.roles) && User.roles[0] && Array.isArray(User.roles[0].permissions))
    ? User.roles[0].permissions.map(permissionObj => permissionObj.name)
    : [];
  // console.log(User)
  // console.log(userpermissions.includes("dashboard.show"))

  const permissionPathMap = {
    "Dashboard.Timeshow": "/dashboard",
    "Permission.Show": "/permission",
    "Users.Show": "/users",
    "Users.View": "/users/:userId",
    "Role.Show": "/role", 
    "Users.Add": "/users/add",
    "Users.Edit": "/users/edit/:id",
    "Role.Edit": "/Page/edit/:id",
    "Dashboard.Birthday": "/dashboard",
    "Dashboard.Upcomingevent": "/dashboard",
    "Permission.Add": "/permission",
    "Permission.Edit": "/permission",
    "Permission.Delete": "/permission",
    "Role.Add": "/Role/add",
    "Role.Delete": "/role",
    "Users.Delete": "/users",
    "Users.Status": "/users",
    "Holidays.Add": "/leaves/add",
    "Holidays.Show": "/leaves",
    "Holidays.Edit": "/leaves/edit/:id",
    "Holidays.Delete": "/leaves",
    "Upcomingevent.Add": "/upcoming/add",
    "Upcomingevent.Show": "/upcoming",
    "Upcomingevent.Edit": "/upcoming/edit/:id",
    // "Upcomingevent.delete": "/upcoming",
    "Leaveticket.Add": "/tickets/add",
    "Leaveticket.Show": "/tickets",
    "Leaveticket.Edit": "/tickets/edit/:id",
    "Leaveticket.Delete": "/tickets",
    "Leaveticket.Approve": "/tickets",
    "Leaveticket.Declined": "/tickets",
    "Leaveticket.Cancel": "/tickets",
    "Leaveticket.Viewdetails": "/tickets",
    "Job.Add": "/job/add",
    "Job.Show": "/job",
    "Job.Edit": "/job/edit/:id",
    "Job.Delete": "/job",
    "Qualification.Add": "/qulification/add",
    "Qualification.Show": "/qulification",
    "Qualification.Edit": "/qulification/edit/:id",
    "Qualification.Delete": "/qulification",
    "Experience.Add": "/experience/add",
    "Experience.Show": "/qulification",
    "Experience.Edit": "/experience/edit/:id",
    "Experience.Delete": "/experience",
    "Time.Add": "/time",
    "Timeentry.Show": "/check",
    "Timeentry.Approve": "/check",
    "Timeentry.Reject": "/check",
    "Offerletter.Add": "/Documents/OfferLatterAdd",
    "Offerletter.Show": "/Documentslist",
    "Offerletter.View": "/Documentslist",
    "Offerletter.Download": "/Documentslist",
    "Offerletter.Delete": "/Documentslist",
    "Salaryslip.Show": "/Documentslist",
    "Salaryslip.View": "/Documentslist",
    "Salaryslip.Download": "/Documentslist",
    "Salaryslip.Delete": "/Documentslist",
    "Relievingletter.Add": "/Documents/Relievingletteradd",
    "Relievingletter.Show": "/Documentslist",
    "Relievingletter.Approve": "/Documentslist",
    "Relievingletter.Reject": "/Documentslist",
    "Relievingletter.View": "/Documentslist",
    "Relievingletter.Download": "/Documentslist",
    "Relievingletter.Delete": "/Documentslist",
    "Template.Add": "/offer",
    "Template.Show": "/latter",
    "Template.Edit": "/Edittemplate/:id",
    "Template.Delete": "/latter",
    "Template.Status": "/latter",
    "Cron.Show": "/Cron",
    "Cron.Dailycron": "/Cron",
    "Cron.Salarycron": "/Cron",
    "Documentslist.Show": "/Documentslist",
    "Profile.Show": "/profile",
    "Attendance.Show": "/Attendance",
    "Settings.Show": "/Settings",
    "DetailedNotifications.Show": "/DetailedNotifications",

    // "Forgotpassword.Show": "/Forgotpassword",
  };

  return (
    <Route
      {...rest}
      render={(props, id) => {

        const hasPermission = Object.entries(permissionPathMap).some(
          ([permission, path]) => userpermissions.includes(permission) && thisPath === path
        );

        if (hasPermission) {
          return <Component {...rest}  {...props} />;
        } else {
          return (
            <Redirect
              to={{
                path: "/login",
                location: props.location,
              }}
            />
          );
        }


        // if (role === "Super Admin") {

        //   if (

        //     thisPath === "/permission" ||
        //     thisPath === "/role" ||
        //     thisPath === "/users" ||
        //     thisPath === "/users/edit/:id" ||
        //     thisPath === "/Page/edit/:id" ||
        //     thisPath === "/users/add" ||
        //     thisPath === "/leaves" ||
        //     thisPath === "/leaves/add" ||
        //     thisPath === "/leaves/edit/:id" ||
        //     thisPath === "/tickets" ||
        //     thisPath === "/tickets/add" ||
        //     thisPath === "/tickets/edit/:id" ||
        //     thisPath === "/profile" ||
        //     thisPath === "/event" ||
        //     thisPath === "/event/add" ||
        //     thisPath === "/event/edit/:id" ||
        //     thisPath === "/upcoming" ||
        //     thisPath === "/upcoming/add" ||
        //     thisPath === "/upcoming/edit/:id" ||
        //     thisPath === "/job" ||
        //     thisPath === "/job/add" ||
        //     thisPath === "/job/edit/:id" ||
        //     thisPath === "/qulification" ||
        //     thisPath === "/qulification/add" ||
        //     thisPath === "/qulification/edit/:id" ||
        //     thisPath === "/experience" ||
        //     thisPath === "/experience/add" ||
        //     thisPath === "/experience/edit/:id" ||
        //     thisPath === "/time" ||
        //     thisPath === "/check" ||
        //     thisPath === "/offer" ||
        //     thisPath === "/latter" ||
        //     thisPath === "/Edittemplate/:id" ||
        //     thisPath === "/OfferLatter" ||
        //     thisPath === "/Salaryslip" ||
        //     thisPath === "/Documentslist" ||
        //     thisPath === "/Relievingletter" ||
        //     thisPath === "/message" ||
        //     thisPath === "/timer" ||
        //     // thisPath === "/Documents/OfferLatterAdd" ||
        //     // thisPath === "/Documents/Relievingletteradd" ||
        //     thisPath === "/testing" ||
        //     thisPath === "/Cron"
        //   ) {
        //     return <Component {...rest} {...props} />;
        //   } else {
        //     return (
        //       <Redirect
        //         to={{
        //           path: "/login",
        //           location: props.location,
        //         }}
        //       />
        //     );
        //   }
        // } else if (role === "Hr") {
        //   if (
        //     thisPath === "/tickets" ||
        //     thisPath === "/tickets/add" ||
        //     thisPath === "/tickets/edit/:id" ||
        //     thisPath === "/profile" ||
        //     thisPath === "/profile/edit" ||
        //     thisPath === "/event" ||
        //     thisPath === "/event/add" ||
        //     thisPath === "/event/edit/:id" ||
        //     thisPath === "/show/:id" ||
        //     thisPath === "/upcoming" ||
        //     thisPath === "/upcoming/add" ||
        //     thisPath === "/upcoming/edit/:id" ||
        //     thisPath === "/job" ||
        //     thisPath === "/job/add" ||
        //     thisPath === "/job/edit/:id" ||
        //     thisPath === "/qulification" ||
        //     thisPath === "/qulification/add" ||
        //     thisPath === "/qulification/edit/:id" ||
        //     thisPath === "/experience" ||
        //     thisPath === "/experience/add" ||
        //     thisPath === "/experience/edit/:id" ||
        //     thisPath === "/time"
        //   ) {
        //     return <Component {...rest} {...props} />;
        //   } else {
        //     return (
        //       <Redirect
        //         to={{
        //           path: "/login",
        //           location: props.location,
        //         }}
        //       />
        //     );
        //   }
        // } else if (role === "Admin") {
        //   if (
        //     thisPath === "/permission" ||
        //     thisPath === "/role" ||
        //     thisPath === "/users" ||
        //     thisPath === "/users/edit/:id" ||
        //     thisPath === "/users/add" ||
        //     thisPath === "/leaves" ||
        //     thisPath === "/leaves/add" ||
        //     thisPath === "/leaves/edit/:id" ||
        //     thisPath === "/tickets" ||
        //     thisPath === "/tickets/add" ||
        //     thisPath === "/tickets/edit/:id" ||
        //     thisPath === "/profile" ||
        //     thisPath === "/event" ||
        //     thisPath === "/event/add" ||
        //     thisPath === "/event/edit/:id" ||
        //     thisPath === "/show/:id" ||
        //     thisPath === "/upcoming" ||
        //     thisPath === "/upcoming/add" ||
        //     thisPath === "/upcoming/edit/:id" ||
        //     thisPath === "/job" ||
        //     thisPath === "/job/add" ||
        //     thisPath === "/job/edit/:id" ||
        //     thisPath === "/qulification" ||
        //     thisPath === "/qulification/add" ||
        //     thisPath === "/qulification/edit/:id" ||
        //     thisPath === "/experience" ||
        //     thisPath === "/experience/add" ||
        //     thisPath === "/experience/edit/:id" ||
        //     thisPath === "/time" ||
        //     thisPath === "/check" ||
        //     thisPath === "/offer" ||
        //     thisPath === "/latter" ||
        //     thisPath === "/OfferLatter" ||
        //     thisPath === "/message"
        //   ) {
        //     return <Component {...rest} {...props} />;
        //   } else {
        //     return (
        //       <Redirect
        //         to={{
        //           path: "/login",
        //           location: props.location,
        //         }}
        //       />
        //     );
        //   }
        // }
        // else if (role === "karmur") {
        //   if (
        //     thisPath === "/permission" ||
        //     thisPath === "/role" ||
        //     thisPath === "/users" ||
        //     thisPath === "/users/edit/:id" ||
        //     thisPath === "/users/add" ||
        //     thisPath === "/leaves" ||
        //     thisPath === "/leaves/add" ||
        //     thisPath === "/leaves/edit/:id" ||
        //     thisPath === "/tickets" ||
        //     thisPath === "/tickets/add" ||
        //     thisPath === "/tickets/edit/:id" ||
        //     thisPath === "/profile" ||
        //     thisPath === "/event" ||
        //     thisPath === "/event/add" ||
        //     thisPath === "/event/edit/:id" ||
        //     thisPath === "/show/:id" ||
        //     thisPath === "/upcoming" ||
        //     thisPath === "/upcoming/add" ||
        //     thisPath === "/upcoming/edit/:id" ||
        //     thisPath === "/job" ||
        //     thisPath === "/job/add" ||
        //     thisPath === "/job/edit/:id" ||
        //     thisPath === "/qulification" ||
        //     thisPath === "/qulification/add" ||
        //     thisPath === "/qulification/edit/:id" ||
        //     thisPath === "/experience" ||
        //     thisPath === "/experience/add" ||
        //     thisPath === "/experience/edit/:id" ||
        //     thisPath === "/time" ||
        //     thisPath === "/check" ||
        //     thisPath === "/offer" ||
        //     thisPath === "/latter" ||
        //     thisPath === "/OfferLatter" ||
        //     thisPath === "/message"
        //   ) {
        //     return <Component {...rest} {...props} />;
        //   } else {
        //     return (
        //       <Redirect
        //         to={{
        //           path: "/login",
        //           location: props.location,
        //         }}
        //       />
        //     );
        //   }
        // } else if (role === "Administrator") {
        //   if (
        //     thisPath === "/permission" ||
        //     thisPath === "/role" ||
        //     thisPath === "/users" ||
        //     thisPath === "/users/edit/:id" ||
        //     thisPath === "/users/add" ||
        //     thisPath === "/leaves" ||
        //     thisPath === "/leaves/add" ||
        //     thisPath === "/leaves/edit/:id" ||
        //     thisPath === "/tickets" ||
        //     thisPath === "/tickets/add" ||
        //     thisPath === "/tickets/edit/:id" ||
        //     thisPath === "/profile" ||
        //     thisPath === "/event" ||
        //     thisPath === "/event/add" ||
        //     thisPath === "/event/edit/:id" ||
        //     thisPath === "/show/:id" ||
        //     thisPath === "/upcoming" ||
        //     thisPath === "/upcoming/add" ||
        //     thisPath === "/upcoming/edit/:id" ||
        //     thisPath === "/job" ||
        //     thisPath === "/job/add" ||
        //     thisPath === "/job/edit/:id" ||
        //     thisPath === "/qulification" ||
        //     thisPath === "/qulification/add" ||
        //     thisPath === "/qulification/edit/:id" ||
        //     thisPath === "/experience" ||
        //     thisPath === "/experience/add" ||
        //     thisPath === "/experience/edit/:id" ||
        //     thisPath === "/time"
        //   ) {
        //     return <Component {...rest} {...props} />;
        //   } else {
        //     return (
        //       <Redirect
        //         to={{
        //           path: "/login",
        //           location: props.location,
        //         }}
        //       />
        //     );
        //   }
        // } else if (role === "Employee") {
        //   if (
        //     thisPath === "/tickets" ||
        //     thisPath === "/tickets/add" ||
        //     thisPath === "/tickets/edit/:id" ||
        //     thisPath === "/profile" ||
        //     thisPath === "/profile/edit" ||
        //     thisPath === "/event" ||
        //     thisPath === "/event/add" ||
        //     thisPath === "/event/edit/:id" ||
        //     thisPath === "/show/:id" ||
        //     thisPath === "/upcoming" ||
        //     // thisPath === "/upcoming/add" ||
        //     thisPath === "/upcoming/edit/:id" ||
        //     thisPath === "/job" ||
        //     thisPath === "/job/add" ||
        //     thisPath === "/leaves" ||
        //     thisPath === "/job/edit/:id" ||
        //     thisPath === "/qulification" ||
        //     thisPath === "/qulification/add" ||
        //     thisPath === "/qulification/edit/:id" ||
        //     thisPath === "/experience" ||
        //     thisPath === "/experience/add" ||
        //     thisPath === "/experience/edit/:id" ||
        //     thisPath === "/time"
        //   ) {
        //     return <Component {...rest} {...props} />;
        //   } else {
        //     return (
        //       <Redirect
        //         to={{
        //           path: "/login",
        //           location: props.location,
        //         }}
        //       />
        //     );
        //   }
        // } else {
        //   return (
        //     <Redirect
        //       to={{
        //         path: "/login",
        //         location: props.location,
        //       }}
        //     />
        //   );
        // }
      }}
    />

  );
};
export default ProtectedRoute;
