import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import AuthApi from "../../../Services/Authapi";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import "./Forgotpassword.css";
import Typography from "@material-ui/core/Typography";
import Logo from "../../../../Images/logo.png";
import { Link } from "react-router-dom";

const validationSchema = yup.object({
    email: yup
        .string()
        .email("Enter a valid email")
        .required("Email is required"),
    otp: yup
        .string()
        .when('otpVisible', {
            is: true,
            then: yup.string().required("OTP is required")
        }),
    password: yup
        .string()
        .when('passwordVisible', {
            is: true,
            then: yup.string().min(8, "Password should be of minimum 8 characters length").required("Password is required")
        }),
    password_confirmation: yup
        .string()
        .when('passwordVisible', {
            is: true,
            then: yup.string().min(8, "Password should be of minimum 8 characters length").required("Password is required")
        }),
});

const Forgotpassword = (props) => {
    const history = useHistory();
    const [otpVisible, setOtpVisible] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleClickShowPassword1 = () => {
        setShowPassword1(!showPassword1);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleMouseDownPassword1 = (event) => {
        event.preventDefault();
    };

    const formik = useFormik({
        initialValues: {
            email: "",
            otp: "",
            password: "",
            password_confirmation: "",
            otpVisible: false,
            passwordVisible: false,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (!otpVisible) {
                swal({
                    title: "Loading",
                    text: "Please wait...",
                    buttons: false,
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                    width: "10%",
                    content: {
                        element: "img",
                        attributes: {
                            src: "https://www.boasnotas.com/img/loading2.gif",
                            style: "width: 50px;"
                        }
                    }
                });

                const response = await AuthApi.gmailotp({ email: values.email });
                if (response.status === true) {
                    swal("Success", "OTP sent to your email!", "success");
                    setOtpVisible(true);
                    formik.setFieldValue('otpVisible', true);
                } else {
                    swal("Error", response.message, "error");
                }

            } else if (!passwordVisible) {

                const response = await AuthApi.verifyotp({ email: values.email, otp: values.otp });
                if (response.status === true) {
                    swal("Success", "OTP verified successfully!", "success");
                    setPasswordVisible(true);
                    formik.setFieldValue('passwordVisible', true);
                } else {
                    swal("Error", response.message || "Invalid OTP. Please try again.", "error");
                }

            } else {

                const response = await AuthApi.resetpassword({ email: values.email, otp: values.otp, password: values.password, password_confirmation: values.password_confirmation });
                if (response.status === true) {
                    swal("Success", "Password reset successfully!", "success");
                    history.push("/login");
                } else {
                    swal("Error", response.message || "Failed to reset password. Please try again.", "error");
                }

            }
        },
    });

    return (
        <main className="main">
            <Paper className="paper" elevation={3}>
                <img style={{ height: "45px" }} src={Logo} alt="ds" />
                <Typography component="h1" variant="h2">
                    Cherrypik Software Inc
                </Typography>
                <Typography style={{ color: "#1B6E95", height: "38px" }}>
                    Employee Management System
                </Typography>
                <div>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2}>
                            {passwordVisible ? ("") : (
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="email"
                                        name="email"
                                        label="Email"
                                        variant="outlined"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                    />
                                </Grid>
                            )}
                            {passwordVisible ? ("") : (
                                otpVisible && (
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="otp"
                                            name="otp"
                                            label="OTP"
                                            variant="outlined"
                                            value={formik.values.otp}
                                            onChange={formik.handleChange}
                                            error={formik.touched.otp && Boolean(formik.errors.otp)}
                                            helperText={formik.touched.otp && formik.errors.otp}
                                        />
                                    </Grid>
                                )
                            )
                            }
                            {passwordVisible && (
                                <>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="password"
                                            name="password"
                                            label="New Password"
                                            type={showPassword ? "text" : "password"}
                                            variant="outlined"
                                            value={formik.values.password}
                                            onChange={formik.handleChange}
                                            error={formik.touched.password && Boolean(formik.errors.password)}
                                            helperText={formik.touched.password && formik.errors.password}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                        >
                                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="password_confirmation"
                                            name="password_confirmation"
                                            label="Confirm Password"
                                            type={showPassword1 ? "text" : "password"}
                                            variant="outlined"
                                            value={formik.values.password_confirmation}
                                            onChange={formik.handleChange}
                                            error={formik.touched.password_confirmation && Boolean(formik.errors.password_confirmation)}
                                            helperText={formik.touched.password_confirmation && formik.errors.password_confirmation}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword1}
                                                            onMouseDown={handleMouseDownPassword1}
                                                        >
                                                            {showPassword1 ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    fullWidth
                                    type="submit"
                                >
                                    {passwordVisible ? "Reset Password" : otpVisible ? "Verify OTP" : "Send OTP"}
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{marginTop: "17px"}}>
                            <Link to="/login" style={{ textDecoration: "none", color: "#1b6e95" }}>
                               Login
                            </Link>
                        </Grid>
                    </form>
                </div>
            </Paper>
        </main>
    );
};

export default Forgotpassword;
