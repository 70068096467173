import React, { Component } from "react";
import * as workerTimers from "worker-timers";
import "./timer.css";
import Authapi from "../../Services/Authapi";
import ls from "local-storage";
import swal from 'sweetalert';

class Timer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timer: 0,
      isActive: false,
      isPaused: false,
      startTime: 0,
      stopTime: 0,
      isLoading: false,
      isInitialLoading: true,
    };
    this.countRef = React.createRef(null);
  }

  async componentDidMount() {
    try {
      let timeCount = await Authapi.durTime(this.props.user.id);
      const totalDurationInSec = parseInt(timeCount.totalDurationInSec, 10);

      if (totalDurationInSec > 0) {
        this.setState({ timer: totalDurationInSec, isActive: true, isInitialLoading: false }, this.runCycle);
      } else {
        this.setState({ isInitialLoading: false });
      }
    } catch (error) {
      console.error("Error fetching duration time:", error);
      this.setState({ isInitialLoading: false });
    }

    window.addEventListener("beforeunload", this.handleComponentUnmount.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleComponentUnmount.bind(this));
    workerTimers.clearInterval(this.countRef.current);
  }

  handleComponentUnmount() {
    if (this.state.isActive) {
      Authapi.saveTime(this.state.timer);  
    }
  }

  runCycle() {
    this.countRef.current = workerTimers.setInterval(() => {
      this.setState((prevState) => ({ timer: prevState.timer + 1 }));
    }, 1000);
  }

  // handleStart = async () => {
  //   this.setState({ isLoading: true }); 
  //   try {
  //     await Authapi.inTime();
  //     this.setState({ isActive: true, startTime: Date.now(), isLoading: false }, this.runCycle); 
  //   } catch (error) {
  //     this.setState({ isLoading: false });
  //     swal("Error", error.response?.data?.message || "An error occurred while clocking in.", "error");
  //   }
  // };
  
  handleStart = async () => {
    // console.log(this.props.authUser.userAccess)
    this.setState({ isLoading: true }); 
    try {
      await Authapi.inTime();
      this.setState({ isActive: true, startTime: Date.now(), isLoading: false }, this.runCycle); 
    } catch (error) {
      this.setState({ isLoading: false });
      swal({
        title: "Error",
        text: error.response?.data?.message || "An error occurred while clocking in.",
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            value: true,
            visible: true,
            className: "gray-button",
            closeModal: true
          },
          add: {
            text: "Add Leave",
            value: "add",
            visible: true,
            className: "",
            closeModal: true
          }
        }
      }).then((value) => {
        if (value === "add") {
          window.location.href = "/tickets/Add";
          // this.props.authUser.userAccess("/tickets/Add")
        }
      });
    }
  };

  handlePause = async () => {
    this.setState({ isLoading: true }); 
    await Authapi.outTime();
    this.setState({ isActive: false, stopTime: Date.now(), isLoading: false }, () => {
      workerTimers.clearInterval(this.countRef.current);
      window.location.reload();
    });
  };
   
  formatTime = () => {
    const { timer } = this.state;
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = Math.floor(timer / 60);
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

    return `${getHours} : ${getMinutes} : ${getSeconds}`;
  };

  render() {
    if (this.state.isInitialLoading) {
      return <div className="stopwatch-card"><p className="timerText">Loading...</p></div>;
    }

   
    return (
      <div className="stopwatch-card">
        <p className="timerText">{this.formatTime()}</p>
        <div className="buttons">
          {!this.state.isActive && (
            <button
              onClick={this.handleStart}
              className="inbutton"
              disabled={this.state.isLoading}
            >
              {this.state.isLoading && <span className="spinner"></span>}
              {!this.state.isLoading && "IN"} 
            </button>
          )}
          {this.state.isActive && (
            <button
              onClick={this.handlePause}
              className="inbutton"
              disabled={this.state.isLoading}
            >
              {this.state.isLoading && <span className="spinner"></span>}
              {!this.state.isLoading && "OUT"} 
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default Timer;