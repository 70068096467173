import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../Layouts/Header';
import Footer from '../Layouts/Footer';
import Breadcrumb from '../Layouts/Breadcrumb';
import AuthApi from '../Services/Authapi';
import swal from 'sweetalert';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AuthCheckComponent from '../Layouts/Token';
// import swal from "sweetalert";
import {
  Card, CardContent, Grid, Typography, TextField,
  Button, Checkbox, FormControlLabel, makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  treeItem: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const Roleadd = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [editValues, setEditValues] = useState({ roleName: '', permissions: [] });
  const [permissions, setPermissions] = useState({});

  useEffect(() => {
    getPermissions();
  }, []);

  const getPermissions = async () => {
    try {
      const permissionResponse = await AuthApi.getpermissions();
      if (permissionResponse.status) {
        setPermissions(permissionResponse.permissions);
      }
    } catch (error) {
      console.error('Error fetching permissions:', error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    swal({
      title: "Loading",
      text: "Please wait...",
      buttons: false,
      closeOnClickOutside: false,
      closeOnEsc: false,
      width: "10%",
      content: {
        element: "img",
        attributes: {
          src: "https://www.boasnotas.com/img/loading2.gif",
          style: "width: 50px;" // Adjust the width as needed
        }
      }
    });
    try {
      const currentRole = await AuthApi.createRole(editValues.roleName, editValues.permissions);
      swal.close();
      if (currentRole && currentRole.status === true) {
        swal("Success", "Role created successfully!", "success");
        history.push('/role');
      } else {
        swal("Error", "Failed to create role.", "error");
      }
    } catch (error) {
      console.error('Error creating role:', error);
      swal("Error", "An error occurred while creating the role.", "error");
    }
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const permissionId = parseInt(name, 10);
    setEditValues((prevState) => {
      const newPermissions = checked
        ? [...prevState.permissions, permissionId]
        : prevState.permissions.filter((permission) => permission !== permissionId);
      return { ...prevState, permissions: newPermissions };
    });
  };

  const handleParentCheckboxChange = (children, checked) => {
    const childIds = children.map((child) => child.id);
    setEditValues((prevState) => {
      const newPermissions = checked
        ? [...new Set([...prevState.permissions, ...childIds])]
        : prevState.permissions.filter((permission) => !childIds.includes(permission));
      return { ...prevState, permissions: newPermissions };
    });
  };

  const isParentChecked = (children) => {
    return children.every((child) => editValues.permissions.includes(child.id));
  };

  return (
    <div>
        <AuthCheckComponent authToken={props.authToken} />
      <Header
        {...props}
        authUser={props.authUser}
        component={
          <div>
            <Card className={classes.root}>
              <CardContent>
                <Breadcrumb
                  {...props}
                  primaryPageName="Role"
                  primaryPageLink="/role"
                  isSecondaryPage={true}
                  secondaryPageName="Add Role"
                />
              </CardContent>
            </Card>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">Add Role</Typography>
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={3} style={{ marginTop: '10px' }}>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            id="roleName"
                            name="roleName"
                            label="Role Name"
                            variant="outlined"
                            value={editValues.roleName}
                            onChange={(e) => setEditValues({ ...editValues, roleName: e.target.value })}
                          />
                        </Grid>
                        <Grid
                          container
                          style={{
                            columnCount: 3,
                            columnGap: '20px',
                            display: 'block',
                            marginTop: '35px',
                          }}
                        >
                          {Object.entries(permissions).map(([parent, children], parentIndex) => (
                            <TreeView
                              key={parentIndex}
                              defaultCollapseIcon={<ExpandMoreIcon />}
                              defaultExpandIcon={<ChevronRightIcon />}
                            >
                              <TreeItem
                                nodeId={parent}
                                label={
                                  <FormControlLabel
                                    className={classes.treeItem}
                                    control={
                                      <Checkbox
                                        checked={isParentChecked(children)}
                                        onChange={(e) => handleParentCheckboxChange(children, e.target.checked)}
                                        color="primary"
                                      />
                                    }
                                    label={parent}
                                  />
                                }
                              >
                                {children.map((permission, childIndex) => (
                                  <div key={childIndex} className={classes.treeItem}
                                    style={{ marginLeft: "24px", marginTop: "-11px" }}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={editValues.permissions.includes(permission.id)}
                                          onChange={handleCheckboxChange}
                                          name={permission.id.toString()}
                                          color="primary"
                                        />
                                      }
                                      label={permission.value}
                                    />
                                  </div>
                                ))}
                              </TreeItem>
                            </TreeView>
                          ))}
                        </Grid>
                      </Grid>
                      <Grid container spacing={3} 
                      style={{marginTop:"23px"}}
                      >
                        <Grid item>
                          <Button type="submit" variant="contained" color="primary">Save</Button>
                          <Button type="button" onClick={() => history.push('/role')} style={{ marginLeft: '10px' }}>
                            Cancel
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        }
      />
      <Footer />
    </div>
  );
};

export default Roleadd;
