import React, { Component } from 'react';
import Header from '../../Layouts/Header';
import Footer from '../../Layouts/Footer';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Breadcrumb from '../../Layouts/Breadcrumb';
import Button from '@material-ui/core/Button';
import AuthApi from '../../Services/Authapi';
import swal from 'sweetalert';
import Tooltip from "@material-ui/core/Tooltip";
import TableComponent from "../../Layouts/TableComponent";
import ls from "local-storage";
import AuthCheckComponent from '../../Layouts/Token';

class Cron extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            pageStatus: false,
            users: [],
            count: 0,
            
            tableTh: [
                {
                    id: "id",
                    numeric: false,
                    disablePadding: true,
                    label: "Id",
                    sortable: true,
                },
                {
                    id: "ip_address",
                    numeric: false,
                    disablePadding: true,
                    label: "Ip Address",
                    sortable: true,
                },
                {
                    id: "crone_name",
                    numeric: false,
                    disablePadding: true,
                    label: "Cron Name",
                    sortable: true,
                },
                {
                    id: "crone_status",
                    numeric: false,
                    disablePadding: true,
                    label: "Cron Status",
                    sortable: true,
                },
                {
                    id: "created_at",
                    numeric: false,
                    disablePadding: true,
                    label: "Created At",
                    sortable: true,
                },
                {
                    // id: "actions",
                    numeric: false,
                    disablePadding: true,
                    label: " ",
                    sortable: false,
                },
            ],
        };
        this.userData = this.userData.bind(this);
        this.addSalary1 = this.addSalary1.bind(this);
        this.Dailycron = this.Dailycron.bind(this);
        this.removeUser = this.removeUser.bind(this);
    }

    componentWillMount() {
        if (this.props && this.props.authToken === false) {
            // this.props.history.push("/login");
        }
        this.userData();
    }

    async userData() {
        try {
            const users = await AuthApi.cronlogs();
            if (users && users.status === true) {
                this.setState({
                    users: users.data,
                    count: users.data.length,
                });
                // console.log(users)
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    }

    addSalary1() {
        const { history } = this.props;
        swal({
            title: "Are you sure?",
            icon: "warning",
            buttons: ["Cancel", "Yes"]
        }).then(async (confirm) => {
            if (confirm) {
                swal({
                    title: "Salaries Generating...",
                    text: "Please wait...",
                    buttons: false,
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                    width: "10%",
                    content: {
                        element: "img",
                        attributes: {
                            src: "https://www.boasnotas.com/img/loading2.gif",
                            style: "width: 50px;"
                        }
                    }
                });

                this.setState({ loading: true });

                try {
                    let role = await AuthApi.Addsalaryall();
                    console.log(role.status);
                    this.setState({ loading: false });
                    // if (role && role.status === true) {
                    //     history.push("/Cron");
                    // }
                    // swal("Success", "Salaries Generated successfully", "success");
                    // swal.close();
                    if (role && role.status === true) {
                        swal("Success", "Salaries Generated successfully", "success");
                        history.push("/Cron");
                    } else {
                        swal("Error", role, "error"); // Display Laravel response message
                    }

                } catch (error) {
                    console.error('Error occurred:', error);
                    this.setState({ loading: false });
                    swal.close();
                    swal("Error", "Failed To Generate Salaries", "error");
                }
            }
        });
    };

    // Dailycron api call
    Dailycron() {
        const { history } = this.props;
        swal({
            title: "Are you sure?",
            icon: "warning",
            buttons: ["Cancel", "Yes"]
        }).then(async (confirm) => {
            if (confirm) {
                swal({
                    title: "Working Hours Calculating...",
                    text: "Please wait...",
                    buttons: false,
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                    width: "10%",
                    content: {
                        element: "img",
                        attributes: {
                            src: "https://www.boasnotas.com/img/loading2.gif",
                            style: "width: 50px;"
                        }
                    }
                });

                this.setState({ loading: true });

                try {
                    let role = await AuthApi.calculateworking();
                    console.log(role.status);
                    this.setState({ loading: false });
                    if (role && role.status === true) {
                        history.push("/Cron");
                    }
                    swal("Success", "Working Hours Calculated successfully", "success");
                    swal.close();
                } catch (error) {
                    console.error('Error occurred:', error);
                    this.setState({ loading: false });
                    swal.close();
                    swal("Error", "Failed To Calculate Working Hours", "error");

                }
            }
        });
    }

    async removeUser(id) {
        swal({
            title: "Are you sure?",
            icon: "warning",
            buttons: ["Cancel", "Yes"],
        }).then(async (confirm) => {
            if (confirm) {
                let currentUser = await AuthApi.cronlogsdelete(id);
                if (currentUser && currentUser.status === true) {
                    this.setState({
                        pageStatus: true,
                    });
                    this.userData();
                    // setTimeout(() => this.setState({ pageStatus: false }), 500);
                } else {
                }
            }
        });
    }



    render() {
        const User = ls("user");
        const classes = this.props;
        const { loading } = this.state;
        const userpermissions = (User && Array.isArray(User.roles) && User.roles[0] && Array.isArray(User.roles[0].permissions))
            ? User.roles[0].permissions.map(permissionObj => permissionObj.name)
            : [];
        // console.log(userpermissions)

        return (
            <div>
                <AuthCheckComponent authToken={this.props.authToken} />
                <Header
                    {...this.props}
                    authUser={this.props.authUser}
                    component={
                        <div>
                            <Card className={classes.root} style={{ marginBottom: '3%' }}>
                                <CardContent>
                                    <Breadcrumb
                                        {...this.props}
                                        primaryPageName="Cron"
                                        primaryPageLink="/Cron"
                                        isSecondaryPage={false}
                                        secondaryPageName="" />
                                </CardContent>
                            </Card>

                            <Grid container className={classes.root} spacing={2}>
                                {
                                    userpermissions.includes("Cron.Dailycron") && (
                                        <Grid item xs={6}>
                                            <Tooltip title="Calculate Working Hours">
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={this.Dailycron}
                                                    disabled={loading}
                                                >
                                                    Daily cron
                                                </Button>
                                            </Tooltip>
                                        </Grid>
                                    )
                                }



                                {
                                    userpermissions.includes("Cron.Salarycron") && (
                                        <Grid item xs={6}>
                                            <Tooltip title="Generate Salaries">
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={this.addSalary1}
                                                    disabled={loading}
                                                >
                                                    Salaries Cron
                                                </Button>
                                            </Tooltip>
                                        </Grid>
                                    )
                                }


                            </Grid>
                            <TableComponent
                                {...this.props}
                                tableTh={this.state.tableTh}
                                tableData={this.state.users}
                                tableCount={this.state.count}
                                tablePagestatus={this.state.pageStatus}
                                colNameToShow={[
                                    "id",
                                    "ip_address",
                                    "crone_name",
                                    "crone_status",
                                    "created_at"
                                ]}
                                selectableRows={true}
                                openPopUp={false}
                                // downloadRow={this.downloadSlip}
                                // showRow={this.viewPdf}
                                // removeRow={this.removeUser}
                                // actionBtns={[
                                //     userpermissions.includes("Cron.Delete") && ('delete')
                                // ]}
                                modelName={"Cron Logs"}
                                // addRoute={'/users/add'}
                                // updateRoute={'/users/edit'}
                                openPopUpUpdate={false}
                            />
                        </div>
                    } />
                <Footer />
            </div>
        );
    }
}

export default Cron;
