/* eslint-disable no-dupe-class-members */
import React, { Component } from "react";
import Header from "../Layouts/Header";
import Footer from "../Layouts/Footer";
import Container from "@material-ui/core/Container";
import AuthApi from "../Services/Authapi";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import swal from "sweetalert";
import TableComponent from "../Layouts/TableComponent";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import Breadcrumb from "../Layouts/Breadcrumb";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import DropdownComponent from "../Layouts/DropdownComponent";
import PageLoader from "../Layouts/PageLoader";
import AuthCheckComponent from "../Layouts/Token";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';

class Role extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: [],
      count: 0,
      pageStatus: false,
      setopen: false,
      roleName: false,
      permission: false,
      isPopup: false,
      searchQuery: "",
      checkbox: [],
      suggestions: [],
      tableTh: [
        {
          id: "name",
          numeric: false,
          disablePadding: true,
          label: "Name",
          sortable: true,
        },
        {
          id: "created_at",
          numeric: false,
          disablePadding: true,
          label: "Created On",
          sortable: true,
        },
        {
          id: "actions",
          numeric: false,
          disablePadding: true,
          label: "Actions",
          sortable: false,
        },
      ],
    };
    this.roleData = this.roleData.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.updatChange = this.updatChange.bind(this);
    this.saveData = this.saveData.bind(this);
    this.removeRole = this.removeRole.bind(this);
    this.updateCheckbox = this.updateCheckbox.bind(this);
    this.handleClickOpenUpdateRole = this.handleClickOpenUpdateRole.bind(this);
    this.updateRoleee = this.updateRoleee.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.handleSuggestionClick = this.handleSuggestionClick.bind(this);
  }
  componentWillMount() {
    if (this.props && this.props.authToken === false) {
      // this.props.history.push("/login");
    }
    this.roleData();
  }

  componentWillReceiveProps(props) {
    if (props && props.authToken === false) {
      // props.history.push("/login");
    }
  }

  roleData = async (e) => {
    let role = await AuthApi.getRole();
    // console.log(role);
    this.setState({
      role: role.data,
      count: role.data.length,
    });
  };

  async handleClickOpen(row = false) {
    let permission = await AuthApi.getPermission();
    this.setState({
      permission: permission.data,
    });
    this.setState({
      setopen: true,
      isPopup: true,
      roleName: row.name,
    });
  }

  async updateRoleee(id) {
    let upte = await AuthApi.updateRole(
      this.state.roleName,
      this.state.checkbox,
      id
    );
    // console.log(id)
    if (upte && upte.status === true) {
      this.setState({ setopen: false }, function () {
        this.roleData();
      });
    }
  }

  async handleClickOpenUpdateRole(row = false) {
    let permission = await AuthApi.getPermission();
    this.setState({
      permission: permission.data,
    });
    if (Object.keys(row.permissions).length > 0) {
      let permissions = [];
      Object.keys(row.permissions).forEach((key) => {
        permissions.push(row.permissions[key].name);
      });
      this.setState({ rolePermission: permissions });
    }
    await this.setState({
      setopen: true,
      isPopup: false,
      roleName: row.name,
      roleId: row.id,
    });
  }

  handleClose() {
    this.setState({
      setopen: false,
      rolePermission: [],
    });
  }

  async saveData() {
    if (this.state.isPopup) {
      let currentRole = await AuthApi.createRole(
        this.state.roleName,
        this.state.checkBox
      );
      if (currentRole && currentRole.status === true) {
        this.setState({ setopen: false }, function () {
          this.roleData();
        });
      } else {
        // axios popup result
      }
    } else {
      this.updateRoleee(this.state.roleId);
    }
  }

  async removeRole(id) {
    swal({
      title: "Are you sure?",
      icon: "warning",
      buttons: ["Cancel", "Yes"],
    }).then(async (confirm) => {
      if (confirm) {
        let currentRole = await AuthApi.deleteRole(id);
        if (currentRole && currentRole.status === true) {
          this.setState({
            pageStatus: true,
          });
          this.roleData();
          setTimeout(() => this.setState({ pageStatus: false }), 500);
        } else {
        }
      }
      // console.log(this.props.page);
    });
  }

  updatChange(e) {
    this.setState({ roleName: e.target.value });
  }

  updateCheckbox(value) {
    this.setState({ checkBox: value });
  }

  handleChange(e) {
    const searchQuery = e.target.value;
    const suggestions = this.state.role.filter((item) => {
      return (
        item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.created_at.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
    this.setState({ searchQuery, suggestions });
  }

  handleSearch = () => {
    // Trigger search functionality
    const filterEvents = this.state.role.filter((item) => {
      return (
        item.name
          .toLowerCase()
          .includes(this.state.searchQuery.toLowerCase()) ||
        item.created_at
          .toLowerCase()
          .includes(this.state.searchQuery.toLowerCase())
      );
    });
    this.setState({ filterEvents });
  };

  clearSearch = () => {
    this.setState({ searchQuery: "", suggestions: [] }, this.handleSearch);
  };

  handleSuggestionClick = (suggestion) => {
    this.setState({ searchQuery: suggestion.name, suggestions: [] }, this.handleSearch);
  };

  render() {
    const classes = this.props;
    const filterEvents = this.state.filterEvents || this.state.role;
    const permissions = (this.props.authUser && Array.isArray(this.props.authUser.userAccess)) ? this.props.authUser.userAccess : [];
    // console.log(permissions)

    return (
      <div>
        <AuthCheckComponent authToken={this.props.authToken} />
        <Header
          {...this.props}
          authUser={this.props.authUser}
          setAutUser={this.props.setAutUser}
          component={
            <div>
              <Card className={classes.root} style={{ marginBottom: "3%" }}>
                <CardContent>
                  <Breadcrumb
                    {...this.props}
                    primaryPageName="Roles"
                    primaryPageLink="/role"
                    isSecondaryPage={false}
                    secondaryPageName=""
                  />
                </CardContent>
              </Card>
              <TextField
                label="Search"
                value={this.state.searchQuery}
                onChange={this.handleChange}
                style={{
                  position: "absolute",
                  right: "121px",
                  marginTop: "21px"
                }}
                variant="outlined"
                InputLabelProps={{
                  shrink: this.state.searchQuery !== "",
                  style: {
                    color: '#1b6e95',
                    transform: this.state.searchQuery !== "" ? 'translate(14px, -6px) scale(0.75)' : 'translate(14px, 11px) scale(1)', // Adjust this line
                    transition: 'transform 0.2s ease-in-out'
                  }
                }}
                InputProps={{
                  style: {
                    backgroundColor: 'lightgray',
                    borderRadius: '4px',
                    height: "40px"
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      {this.state.searchQuery && (
                        <ClearIcon onClick={this.clearSearch} style={{ cursor: 'pointer' }} />
                      )}
                      <SearchIcon onClick={this.handleSearch} style={{ cursor: 'pointer' }} />
                    </InputAdornment>
                  ),
                }}
              />
              {this.state.searchQuery && this.state.suggestions.length > 0 && (
                <Paper
                  style={{
                    position: 'absolute',
                    right: '188px',
                    marginTop: '62px',
                    zIndex: 1,
                    width: "210px",
                    maxHeight: '200px',
                    overflowY: 'auto',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)'
                  }}>
                  {[...new Set(this.state.suggestions.map(suggestion => suggestion.name))].map((name, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => this.handleSuggestionClick({ name })}
                      style={{
                        padding: '10px 20px',
                        cursor: 'pointer'
                      }}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Paper>
              )}
              {/* {console.log(filterEvents)} */}
              <TableComponent
                {...this.props}
                tableTh={this.state.tableTh}
                tableData={filterEvents}
                tableCount={filterEvents.length}
                tablePage={this.state.page}
                tablePagestatus={this.state.pageStatus}
                colNameToShow={["name", "created_at"]}
                // openPopUp={this.handleClickOpen}
                // openPopUpUpdate={this.handleClickOpenUpdateRole}
                removeRow={this.removeRole}
                actionBtns={[permissions.includes("Role.Edit") && ('update1'), permissions.includes("Role.Delete") && ('delete')]}
                updateRoute={'/Page/edit'}
                modelName={"Role"}
                addRoute={'/Role/add'}
                openPopUpUpdate={false}
              />
            </div>
          }
        />
        <Footer />
        <PopUp
          isPopup={this.state.isPopup}
          {...this.props}
          handleClose={this.handleClose}
          setopen={this.state.setopen}
          roleName={this.state.roleName}
          saveData={this.saveData}
          updatChange={this.updatChange}
          permission={this.state.permission}
          inputHandleChange={this.inputHandleChange}
          checkbox={this.state.checkbox}
          updateCheckbox={this.updateCheckbox}
          updateRoleee={this.updateRoleee}
          rolePermission={this.state.rolePermission}
        />
      </div>
    );
  }
}

export default Role;

class PopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permission: false,
      value: true,
      loading: true,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value) {
    this.props.updateCheckbox(value);
  }

  componentWillReceiveProps() {
    if (
      typeof this.props.rolePermission !== "undefined" &&
      Object.keys(this.props.rolePermission).length > 0
    ) {
      this.setState({ loading: false });
    }
  }

  componentWillReceiveProps(props) {
    if (
      typeof props.rolePermission !== "undefined" &&
      Object.keys(props.rolePermission).length > 0
    ) {
      this.setState({ loading: false });
    }
  }

  render() {
    let error = this.props.roleName == undefined;
    return (
      <div>
        <Dialog open={this.props.setopen}>
          <DialogTitle>
            {this.props.isPopup ? "Add Role" : " Update Role"}
          </DialogTitle>
          {/* {
            this.state.loading === true
              ?
              <PageLoader />
              : */}
          <div>
            <DialogContent>
              {/* <DialogContentText> */}
              <TextField
                autoFocus
                helperText={error ? "Cannot be blank " : "Perfect!"}
                error={error}
                name="name"
                label="Name"
                type="text"
                value={
                  this.props.roleName && this.props.roleName !== false
                    ? this.props.roleName
                    : ""
                }
                onChange={(e) => {
                  this.props.updatChange(e);
                }}
              />
              {/* </DialogContentText> */}
            </DialogContent>

            <DialogContent>
              <DropdownComponent
                inputValue={this.props.rolePermission}
                inputOptions={(this.props.permission && this.props.permission !== null) ? this.props.permission : null}
                inputName="Permission"
                inputTypeMultiple={true}
                handleChange={this.handleChange}
              />
            </DialogContent>
          </div>
          {/* } */}
          <DialogActions>
            <Button
              color="primary"
              onClick={(e) => {
                this.props.saveData();
              }}
            >
              Save
            </Button>
            <Button
              onClick={(e) => {
                this.props.handleClose();
              }}
              color="primary"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}