import React, { useState, useEffect, lazy, Suspense } from "react";
import Header from "../../Layouts/Header";
import Footer from "../../Layouts/Footer";
import Grid from "@material-ui/core/Grid";
import Breadcrumb from "../../Layouts/Breadcrumb";
import { Typography, Button, Card, CardContent, Tabs, Tab, Box, Dialog, DialogTitle, DialogContent, TextField } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import AuthCheckComponent from "../../Layouts/Token";
import * as FaIcons from "react-icons/fa";
import * as MdIcons from "react-icons/md";
// import * as GiIcons from "react-icons/gi";
// import * as AiIcons from "react-icons/ai";
// import * as BiIcons from "react-icons/bi";
// import * as RiIcons from "react-icons/ri";
// import * as CgIcons from "react-icons/cg";
// import * as BsIcons from "react-icons/bs";
// import * as FiIcons from "react-icons/fi";
// import * as IoIcons from "react-icons/io5";
// import * as HiIcons from "react-icons/hi";
// import * as GrIcons from "react-icons/gr";
// import * as DiIcons from "react-icons/di";
import Authapi from "../../Services/Authapi";
import Swal from "sweetalert2";

const notificationLabels = [
  "Leave Ticket", "Birthday", "Leave Status", "Upcoming Event", "Salary-Slip", "Time-Entry", "New Holiday", "Time-Entry Status", "Resign-Letter",
];

const allIcons = {
  ...FaIcons,
  ...MdIcons,
  // ...GiIcons,
  // ...AiIcons,
  // ...BiIcons,
  // ...RiIcons,
  // ...CgIcons,
  // ...BsIcons,
  // ...FiIcons,
  // ...IoIcons,
  // ...HiIcons,
  // ...GrIcons,
  // ...DiIcons,
  // Add more icon packs here if needed
};

const IconPicker = lazy(() => import("./IconPicker"));

const Settings = (props) => {
  const classes = props;
  const [notificationIcons, setNotificationIcons] = useState({});
  const [tabIndex, setTabIndex] = useState(0);
  // const [transparentSidebar, setTransparentSidebar] = useState(false);
  const [iconPickerOpen, setIconPickerOpen] = useState(false);
  const [currentLabel, setCurrentLabel] = useState("");
  const [loading, setLoading] = useState(true);
  const [updatedIcons, setUpdatedIcons] = useState({});
  const [fullDayHours, setFullDayHours] = useState("");
  const [halfDayHours, setHalfDayHours] = useState("");
  const [lopLeaveBalance, setLopLeaveBalance] = useState("");
  const [casualLeaveBalance, setCasualLeaveBalance] = useState("");

  const transformLabel = (label) => {
    return label.replace(/_/g, " ");
  };

  useEffect(() => {
    const initialIcons = {};
    notificationLabels.forEach((label) => {
      initialIcons[label] = "FaFolderPlus";
    });

    Authapi.getNotificationIcons()
      .then((response) => {
        if (response) {
          const transformedResponse = {};
          for (const key in response) {
            transformedResponse[transformLabel(key)] = response[key];
          }
          setNotificationIcons((prevIcons) => ({
            ...initialIcons,
            ...transformedResponse,
          }));
        } else {
          setNotificationIcons(initialIcons);
        }
      })
      .catch((error) => {
        console.error("Error fetching notification icons:", error);
        setNotificationIcons(initialIcons);
      })
      .finally(() => {
        setLoading(false);
      });

      Authapi.getWorkingHours()
      .then((response) => {
        if (response) {
          setFullDayHours(response.data.full_day_hours);
          setHalfDayHours(response.data.half_day_hours);
        }
      })
      .catch((error) => {
        console.error("Error fetching working hours:", error);
      });
  
    Authapi.getleavebalance()
      .then((response) => {
        if (response) {
          setCasualLeaveBalance(response.data.casual_leave_balance);
          setLopLeaveBalance(response.data.lop_leave_balance);
        }
      })
      .catch((error) => {
        console.error("Error fetching leave balance:", error);
      });
  }, []);
  

  const handleIconChange = (iconKey) => {
    setNotificationIcons((prev) => ({
      ...prev,
      [currentLabel]: iconKey,
    }));
    setUpdatedIcons((prev) => ({
      ...prev,
      [currentLabel]: iconKey,
    }));
    setIconPickerOpen(false);
  };

  const handleSave = () => {
    Swal.fire({
      title: "Saving...",
      text: "Please wait while we save your changes.",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    Authapi.saveNotificationIcons(updatedIcons)
      .then((response) => {
        if (response) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Icons saved successfully!",
          }).then(() => {
            props.history.push('/dashboard');
          });
          setUpdatedIcons({});
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Error saving icons.",
          });
        }
      })
      .catch((error) => {
        console.error("Error saving notification icons:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Error saving notification icons.",
        });
      });
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  // const handleSidebarChange = (event) => {
  //   setTransparentSidebar(event.target.checked);
  // };

  const openIconPicker = (label) => {
    setCurrentLabel(label);
    setIconPickerOpen(true);
  };

  const handleWorkingHoursSave = async () => {
    Swal.fire({
      title: "Saving...",
      text: "Please wait while we save your changes.",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const hours = {
      fullDayHours,
      halfDayHours,
    };

    try {
      const response = await Authapi.saveWorkingHours(hours);
      if (response) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Working hours saved successfully!",
        }).then(() => {
          props.history.push('/dashboard');
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to save working hours.",
        });
      }
    } catch (error) {
      console.error("Failed to save working hours:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to save working hours.",
      });
    }
  };

  const handleLeaveSettingsSave = async () => {
    Swal.fire({
      title: "Saving...",
      text: "Please wait while we save your changes.",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  
    const leaveBalance = {
      casualLeaveBalance,
      lopLeaveBalance,
    };
  
    try {
      const response = await Authapi.saveleavebalance(leaveBalance);
      if (response) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Leave balances saved successfully!",
        }).then(() => {
          props.history.push('/dashboard');
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to save leave balances.",
        });
      }
    } catch (error) {
      console.error("Failed to save leave balances:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to save leave balances.",
      });
    }
  };

  return (
    <div>
      <AuthCheckComponent authToken={props.authToken} />
      <Header
        {...props}
        authUser={props.authUser}
        component={
          <div>
            <Card className={classes.root} style={{ marginBottom: "0%" }}>
              <CardContent>
                <Breadcrumb
                  {...props}
                  primaryPageName="Settings"
                  primaryPageLink="/Settings"
                />
              </CardContent>
            </Card>
           <Grid container className={classes.root} spacing={2}>
              <div style={{ padding: "20px", width: "100%" }}> 
                <Tabs
                  value={tabIndex}
                  onChange={handleTabChange}
                  aria-label="settings tabs"
                >
                  <Tab label="Working Hours" />
                  <Tab label="Leaves Settings" />
                  {/* <Tab label="Team" />
                  <Tab label="Appearance" /> */}
                  <Tab label="Notifications" />
                  {/* <Tab label="Audit Trail" /> */}
                </Tabs>
                <TabPanel value={tabIndex} index={0}>
                  <Grid container justifyContent="flex-start">
                    <Typography variant="h5" gutterBottom style={{ marginBottom: "25px", color: "#1B6E95" }}>
                      Working Hours Settings
                    </Typography>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={fullDayHours}
                        onChange={(e) => setFullDayHours(e.target.value)}
                        label="Min Full-Day Hours"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={halfDayHours}
                        onChange={(e) => setHalfDayHours(e.target.value)}
                        label="Min Half-Day Hours"
                      />
                    </Grid>
                  </Grid>
                  <Grid container className={classes.root} spacing={3}>
                    <Grid item xs={4} style={{ display: 'flex' }}>
                      <Button type="submit" style={{ marginTop: "10px", backgroundColor: "#1B6E95" }} variant="contained" color="primary" onClick={handleWorkingHoursSave}>Save</Button>
                      <Button type="button" style={{ marginTop: "10px", color: "#000000" }} onClick={(e) => { e.preventDefault(); props.history.push('/dashboard') }}>Cancel</Button>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                  <Grid container justifyContent="flex-start">
                    <Typography variant="h5" gutterBottom style={{ marginBottom: "25px", color: "#1B6E95" }}>
                      Leave Settings
                    </Typography>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={lopLeaveBalance}
                        onChange={(e) => setLopLeaveBalance(e.target.value)}
                        label="Loss of Pay Leave Balance"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={casualLeaveBalance}
                        onChange={(e) => setCasualLeaveBalance(e.target.value)}
                        label="Casual Leave Balance"
                      />
                    </Grid>
                  </Grid>
                  <Grid container className={classes.root} spacing={3}>
                    <Grid item xs={4} style={{ display: 'flex' }}>
                      <Button type="submit" style={{ marginTop: "10px", backgroundColor: "#1B6E95" }} variant="contained" color="primary" onClick={handleLeaveSettingsSave}>Save</Button>
                      <Button type="button" style={{ marginTop: "10px", color: "#000000" }} onClick={(e) => { e.preventDefault(); props.history.push('/dashboard') }}>Cancel</Button>
                    </Grid>
                  </Grid>
                </TabPanel>
                {/* <TabPanel value={tabIndex} index={3}>
                  <Typography variant="h5" gutterBottom>
                    Appearance
                  </Typography>
                </TabPanel> */}
                <TabPanel value={tabIndex} index={2}>
                  <Typography variant="h5" gutterBottom>
                    Edit Notification Icons
                  </Typography>
                  {loading ? (
                    <Typography>Loading...</Typography>
                  ) : (
                    <Grid container spacing={2}>
                      {notificationLabels.map((label) => (
                        <Grid item xs={12} sm={6} md={2} key={label}>
                          <Card style={{ marginBottom: "10px" }}>
                            <CardContent>
                              <Typography variant="h6">{label}</Typography>
                              <Button onClick={() => openIconPicker(label)}>
                                {allIcons[notificationIcons[label]]
                                  ? React.createElement(
                                    allIcons[notificationIcons[label]],
                                    { size: 30, color: "#1B6E95" }
                                  )
                                  : React.createElement(
                                    allIcons["FaFolderPlus"],
                                    { size: 30, color: "#1B6E95" }
                                  )}
                              </Button>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                    onClick={handleSave}
                    style={{ marginTop: "20px", backgroundColor: "#1b6e95" }}
                  >
                    Save
                  </Button>
                </TabPanel>
              </div>
            </Grid>
          </div>
        }
      />
      <Footer />
      <Dialog open={iconPickerOpen} onClose={() => setIconPickerOpen(false)}>
        <DialogTitle color="#1B6E95">Select an Icon</DialogTitle>
        <DialogContent>
          <Suspense fallback={<div>Loading...</div>}>
            <IconPicker
              onSelect={handleIconChange}
              onCancel={() => setIconPickerOpen(false)}
            />
          </Suspense>
        </DialogContent>
      </Dialog>
    </div>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export default Settings;