import React, { useState, useEffect } from "react";
import {
  withStyles,
  makeStyles,
  useTheme,
  // lighten,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import PropTypes from "prop-types";
import TableSortLabel from "@material-ui/core/TableSortLabel";
// import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
// import Tooltip from "@material-ui/core/Tooltip";
import FontAwesomeIconComponent from "../Layouts/FontAwesomeIconComponent";
import Typography from "@material-ui/core/Typography";
import ls from "local-storage";
// import ClearIcon from '@mui/icons-material/Clear';
// import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// import Switch from '@material-ui/core/Switch';
import "./tablecomponent.css";
import Switch from '@material-ui/core/Switch';
import Tooltip from "@material-ui/core/Tooltip";
import { useHistory } from 'react-router-dom';
// import Authapi from "../Services/Authapi";
import swal from "sweetalert";





export default function TableComponent(props) {
  const classes = useStyles();
  const history = useHistory();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [order, setOrder] = React.useState(props.tableTh[0]);
  const [orderBy, setOrderBy] = React.useState(props.tableTh[0]);
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, props.tableData.length - page * rowsPerPage);
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  console.warn = console.error = () => { };
  // const [isChecked, setIsChecked] = useState(false);

  const getActionBtns = (props, row) => {
    if (Array.isArray(props.actionBtns)) {
      // Your existing code for getActionBtns
    } else {
      // Handle the case where actionBtns is not an array (possibly null)
      console.error("actionBtns is not an array");
      return null; // or handle it according to your application logic
    }
    const isPending = row.status === "Pending";
    // const isEmployee = ls("roles") === "Employee";
    const isLeaveTicketPage = window.location.pathname === "/tickets";
    const isRelievingLetterPending =
      window.location.pathname === "/Documentslist" && isPending;

    const isAdminOnLeaveTicketPage =
      isLeaveTicketPage && ls("roles") === "Admin";

    // Check if it's not the leave ticket page or the user is an admin
    const isNotLeaveTicketPageOrAdmin =
      !isLeaveTicketPage || ls("roles") !== "Admin";


    // const handleCheckboxChange = async (id, status) => {
    //   let statu = await Authapi.UserStatus(id, status);

    //   if (statu && statu.status == true) {
    //     document.location.reload();
    //   }
    // };


    // const isEmployeePendingLeave = isEmployee && isPending;



    return (
      <div>
        <Grid container spacing={3}>
          {/* {props.actionBtns.indexOf("status") > -1 ? (
            <Grid item xs={1} style={{ marginTop: "6px", marginRight: "20px" }}>
              {row.user_logged == 0 ? (
                <label className="switch">
                  <input
                    type="checkbox"
                    data-toggle="toggle"
                    onChange={() => handleCheckboxChange(row.id, 1)}
                  />
                  <span className="sliderhh round"></span>
                </label>
              ) : (
                <label className="switch">
                  <input
                    type="checkbox"
                    checked
                    data-toggle="toggle"
                    onChange={() => handleCheckboxChange(row.id, 0)}
                  />
                  <span className="sliderhh round"></span>
                </label>
              )}
            </Grid>
          ) : (
            ""
          )} */}



          {props.actionBtns.indexOf("viewdetails") > -1 ? (
            <Tooltip title="View Details">
              <Grid item xs={2}>
                {" "}
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    props.viewDetails(row.id);
                  }}
                // style={{ padding: "1px", marginLeft: "-5px" }}
                >
                  <FontAwesomeIconComponent
                    classes="fa fa-info-circle"
                    colorName="primary"
                    fontSize={"small"}
                  />
                </IconButton>{" "}
              </Grid>
            </Tooltip>
          ) : (
            ""
          )}
          {props.actionBtns.indexOf("edit") > -1 && row.is_approved === 1 ? (
            <Tooltip title="Edit">
              <Grid item xs={2}>
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    props.handleEditClick(row);
                  }}
                >
                  <FontAwesomeIconComponent
                    classes="fa fa-edit"
                    colorName="primary"
                    fontSize={"small"}
                  />
                </IconButton>
              </Grid>
            </Tooltip>
          ) : (
            ""
          )}

          {props.actionBtns.indexOf("deletetimeentry") > -1 && row.is_approved === 1 ? (
            <Tooltip title="Delete">
              <Grid item xs={2}>
                <IconButton
                  variant="contained"
                  color="secondary"
                  onClick={(e) => {
                    swal({
                      title: "Are you sure?",
                      text: "Do you want to delete this entry?",
                      icon: "warning",
                      buttons: ["Cancel", "Yes"],
                    }).then((confirm) => {
                      if (confirm) {
                        props.handleDelete(row.id);
                      }
                    });
                  }}
                >
                  <FontAwesomeIconComponent
                    classes="fa fa-trash"
                    colorName="secondary"
                    fontSize={"small"}
                  />
                </IconButton>
              </Grid>
            </Tooltip>
          ) : (
            ""
          )}



          {isAdminOnLeaveTicketPage &&
            isPending &&
            props.actionBtns.indexOf("update") > -1 ? (
            <Tooltip title="Update">
              <Grid item xs={2}>
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    props.openPopUpUpdate !== false
                      ? props.openPopUpUpdate(row)
                      : props.history.push(props.updateRoute + "/" + row.id);
                  }}
                // style={{ padding: "1px" }}
                >
                  <FontAwesomeIconComponent
                    classes="fa fa-edit"
                    colorName="primary"
                    fontSize={"small"}
                  />
                </IconButton>
              </Grid>
            </Tooltip>
          ) : null}

          {/* {isEmployeePendingLeave && 
            props.actionBtns.indexOf("update1") > -1 ? (
            <Tooltip title="Update">
              <Grid item xs={1}>
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    props.openPopUpUpdate !== false
                      ? props.openPopUpUpdate(row)
                      : props.history.push(props.updateRoute + "/" + row.id);
                  }}
                >
                  <FontAwesomeIconComponent
                    classes="fa fa-edit"
                    colorName="primary"
                    fontSize={"small"}
                  />
                </IconButton>{" "}
              </Grid>
            </Tooltip>
          ) : null} */}
          {props.actionBtns.indexOf("status") > -1 ? (
            <Tooltip title="Status">
              <Grid item xs={2}>
                {" "}
                <IconButton
                  variant="contained"
                  color="primary"
                  // onClick={(e) => {
                  //   props.statusRow(row.id);
                  //   // console.log(row)
                  // }}
                  style={{ padding: "1px", marginTop: "2px", marginLeft: "-20px" }}
                >
                  <Switch
                    checked={row.user_logged === 1}
                    // onChange={() => handleCheckboxChange(item.id, item.status === 1 ? 0 : 1)}
                    // color="primary"
                    onClick={(e) => props.statusRow(row.id, row.user_logged === 1 ? 0 : 1)}
                    color="primary"
                  />
                </IconButton>{" "}
              </Grid>
            </Tooltip>
          ) : (
            ""
          )}

          {isNotLeaveTicketPageOrAdmin &&
            props.actionBtns.indexOf("update") > -1 ? (
            <Tooltip title="Update">
              <Grid item xs={2}>
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    props.openPopUpUpdate !== false
                      ? props.openPopUpUpdate(row)
                      : props.history.push(props.updateRoute + "/" + row.id);
                  }}
                // style={{ padding: "1px" }}
                >
                  <FontAwesomeIconComponent
                    classes="fa fa-edit"
                    colorName="primary"
                    fontSize={"small"}
                  />
                </IconButton>{" "}
              </Grid>
            </Tooltip>
          ) : null}

          {props.actionBtns.indexOf("update1") > -1 ? (
            <Tooltip title="Update">
              <Grid item xs={2}>
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    props.openPopUpUpdate !== false
                      ? props.openPopUpUpdate(row)
                      : props.history.push(props.updateRoute + "/" + row.id);
                  }}
                // style={{ padding: "1px" }}
                >
                  <FontAwesomeIconComponent
                    classes="fa fa-edit"
                    colorName="primary"
                    fontSize={"small"}
                  />
                </IconButton>{" "}
              </Grid>
            </Tooltip>
          ) : null}



          {props.actionBtns.indexOf("show") > -1 ? (
            <Tooltip title="Show">
              <Grid item xs={2}>
                {" "}
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    props.showRow(row.id, "Offer Later");
                  }}
                // style={{ padding: "1px", marginLeft: "3px" }}
                >
                  <FontAwesomeIconComponent
                    classes="fa fa-eye"
                    colorName="primary"
                    fontSize={"small"}
                  />
                </IconButton>{" "}
              </Grid>
            </Tooltip>
          ) : (
            ""
          )}

          {props.actionBtns.indexOf("show1") > -1 ? (
            <Tooltip title="Show">
              <Grid item xs={2}>
                {" "}
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    props.showRow(row.id, "Offer Later");
                  }}
                // style={{ padding: "1px", marginLeft: "3px" }}
                >
                  <FontAwesomeIconComponent
                    classes="fa fa-eye"
                    colorName="primary"
                    fontSize={"small"}
                  />
                </IconButton>{" "}
              </Grid>
            </Tooltip>
          ) : (
            ""
          )}

          {isRelievingLetterPending ? (
            <React.Fragment>
              {props.actionBtns.indexOf("approve1") > -1 ? (
                <Tooltip title="Approve">
                  <Grid item xs={2}>
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={(e) => {
                        props.acceptRow(row.id);
                      }}
                    // style={{ padding: "1px", marginLeft: "4px" }}
                    >

                      {/* <FontAwesomeIcon icon="far fa-check-square" /> */}
                      <FontAwesomeIconComponent
                        classes="far fa-check-square"
                        colorName="primary"
                        fontSize={"small"}
                      />
                    </IconButton>
                  </Grid>
                </Tooltip>
              ) : null}

              {props.actionBtns.indexOf("reject1") > -1 ? (
                <Tooltip title="Reject">
                  <Grid item xs={2}>
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={(e) => {
                        props.rejectRow(row.id);
                      }}
                    // style={{ padding: "1px", marginLeft: "7px" }}
                    >
                      <FontAwesomeIconComponent
                        classes="fa fa-window-close"
                        colorName="primary"
                        fontSize={"small"}
                      />
                    </IconButton>
                  </Grid>
                </Tooltip>
              ) : null}
            </React.Fragment>
          ) : null}

          {isNotLeaveTicketPageOrAdmin ? (
            <React.Fragment>
              {props.actionBtns.indexOf("approve") > -1 ? (
                <Tooltip title="Approve">
                  <Grid item xs={2}>
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={(e) => {
                        props.acceptRow(row.id);
                      }}
                    // style={{ padding: "1px" }}
                    >
                      <FontAwesomeIconComponent
                        classes="far fa-check-square"
                        colorName="primary"
                        fontSize={"small"}
                      />
                    </IconButton>
                  </Grid>
                </Tooltip>
              ) : null}


              {props.actionBtns.indexOf("approve111") > -1 && row.is_approved === 1 ? (
                <Tooltip title="Approve">
                  <Grid item xs={2}>
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={(e) => {
                        props.acceptRow(row.id);
                      }}
                    // style={{ padding: "1px" }}
                    >
                      <FontAwesomeIconComponent
                        classes="far fa-check-square"
                        colorName="primary"
                        fontSize={"small"}
                      />
                    </IconButton>
                  </Grid>
                </Tooltip>
              ) : null}



              {props.actionBtns.indexOf("reject") > -1 ? (
                <Tooltip title="Reject">
                  <Grid item xs={2}>
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={(e) => {
                        props.rejectRow(row.id);
                      }}
                    // style={{ padding: "1px" }}
                    >
                      <FontAwesomeIconComponent
                        classes="fas fa-window-close"
                        colorName="primary"
                        fontSize={"small"}
                      />
                    </IconButton>
                  </Grid>
                </Tooltip>
              ) : null}
            </React.Fragment>
          ) : null}



          {props.actionBtns.indexOf("reject11") > -1 && row.is_approved === 1 ? (
            <Tooltip title="Reject">
              <Grid item xs={2}>
                {/* {console.log(row)} */}
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    props.rejectRow(row.id);
                  }}
                // style={{ padding: "1px" }}
                >
                  <FontAwesomeIconComponent
                    classes="fas fa-window-close"
                    colorName="primary"
                    fontSize={"small"}
                  />
                </IconButton>
              </Grid>
            </Tooltip>
          ) : null}


          {props.actionBtns.indexOf("download") > -1 ? (
            <Tooltip title="Download">
              <Grid item xs={2}>
                {" "}
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    props.downloadRow(row.id);
                  }}
                  style={{
                    // padding: "1px", marginLeft: "4px"
                  }}
                >
                  <FontAwesomeIconComponent
                    classes="fa fa-download"
                    colorName="primary"
                    fontSize={"small"}
                  />
                </IconButton>{" "}
              </Grid>
            </Tooltip>
          ) : (
            ""
          )}

          {props.actionBtns.indexOf("download1") > -1 ? (
            <Tooltip title="Download">
              <Grid item xs={2}>
                {" "}
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    props.downloadRow(row.user_id);
                  }}
                  style={{
                    // padding: "1px", marginLeft: "4px"
                  }}
                >
                  <FontAwesomeIconComponent
                    classes="fa fa-download"
                    colorName="primary"
                    fontSize={"small"}
                  />
                </IconButton>{" "}
              </Grid>
            </Tooltip>
          ) : (
            ""
          )}

          {props.actionBtns.indexOf("delete") > -1 ? (
            <Tooltip title="Delete">
              <Grid item xs={2}>
                {" "}
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    props.removeRow(row.id);
                    console.log(row)
                  }}
                // style={{ padding: "1px", margingLeft: "2px" }}
                >
                  <FontAwesomeIconComponent
                    classes="fa fa-trash"
                    colorName="primary"
                    fontSize={"small"}
                  />
                </IconButton>{" "}
              </Grid>
            </Tooltip>
          ) : (
            ""
          )}

          {props.actionBtns.indexOf("delete1") > -1 ? (
            <Tooltip title="Delete">
              <Grid item xs={2}>
                {" "}
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    props.removeRow(row.id);
                    console.log(row)
                  }}
                // style={{ padding: "1px", marginLeft: "-5px" }}
                >
                  <FontAwesomeIconComponent
                    classes="fa fa-trash"
                    colorName="primary"
                    fontSize={"small"}
                  />
                </IconButton>{" "}
              </Grid>
            </Tooltip>
          ) : (
            ""
          )}
        </Grid>
      </div>
    );
  };

  // let th = [];
  useEffect(() => {
    if (props.tablePagestatus === true) {
      let rawCount = props.tableCount;
      let perPage = rowsPerPage;
      let answer = rawCount / perPage;
      let page2 = page;
      if (page2 > rawCount % perPage) {
        if (rawCount % perPage === 0) {
          handleChangePage(answer - 1);
        } else {
          handleChangePage(Math.floor(answer));
        }
      }
    }
  });

  let td = [];
  let tr = [];
  if (Object.keys(props.tableData).length > 0) {
    let tableRows = props.tableData;

    if (rowsPerPage > 0) {
      tableRows = props.tableData.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
    }
    tableRows = stableSort(tableRows, getComparator(order, orderBy));

    Object.keys(tableRows).forEach((key) => {
      tr.push(
        <StyledTableRow key={key}>
          {props.colNameToShow.map((columnName) => (
            <TableCell key={columnName} align="left" style={{ overflow: "hidden" }}>
              {columnName === "photo" ? (
                <img src={tableRows[key][columnName]} alt="User" style={{ width: "50px", height: "50px", borderRadius: "50%" }} />
              ) : (
                tableRows[key][columnName]
              )}
            </TableCell>
          ))}
          {/* Add dropdown cell if needed */}
          {props.includeDropdown && (
            <TableCell key="dropdown" align="left">
              {props.dropdownRenderer ? props.dropdownRenderer() : null}
            </TableCell>
          )}
          {/* Add action buttons cell */}
          <TableCell key="actionButtons" align="left">
            {getActionBtns(props, tableRows[key])}
          </TableCell>
        </StyledTableRow>
      );
    });

    if (emptyRows > 0) {
      tr.push(
        <TableRow key={Math.random} style={{ height: emptyRows }}>
          {" "}
        </TableRow>
      );
      // tr.push(<TableRow  key={Math.random} style={{ height: 67 * emptyRows }}> <TableCell colSpan={Object.keys(props.tableTh).length} /> </TableRow>)
    }
  } else {
    // td.push(<TableCell colSpan={Object.keys(props.tableTh).length} align="center">No data found</TableCell>)
    tr.push(
      <StyledTableRow key={1}>
        <TableCell colSpan={Object.keys(props.tableTh).length} align="center">
          No data found
        </TableCell>
      </StyledTableRow>
    );
  }

  const permissions = (props.authUser && Array.isArray(props.authUser.userAccess)) ? props.authUser.userAccess : [];
  // console.log(permissions)
  const url = window.location.pathname;
  // console.log(url)

  var display;
  if ((permissions.includes("Role.Add") && url === "/role") ||
    (permissions.includes("Permission.Add") && url === "/permission") ||
    (permissions.includes("Users.Add") && url === "/users") ||
    (permissions.includes("Holidays.Add") && url === "/leaves") ||
    (permissions.includes("Upcomingevent.Add") && url === "/upcoming") ||
    (permissions.includes("Leaveticket.Add") && url === "/tickets") ||
    (permissions.includes("Job.Add") && url === "/job") ||
    (permissions.includes("Qualification.Add") && url === "/qulification") ||
    (permissions.includes("Experience.Add") && url === "/experience") ||
    (permissions.includes("Offerletter.Add") && url === "/Documentslist") ||
    (permissions.includes("Relievingletter.Add") && url === "/Documentslist") ||
    (permissions.includes("Time.Add") && url === "/time") ||
    (permissions.includes("Time.Add") && url === "/check")
  ) {
    display = "block";
  } else {
    display = "none";
  }


  var overflow;
  if ((url === "/tickets")) {
  } else {
    overflow = "hidden";
  }

  // const isHolidayPage = window.location.pathname === "/leaves";
  // const isEmployee = ls("roles") === "Employee";


  const handleRowClick = (userId) => {
    if (window.location.pathname === "/users") {
      history.push(`/users/${userId}`);
    }
  };

  

  return (
    <TableContainer component={Paper} style={{ marginBottom: "5%" }}>
      <Typography variant="h2"> {props.modelName}</Typography>
      {/* {console.log(props.modelName)} */}
      {/* {isHolidayPage && isEmployee ? null : (*/}
      {props.modelName !== "Salary Slip" && (

        // <Button
        //   variant="contained"
        //   color="primary"
        //   style={{ float: "right", margin: "22px", display: display }}
        //   onClick={(e) => {
        //     if (typeof props.openPopUp === 'function') {
        //       props.openPopUp();
        //     } else {
        //       props.history.push(props.addRoute);
        //     }
        //   }}
        // >
        //   Add
        // </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ float: "right", margin: "22px", display: display }}
          onClick={(e) => {
            if (typeof props.openPopUp === 'function') {
              props.openPopUp();
            } else {
              props.history.push(props.addRoute);
            }
          }}
        >
          Add
        </Button>
      )
      }
      {/* //   )
      // )} */}

      <Table
        className={classes.table}
        aria-label="customized table"
        style={{ tableLayout: "fixed", width: "100%" }}
      >
        <TableHead>
          <EnhancedTableHead
            classes={classes}
            order="desc"
            orderBy="asc"
            onRequestSort={handleRequestSort}
            rowCount={props.tableTh.length}
            tableTh={props.tableTh}
          />
        </TableHead>
        <TableBody>
          {props.tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
            <StyledTableRow
              key={row.id}
              style={props.rowStyle ? props.rowStyle(row) : {}}
            >
              {props.colNameToShow.map((columnName) => (
                <TableCell
                  key={columnName}
                  align="left"
                  style={{
                    overflow: overflow,
                    cursor: window.location.pathname === "/users" ? "pointer" : "default",
                    ...(props.rowStyle ? props.rowStyle(row) : {})
                  }}
                  onClick={() => handleRowClick(row.id)}
                >
                  {columnName === "photo" ? (
                    <img src={row[columnName]} alt="User" style={{ width: "50px", height: "50px", borderRadius: "50%" }} />
                  ) : (
                    row[columnName]
                  )}
                </TableCell>
              ))}
              <TableCell key="actionButtons" align="left" style={props.rowStyle ? props.rowStyle(row) : {}}>
                {getActionBtns(props, row)}
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
        <TableFooter style={{ position: "absolute", right: "6px", paddingBottom: "40px" }}>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: props.tableCount }]}
              colSpan={3}
              count={props.tableCount}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
                className: "custom-select",
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  head: {
    backgroundColor: "#1B6E95",
    color: "#fff",
    margin: "0 auto",
    "& th": {
      color: "#fff",
    },
    "& th > span:hover": {
      color: "#fff",
    },
    "& th:hover": {
      color: "#fff",
    },
    "& th:active": {
      color: "#fff",
    },
    "& th > span:active": {
      color: "#fff",
    },
  },
});

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = () => {
    onPageChange(null, 0);
  };

  const handleBackButtonClick = () => {
    onPageChange(null, page - 1);
  };

  const handleNextButtonClick = () => {
    onPageChange(null, page + 1);
  };

  const handleLastPageButtonClick = () => {
    onPageChange(null, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => () => {
    onRequestSort(property);
  };

  return (
    <TableRow className={classes.head}>
      {props.tableTh.map((headCell) => (
        <TableCell
          key={headCell.id}
          align={headCell.numeric ? "right" : "left"}
          // padding="10%"
          maxwidth="300px"
          // overflow="hidden"
          whitespace="normal"
          sortDirection={orderBy === headCell.id ? order : false}
        >
          {headCell.sortable === true ? (
            <TableSortLabel
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}></span>
              ) : null}
            </TableSortLabel>
          ) : (
            headCell.label
          )}
        </TableCell>
      ))}
    </TableRow>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
