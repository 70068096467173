import React, { useState, useEffect } from 'react';
import Header from '../../Layouts/Header';
import Footer from '../../Layouts/Footer';
import Breadcrumb from '../../Layouts/Breadcrumb';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Grid, Button, Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import AuthApi from '../../Services/Authapi';
import parse from 'html-react-parser';
import './Documents.css';
import swal from 'sweetalert';
import FontAwesomeIconComponent from '../../Layouts/FontAwesomeIconComponent';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ls from "local-storage";
import Switch from '@material-ui/core/Switch';
import AuthCheckComponent from '../../Layouts/Token';
import "./Documentslist.css"

const Latter = (props) => {
    const classes = props;
    const [data, setdata] = useState();
    const [expandedItems, setExpandedItems] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    useEffect(() => {
        if (props && props.authToken === false) {
            // props.history.push('/login');
        }
    });

    useEffect(() => {
        Templategetdata();
    }, []);

    const Templategetdata = async () => {
        try {
            let role = await AuthApi.Templategetdata();
            setdata(role.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const hardik = () => {
        props.history.push('/offer');
    };

    const toggleExpanded = (itemId) => {
        setExpandedItems((prevState) => ({
            ...prevState,
            [itemId]: !prevState[itemId],
        }));
    };

    const handleCheckboxChange = async (id, status) => {
        swal({
            title: "Loading",
            text: "Please wait...",
            buttons: false,
            closeOnClickOutside: false,
            closeOnEsc: false,
            width: "10%",
            content: {
                element: "img",
                attributes: {
                    src: "https://www.boasnotas.com/img/loading2.gif",
                    style: "width: 50px;"
                }
            }
        });
        try {
            let statu = await AuthApi.Templategetstatu(id, status);
            swal.close();
            if (statu && statu.status === true) {
                swal("Success", "Template status update successfully!", "success");
                Templategetdata();
            } else {
                swal("Error", statu, "error");
            }
        } catch (error) {
            swal("Error", "An error occurred, Please try again later!", "error");
        }
    };

    const handleEdit = (id) => {
        props.history.push(`/Edittemplate/${id}`);
    };

    const handledelete = async (id) => {
        swal({
            title: "Are you sure?",
            icon: "warning",
            buttons: ["Cancel", "Yes"]
        }).then(async (confirm) => {
            if (confirm) {
                let currentPermission = await AuthApi.Templatedelete(id);
                if (currentPermission && currentPermission.status === true) {
                    swal("Success", "Template delete successfully!", "success");
                    Templategetdata();
                } else {
                }
            }
        });
    }

    const User = ls("user");

    const userpermissions = (User && Array.isArray(User.roles) && User.roles[0] && Array.isArray(User.roles[0].permissions))
        ? User.roles[0].permissions.map(permissionObj => permissionObj.name)
        : [];

    const handleViewTemplate = (template) => {
        setSelectedTemplate(template);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <div>
            <AuthCheckComponent authToken={props.authToken} />
            <Header
                {...props}
                authUser={props.authUser}
                component={
                    <div>
                        <Card className={classes.root} style={{ marginBottom: '3%' }}>
                            <CardContent>
                                <Breadcrumb
                                    {...props}
                                    primaryPageName="User Template"
                                    primaryPageLink="/latter"
                                    isSecondaryPage={false}
                                    secondaryPageName=""
                                />
                            </CardContent>
                        </Card>
                        <Grid container className={classes.root} spacing={2}>
                            <Grid item xs={12}>
                                <Card className={classes.root}>
                                    <Grid item xs={12}>
                                        <CardContent>
                                            <Typography variant="h2" style={{ paddingTop: 0, paddingLeft: 0, paddingBottom: 0 }}> Template All</Typography>
                                        </CardContent>
                                        <Button
                                            variant="contained"
                                            onClick={hardik}
                                            color="primary"
                                            style={{ marginLeft: "93%", top: "-32px" }}
                                        >
                                            Add
                                        </Button>
                                    </Grid>
                                    <Grid container className={classes.root} spacing={5} >
                                        {data && data.map((item) => (
                                            <Grid item xs={4} key={item.id} style={{ padding: "3px" }}>
                                                <Card className={classes.card} >
                                                    <CardContent>
                                                        <Typography variant="h5" component="div">
                                                            {item.template_name}
                                                        </Typography>
                                                        <br />
                                                        {item.letters.length > 0 && (
                                                            <Button
                                                                style={{ backgroundColor: "#06759b", color: "white" }}
                                                                onClick={() => handleViewTemplate(item)}
                                                            >
                                                                View
                                                            </Button>
                                                        )}

                                                        <Grid container className={classes.root} spacing={5} style={{
                                                            position: "relative",
                                                            left: "34%"
                                                        }}>
                                                            {userpermissions.includes("Template.Status") && (<Grid item xs={1} style={{ marginTop: "5px", marginRight: "9px" }}>
                                                                <Tooltip title="Status">
                                                                    <Switch
                                                                        checked={item.status === 1}
                                                                        onChange={() => handleCheckboxChange(item.id, item.status === 1 ? 0 : 1)}
                                                                        color="primary"
                                                                    />
                                                                </Tooltip>
                                                            </Grid>)}

                                                            {userpermissions.includes("Template.Edit") && (<Grid item xs={1}>
                                                                <Tooltip title="Template Edit">
                                                                    <IconButton
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={() => handleEdit(item.id)}
                                                                    >

                                                                        <FontAwesomeIconComponent
                                                                            classes="fa fa-edit"
                                                                            colorName="primary"
                                                                            fontSize={"small"}
                                                                        />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                            )
                                                            }

                                                            {
                                                                userpermissions.includes("Template.Delete") && (
                                                                    <Grid item xs={1}>
                                                                        <Tooltip title="Delete">
                                                                            <IconButton
                                                                                variant="contained"
                                                                                color="primary"
                                                                                onClick={() => handledelete(item.id)}
                                                                                style={{ marginLeft: " -11px" }}
                                                                            >

                                                                                <FontAwesomeIconComponent
                                                                                    classes="fa fa-trash"
                                                                                    colorName="primary"
                                                                                    fontSize={"small"}
                                                                                />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </Grid>
                                                                )
                                                            }

                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                }
            />
            <Footer />
            <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" style={{ marginTop: "35px" }} >
                <DialogTitle>
                    {selectedTemplate?.template_name}
                </DialogTitle>
                <DialogContent>
                    {selectedTemplate && parse(selectedTemplate.letters)}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Latter;