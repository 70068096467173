import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Card, CardContent, Grid, Typography, TextField,
    Button, Checkbox, FormControlLabel, makeStyles
} from '@material-ui/core';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Header from "../Layouts/Header";
import Footer from "../Layouts/Footer";
import Breadcrumb from "../Layouts/Breadcrumb";
import AuthApi from '../Services/Authapi';
import swal from "sweetalert";
import AuthCheckComponent from '../Layouts/Token';

const useStyles = makeStyles((theme) => ({
    treeItem: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1), // Adjust as needed
    },
}));

const EditRole = (props) => {
    const history = useHistory();
    const classes = useStyles();
    const [editValues, setEditValues] = useState({
        roleName: "",
        event_Id: "",
        permissions: [],
    });
    const [permissions, setPermissions] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const eventId = history.location.pathname.split('/').pop();
                const event = await AuthApi.editRole(eventId);

                if (event.status && event.data) {
                    const roleName = event.data.role_name || "";
                    const eventPermissions = event.data.permissions || {};
                    const initialPermissions = [];

                    Object.entries(eventPermissions).forEach(([key, permArray]) => {
                        permArray.forEach(perm => {
                            if (perm.checked) {
                                initialPermissions.push(perm.id);
                            }
                        });
                    });

                    setEditValues({
                        roleName,
                        permissions: initialPermissions,
                        event_Id: eventId,
                    });
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
        getPermissions();
    }, [history.location.pathname]);

    const getPermissions = async () => {
        swal({
            title: "Loading",
            text: "Please wait...",
            buttons: false,
            closeOnClickOutside: false,
            closeOnEsc: false,
            width: "10%",
            content: {
              element: "img",
              attributes: {
                src: "https://www.boasnotas.com/img/loading2.gif",
                style: "width: 50px;" // Adjust the width as needed
              }
            }
          });
        try {
            const permissionResponse = await AuthApi.getpermissions();
            swal.close();
            if (permissionResponse.status) {
                setPermissions(permissionResponse.permissions);
            }
        } catch (error) {
            console.error("Error fetching permissions:", error);
        }
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        const permissionId = parseInt(name, 10);
        setEditValues(prevState => {
            const newPermissions = checked
                ? [...prevState.permissions, permissionId]
                : prevState.permissions.filter(permission => permission !== permissionId);
            return { ...prevState, permissions: newPermissions };
        });
    };

    const handleParentCheckboxChange = (parent, children, checked) => {
        setEditValues(prevState => {
            const childIds = children.map(child => child.id);
            const newPermissions = checked
                ? [...new Set([...prevState.permissions, ...childIds])]
                : prevState.permissions.filter(permission => !childIds.includes(permission));
            return { ...prevState, permissions: newPermissions };
        });
    };

    const isParentChecked = (children) => {
        return children.every(child => editValues.permissions.includes(child.id));
    };


    // roleName: "",
    const hardik = editValues.permissions


    const handleSubmit = async (event) => {
        event.preventDefault();
        swal({
            title: "Loading",
            text: "Please wait...",
            buttons: false,
            closeOnClickOutside: false,
            closeOnEsc: false,
            width: "10%",
            content: {
              element: "img",
              attributes: {
                src: "https://www.boasnotas.com/img/loading2.gif",
                style: "width: 50px;" // Adjust the width as needed
              }
            }
          });
        try {
            const response = await AuthApi.updateRole(editValues.event_Id, hardik);
            swal.close();
            if (response.status) {
                history.push('/role');
            } else {
                console.error("Error updating role:", response.message);
            }
        } catch (error) {
            console.error("Error updating role:", error);
        }
    };

    // const handleSubmit = async (event) => {
    //     event.preventDefault();
    //     try {
    //         const response = await AuthApi.updateRole(editValues.event_Id, editValues);
    //         if (response.status) {
    //             history.push('/role');
    //         } else {
    //             console.error("Error updating role:", response.message);
    //         }
    //     } catch (error) {
    //         console.error("Error updating role:", error);
    //     }
    // };


    return (
        <div>
              <AuthCheckComponent authToken={props.authToken} />
            <Header
                {...props}
                authUser={props.authUser}
                component={
                    <div>
                        <Card style={{ marginBottom: '20px' }}>
                            <CardContent>
                                <Breadcrumb
                                    {...props}
                                    primaryPageName="Role"
                                    primaryPageLink="/role"
                                    isSecondaryPage={true}
                                    secondaryPageName="Permission"
                                />
                            </CardContent>
                        </Card>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Card>
                                    <CardContent>
                                        <Typography variant="h6">Edit Role</Typography>
                                        <form onSubmit={handleSubmit}>
                                            <Grid container spacing={3} style={{ marginTop: "10px" }} >
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        id="roleName"
                                                        name="roleName"
                                                        label="Role Name"
                                                        variant="outlined"
                                                        value={editValues.roleName}
                                                        onChange={(e) => setEditValues({ ...editValues, roleName: e.target.value })}
                                                    />
                                                </Grid>

                                                <Grid container
                                                    style={{
                                                        MozColumnCount: 3,
                                                        MozColumnGap: '20px',
                                                        WebkitColumnCount: 3,
                                                        WebkitColumnGap: '20px',
                                                        columnCount: 3,
                                                        columnGap: '20px',
                                                        display: 'block',
                                                        marginTop: "35px"
                                                    }}
                                                >
                                                    {Object.entries(permissions).map(([parent, children], parentIndex) => (
                                                        // <Grid item  key={parentIndex}>
                                                        <TreeView
                                                            defaultCollapseIcon={<ExpandMoreIcon />}
                                                            defaultExpandIcon={<ChevronRightIcon />}

                                                        >
                                                            <TreeItem

                                                                nodeId={parent}
                                                                label={
                                                                    <FormControlLabel
                                                                        className={classes.treeItem}
                                                                        control={
                                                                            <Checkbox
                                                                                checked={isParentChecked(children)}
                                                                                onChange={(e) => handleParentCheckboxChange(parent, children, e.target.checked)}
                                                                                color="primary"
                                                                            />
                                                                        }
                                                                        label={parent}
                                                                    />
                                                                }

                                                            >
                                                                {children.map((permission, childIndex) => (
                                                                    <div key={childIndex} className={classes.treeItem}
                                                                        style={{ marginLeft: "24px", marginTop: "-11px" }}
                                                                    >
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    checked={editValues.permissions.includes(permission.id)}
                                                                                    onChange={handleCheckboxChange}
                                                                                    name={permission.id.toString()}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={permission.value}
                                                                        />
                                                                    </div>
                                                                ))}
                                                            </TreeItem>
                                                        </TreeView>
                                                        // </Grid>
                                                    ))}
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={3}
                                                style={{ marginTop: "23px" }}
                                            >
                                                <Grid item>
                                                    <Button type="submit" variant="contained" color="primary">Save</Button>
                                                    <Button type="button" onClick={() => history.push('/role')} style={{ marginLeft: '10px' }}>Cancel</Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                }
            />
            <Footer />
        </div >
    );
};

export default EditRole;
