import React, { useState, useEffect } from 'react'
import Header from '../../Layouts/Header'
import Footer from '../../Layouts/Footer';
import Breadcrumb from '../../Layouts/Breadcrumb';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AuthApi from "../../Services/Authapi";
import moment from "moment";
import swal from "sweetalert";
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import AuthCheckComponent from '../../Layouts/Token';


let userData;

const iniDoc = {
    candidateName: "",
    designation: "",
    doj: "",
    monthlySalary: "",
    location: "",
}

const iniData = {
    userData: [],
}

const OfferLatterAdd = (props) => {
    const classes = props;
    const [doc, setDoc] = useState(iniDoc);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formvalue, setFormvalue] = useState(iniData)


    useEffect(() => {
        if (props && props.authToken === false) {
            // props.history.push('/login');
        }

    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setDoc({ ...doc, [name]: value });
    };


    const validate = (values) => {
        let errors = {};
        const regex = /^[a-zA-Z]/;
        const dobRegx = /^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/;
        const Numeric = /^\d+$/;


        if (!values.candidateName) {
            errors.candidateName = "Cannot be blank";
        } else if (!regex.test(values.candidateName)) {
            errors.candidateName = "Invalid candidateName format";
        }

        if (!values.designation) {
            errors.designation = "Cannot be blank";
        } else if (!regex.test(values.designation)) {
            errors.designation = "Invalid designation format";
        }

        if (!values.doj) {
            errors.doj = "Cannot be blank";
        } else if (!dobRegx.test(values.doj)) {
            errors.doj = "Invalid dateOfJoining format";
        }

        if (!values.monthlySalary) {
            errors.monthlySalary = "Cannot be blank";
        } else if (!Numeric.test(values.monthlySalary)) {
            errors.monthlySalary = "Must Be Numeric"
        }

        if (!values.location) {
            errors.location = "Cannot be blank";
        } else if (!regex.test(values.location)) {
            errors.location = "Invalid location format"
        }

        return errors;
    };



    const handleSubmit = async (e) => {
        e.preventDefault()
        setFormErrors(validate(doc));
        setIsSubmitting(true);
    }

    useEffect( () => {
        if (Object.keys(formErrors).length === 0 && isSubmitting) {
             createLeaves();
        }
    }, [formErrors]);


    const createLeaves = async () => {
        swal({
            title: "Loading",
            text: "Please wait...",
            buttons: false,
            closeOnClickOutside: false,
            closeOnEsc: false,
            width: "10%",
            content: {
                element: "img",
                attributes: {
                    src: "https://www.boasnotas.com/img/loading2.gif",
                    style: "width: 50px;" // Adjust the width as needed
                }
            }
        });
        try {
            console.log(createLeaves);
            let create = await AuthApi.offerletteradd(doc);
            swal.close();
            console.log(create);

            if (create && create.status === true) {
                props.history.push("/Documentslist");
            } else {
                swal({
                    title: "OOPS!",
                    icon: "fail",
                    message: "Something went wrong, Please try later!",
                });
            }
        } catch (error) {
            console.error("Error updating later:", error);
        }
    };


    function diffday() {
        const a = moment(doc.startDate);
        const b = moment(doc.endDate);
        const dateRangeArr = [];

        // if(e.target.name == "startDate"){
        var startDate = doc.startDate;
        dateRangeArr.push(startDate);
        // }
        // if(e.target.name == "endDate"){
        var endDate = doc.endDate;
        dateRangeArr.push(endDate);
        // }

        doc.dateRange = dateRangeArr;
        doc.noOfDays = b.diff(a, "days");
        // console.log(doc.noOfDays);
        // console.log( doc.dateRange);
    }


    return (
        <div>
            <AuthCheckComponent authToken={props.authToken} />
            <Header
                {...props}
                authUser={props.authUser}
                component={
                    <div>
                        <Card className={classes.root} style={{ marginBottom: '3%' }}>
                            <CardContent>
                                <Breadcrumb
                                    {...props}
                                    primaryPageName="Documentslist"
                                    primaryPageLink="/Documentslist"
                                    isSecondaryPage={true}
                                    secondaryPageName="Offer Latter" />
                            </CardContent>
                        </Card>
                        <Grid container className={classes.root} spacing={2}>
                            <Grid item xs={12}>
                                <Card className={classes.root}>
                                    <CardContent>
                                        <Typography variant="h2" style={{ paddingLeft: 5 }}>Add Offer Letter</Typography>
                                        <form className={classes.form} onSubmit={handleSubmit} id="create-course-form" >
                                            <Grid container className={classes.root} spacing={5}>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        fullWidth
                                                        id="Candidatename"
                                                        name="candidateName"
                                                        label="Candidate Name"
                                                        variant="outlined"
                                                        error={formErrors.candidateName && true}
                                                        value={doc.candidateName}
                                                        onChange={handleChange}
                                                        className={formErrors.candidateName && "input-error"}

                                                    />
                                                    {formErrors.candidateName && (
                                                        <span className="error">{formErrors.candidateName}</span>
                                                    )}
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <TextField
                                                        fullWidth
                                                        id="Designation"
                                                        name="designation"
                                                        label="Designation"
                                                        variant="outlined"
                                                        error={formErrors.designation && true}
                                                        value={doc.designation}
                                                        onChange={handleChange}
                                                        className={formErrors.designation && "input-error"}

                                                    />
                                                    {formErrors.designation && (
                                                        <span className="error">{formErrors.designation}</span>
                                                    )}
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <TextField
                                                        fullWidth
                                                        id="date"
                                                        variant="outlined"
                                                        label="Date of Join"
                                                        name="doj"
                                                        type="date"
                                                        error={formErrors.doj && true}
                                                        value={doc.doj}
                                                        onChange={handleChange}
                                                        className={formErrors.doj && "input-error"}
                                                        className={classes.textField}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                    {formErrors.doj && (
                                                        <span className="error">{formErrors.doj}</span>
                                                    )}
                                                </Grid>
                                            </Grid>


                                            <Grid container className={classes.root} spacing={5}>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        fullWidth
                                                        id="monthlySalary"
                                                        name="monthlySalary"
                                                        label="Monthly Salary"
                                                        variant="outlined"
                                                        error={formErrors.monthlySalary && true}
                                                        value={doc.monthlySalary}
                                                        onChange={handleChange}
                                                        className={formErrors.monthlySalary && "input-error"}

                                                    />
                                                    {formErrors.monthlySalary && (
                                                        <span className="error">{formErrors.monthlySalary}</span>
                                                    )}

                                                </Grid>

                                                <Grid item xs={4}>
                                                    <TextField
                                                        fullWidth
                                                        id="location"
                                                        name="location"
                                                        label="Location"
                                                        variant="outlined"
                                                        error={formErrors.location && true}
                                                        value={doc.location}
                                                        onChange={handleChange}
                                                        className={formErrors.location && "input-error"}

                                                    />
                                                    {formErrors.location && (
                                                        <span className="error">{formErrors.location}</span>
                                                    )}
                                                </Grid>
                                            </Grid>



                                            <Grid container className={classes.root} spacing={3}>
                                                <Grid item xs={4} style={{ display: 'flex' }}>
                                                    <Button
                                                        type="submit"
                                                        onClick={(e) => {
                                                            diffday();
                                                        }}
                                                        style={{ marginTop: "10px" }}
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        Save
                                                    </Button>

                                                    <Button type="button" style={{ marginTop: "10px" }} onClick={(e) => { e.preventDefault(); props.history.push('/Documentslist') }}>Cancel</Button>
                                                </Grid>
                                                <Grid item xs={4}></Grid>
                                                <Grid item xs={4}></Grid>
                                            </Grid>

                                        </form>

                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                } />
            <Footer />
        </div>
    )
}

export default OfferLatterAdd
